import { createActionGroup, props } from '@ngrx/store';
import { dialogFeatureKey } from './selectors';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogData } from '../dialogs.model';

export const DialogsActions = createActionGroup({
  source: dialogFeatureKey,
  events: {
    showDialog: props<{ data: DialogData; callback?: (...args: any[]) => void }>(),
    setRef: props<{ ref: MatDialogRef<any> | null }>(),
  },
});
