import { BaseEventsService } from './base-event-service';
import { dataTableEvents } from '../constants/data-table-events';

function processCommonMenuItems(eventsService: BaseEventsService, event: any) {
  // common
  if (event.target.hasAttribute(dataTableEvents.common.reviewLink)) {
    eventsService.onReviewLink(event.target.getAttribute(dataTableEvents.common.reviewLink));
    return;
  }
  if (event.target.hasAttribute(dataTableEvents.common.queryLink)) {
    eventsService.onQueryLink(event.target.getAttribute(dataTableEvents.common.queryLink));
    return;
  }
  if (event.target.hasAttribute(dataTableEvents.common.addClausesLink)) {
    eventsService.onAddClausesLink(
      event.target.getAttribute(dataTableEvents.common.addClausesLink),
    );
    return;
  }
  if (event.target.hasAttribute(dataTableEvents.common.editProperties)) {
    eventsService.onEditProperties(
      event.target.getAttribute(dataTableEvents.common.editProperties),
    );
    return;
  }
  if (event.target.hasAttribute(dataTableEvents.common.cancel)) {
    eventsService.onCancel(event.target.getAttribute(dataTableEvents.common.cancel));
    return;
  }
  if (event.target.hasAttribute(dataTableEvents.common.edit)) {
    eventsService.onEdit(event.target.getAttribute(dataTableEvents.common.edit));
    return;
  }
  if (event.target.hasAttribute(dataTableEvents.common.editInDesktop)) {
    eventsService.onEditInDesktop(event.target.getAttribute(dataTableEvents.common.editInDesktop));
    return;
  }
  if (event.target.hasAttribute(dataTableEvents.common.sendReport)) {
    eventsService.onSendReport(event.target.getAttribute(dataTableEvents.common.sendReport));
    return;
  }
  if (event.target.hasAttribute(dataTableEvents.common.delete)) {
    eventsService.onDelete(event.target.getAttribute(dataTableEvents.common.delete));
    return;
  }
  if (event.target.hasAttribute(dataTableEvents.common.search)) {
    eventsService.onSearch(event.target.getAttribute(dataTableEvents.common.search));
    return;
  }
  if (event.target.hasAttribute(dataTableEvents.common.apply)) {
    eventsService.onApply(event.target.getAttribute(dataTableEvents.common.apply));
    return;
  }
  if (event.target.hasAttribute(dataTableEvents.common.case)) {
    eventsService.onCase(event.target.getAttribute(dataTableEvents.common.case));
    return;
  }
  if (event.target.hasAttribute(dataTableEvents.common.details)) {
    eventsService.onDetails(event.target.getAttribute(dataTableEvents.common.details));
    return;
  }
  if (event.target.hasAttribute(dataTableEvents.common.download)) {
    eventsService.onDownload(event.target.getAttribute(dataTableEvents.common.download));
    return;
  }
  if (event.target.hasAttribute(dataTableEvents.common.previw)) {
    eventsService.onPreview(event.target.getAttribute(dataTableEvents.common.previw));
    return;
  }
}

export { processCommonMenuItems };
