<div class="app-input">
  <mat-label *ngIf="label" class="app-input__label">{{ label }}: </mat-label>
  <mat-form-field appearance="outline">
    <input
      type="text"
      matInput
      matNativeControl
      [formControl]="control"
      [placeholder]="placeholder"
      [name]="name"
      [id]="id"
    />
    @if (!control.value) {
      <mat-icon matSuffix class="app-input__icon def-cursor">{{ 'search' }}</mat-icon>
    } @else {
      <mat-icon (click)="clear()" matSuffix class="app-input__icon">{{ 'close' }}</mat-icon>
    }
  </mat-form-field>
</div>

<span class="material-symbols-rounded"> </span>
