import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'dateShort',
})
export class DateShortPipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {}

  transform(date: string): string {
    const language = this.translocoService.getActiveLang();
    if (!date) {
      return '';
    }

    return getFormattedDate(date, language);
  }
}

function getFormattedDate(dateStr: string, language: string) {
  const date = new Date(dateStr);
  let formattedDate = '';
  const month = date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
  const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
  const year = date.getFullYear();

  switch (language) {
    case 'fr': {
      formattedDate = `${day}/${month}/${year}`;
      break;
    }
    default: {
      formattedDate = `${month}/${day}/${year}`;
      break;
    }
  }

  return formattedDate;
}
