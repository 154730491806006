import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/core/services/api.service';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root',
})
export class LanguagesApiService extends ApiService {
  constructor() {
    super();
  }

  public getContentLanguages$(): Observable<LanguageDto[]> {
    return this.get('/languages/content-languages');
  }

  public getInterfaceLanguages$(): Observable<LanguageDto[]> {
    return this.get('/languages/interface-languages');
  }
}

export interface LanguageDto {
  id: number;
  name: string;
  displayName: string;
}

export const apiLanguagesProtectedResources = [
  {
    httpMethod: 'GET',
    scopes: [...environment.protectedResources.apiLanguages.scopes.read],
  },
  {
    httpMethod: 'POST',
    scopes: [...environment.protectedResources.apiLanguages.scopes.write],
  },
  {
    httpMethod: 'PUT',
    scopes: [...environment.protectedResources.apiLanguages.scopes.write],
  },
  {
    httpMethod: 'DELETE',
    scopes: [...environment.protectedResources.apiLanguages.scopes.write],
  },
];
