import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DocumentStatusFactory } from 'src/app/core/factories/doc-status.factory';
import { DOC_STATUS } from 'src/app/core/models/status.enum';

@Pipe({ name: 'docStatus' })
export class DocStatusPipe implements PipeTransform {
  constructor(
    private translocoService: TranslocoService,
    private statusFactory: DocumentStatusFactory,
  ) {}

  private getColor(status: DOC_STATUS) {
    return this.statusFactory.getById(status)?.color;
  }

  transform(status: DOC_STATUS) {
    const color = this.getColor(status);

    return `<span> <mat-icon role="img" class="mat-icon smallIcon in-column material-icons mat-ligature-font" aria-hidden="true" data-mat-icon-type="font" style="color: ${color}">circle</mat-icon> ${this.translocoService.translate(
      'status.' + status,
    )}</span>`;
  }
}
