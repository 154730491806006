<div class="app-tui-multy-select f-control">
  <tui-select [valueContent]="valueContent" [formControl]="control">
    {{ placeholderKey | transloco }}
    <custom-list
      *tuiDataList
      [autocompleteOptions]="autocompleteOptions"
      [optionLabel]="optionLabel"
    ></custom-list>
  </tui-select>

  <mat-error class="errors" *ngIf="showErrors && control?.invalid && control?.touched"
    >{{ getErrorMessage() }}
  </mat-error>
</div>
