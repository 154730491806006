import * as i0 from '@angular/core';
import { ElementRef, NgZone, Directive, Inject, Self, Input, NgModule } from '@angular/core';
import { tuiZonefree, tuiPreventDefault } from '@taiga-ui/cdk/observables';
import { TuiDestroyService } from '@taiga-ui/cdk/services';
import * as i1 from 'rxjs';
import { fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * @description:
 * Simple prevent default on event directive when you do not need anything
 * else on event and do not want to trigger change detection
 */
class TuiPreventDefaultDirective {
  constructor(el, zone, destroy$) {
    this.el = el;
    this.zone = zone;
    this.destroy$ = destroy$;
    this.eventName = '';
  }
  ngOnInit() {
    fromEvent(this.el.nativeElement, this.eventName, {
      passive: false
    }).pipe(tuiZonefree(this.zone), tuiPreventDefault(), takeUntil(this.destroy$)).subscribe();
  }
}
TuiPreventDefaultDirective.ɵfac = function TuiPreventDefaultDirective_Factory(t) {
  return new (t || TuiPreventDefaultDirective)(i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(NgZone), i0.ɵɵdirectiveInject(TuiDestroyService, 2));
};
TuiPreventDefaultDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiPreventDefaultDirective,
  selectors: [["", "tuiPreventDefault", ""]],
  inputs: {
    eventName: [i0.ɵɵInputFlags.None, "tuiPreventDefault", "eventName"]
  },
  features: [i0.ɵɵProvidersFeature([TuiDestroyService])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPreventDefaultDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiPreventDefault]',
      providers: [TuiDestroyService]
    }]
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i0.NgZone,
      decorators: [{
        type: Inject,
        args: [NgZone]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }];
  }, {
    eventName: [{
      type: Input,
      args: ['tuiPreventDefault']
    }]
  });
})();

/**
 * @deprecated Use (event.prevent.silent)=(0) instead.
 */
class TuiPreventDefaultModule {}
TuiPreventDefaultModule.ɵfac = function TuiPreventDefaultModule_Factory(t) {
  return new (t || TuiPreventDefaultModule)();
};
TuiPreventDefaultModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiPreventDefaultModule
});
TuiPreventDefaultModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPreventDefaultModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiPreventDefaultDirective],
      exports: [TuiPreventDefaultDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiPreventDefaultDirective, TuiPreventDefaultModule };
