import {
  TuiFilterPipeModule,
  TuiLetModule,
  TuiPortalModule,
  TuiPressedModule,
} from '@taiga-ui/cdk';
import { TuiDataListModule, TuiPrimitiveTextfieldModule, TuiRootModule } from '@taiga-ui/core';
import {
  TuiMultiSelectModule,
  TuiMultiSelectOptionModule,
  TuiSelectModule,
  TuiSelectOptionModule,
} from '@taiga-ui/kit';

import { FormsModule } from '@angular/forms';

export const TAIGA_UI_MODULES = [
  FormsModule,
  TuiFilterPipeModule,
  TuiLetModule,
  TuiPortalModule,
  TuiPressedModule,
  TuiDataListModule,
  TuiPrimitiveTextfieldModule,
  TuiRootModule,
  TuiMultiSelectModule,
  TuiMultiSelectOptionModule,
  TuiSelectModule,
  TuiSelectOptionModule,
];
