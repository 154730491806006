import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { DialogData } from 'src/app/modules/dialogs/dialogs.model';
import { AppButtonSize, AppButtonType } from 'src/app/shared/shared.model';

@Component({
  selector: 'app-dialog',
  templateUrl: './app-dialog.component.html',
  styleUrl: './app-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppDialogComponent {
  public btnTypes = AppButtonType;
  public btnSizes = AppButtonSize;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<AppDialogComponent>,
    private translocoService: TranslocoService,
  ) {}

  public getLabel(item: string): string {
    return this.translocoService.translate(`buttons.${item}`);
  }

  public close(action: string): void {
    this.dialogRef.close(action);
  }
}
