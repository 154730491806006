import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { Directive, EventEmitter, ChangeDetectorRef, TemplateRef, Component, ChangeDetectionStrategy, Optional, Self, Inject, ContentChild, ViewChild, Input, HostBinding, Output, Pipe, ContentChildren, forwardRef, NgModule } from '@angular/core';
import * as i3 from '@taiga-ui/cdk';
import { tuiCreateToken, tuiProvideOptions, AbstractTuiMultipleControl, ALWAYS_TRUE_HANDLER, EMPTY_ARRAY, tuiIsString, tuiArrayToggle, tuiIsNativeFocused, TUI_IS_MOBILE, tuiAsFocusableItemAccessor, tuiAsControl, tuiPure, EMPTY_QUERY, tuiQueryListChanges, tuiGetOriginalArrayFromQueryList, TUI_DEFAULT_IDENTITY_MATCHER, tuiIsPresent, tuiControlValue, EMPTY_FUNCTION, TuiPreventDefaultModule, TuiActiveZoneModule, TuiLetModule, TuiMapperPipeModule } from '@taiga-ui/cdk';
import { tuiIsFlat } from '@taiga-ui/kit/utils';
import * as i4 from '@angular/forms';
import { NgControl, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import * as i1 from '@taiga-ui/core';
import { TUI_TEXTFIELD_WATCHED_CONTROLLER, tuiAsDataListHost, TEXTFIELD_CONTROLLER_PROVIDER, TUI_DATA_LIST_ACCESSOR, TuiDataListDirective, TuiHostedDropdownComponent, AbstractTuiTextfieldHost, tuiAsTextfieldHost, TUI_DATA_LIST_HOST, TuiOptionComponent, tuiAsOptionContent, tuiAsDataList, TuiWrapperModule, TuiSvgModule, TuiHostedDropdownModule, TuiLinkModule, TuiDataListModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { TuiStringifiableItem } from '@taiga-ui/kit/classes';
import { TUI_ARROW_MODE, TuiArrowModule } from '@taiga-ui/kit/components/arrow';
import * as i2 from '@taiga-ui/kit/components/input-tag';
import { TuiInputTagComponent, TuiInputTagModule } from '@taiga-ui/kit/components/input-tag';
import { FIXED_DROPDOWN_CONTROLLER_PROVIDER } from '@taiga-ui/kit/providers';
import { TUI_ITEMS_HANDLERS, TUI_MULTI_SELECT_TEXTS } from '@taiga-ui/kit/tokens';
import { AbstractTuiNativeSelect } from '@taiga-ui/kit/abstract';
import * as i2$1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i6 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusComponent, PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import * as i1$1 from '@taiga-ui/kit/components/data-list-wrapper';
import { TuiDataListWrapperModule } from '@taiga-ui/kit/components/data-list-wrapper';
import { TuiMultiSelectOptionComponent, TuiMultiSelectOptionModule } from '@taiga-ui/kit/components/multi-select-option';
import * as i6$1 from '@taiga-ui/kit/pipes/stringify-content';
import { TuiStringifyContentPipeModule } from '@taiga-ui/kit/pipes/stringify-content';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
function TuiMultiSelectComponent_ng_template_7_ng_content_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0, 1, ["*ngIf", "isMobile"]);
  }
}
function TuiMultiSelectComponent_ng_template_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TuiMultiSelectComponent_ng_template_7_ng_content_0_Template, 1, 0, "ng-content", 7);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", ctx_r1.isMobile);
  }
}
function TuiMultiSelectComponent_ng_container_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0, 8);
  }
}
function TuiMultiSelectComponent_div_10_span_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 11);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const text_r9 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r9, " ");
  }
}
const _c0 = a0 => ({
  $implicit: a0
});
function TuiMultiSelectComponent_div_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 9);
    i0.ɵɵtemplate(1, TuiMultiSelectComponent_div_10_span_1_Template, 2, 1, "span", 10);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    const _r0 = i0.ɵɵreference(2);
    i0.ɵɵclassProp("t-content_fullsize", _r0.labelOutside);
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r4.valueContent)("polymorpheusOutletContext", i0.ɵɵpureFunction1(4, _c0, ctx_r4.value));
  }
}
function TuiMultiSelectComponent_ng_template_11_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const text_r11 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r11, " ");
  }
}
function TuiMultiSelectComponent_ng_template_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 12);
    i0.ɵɵtemplate(1, TuiMultiSelectComponent_ng_template_11_ng_container_1_Template, 2, 1, "ng-container", 13);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r5 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("t-arrow_native-dropdown", ctx_r5.nativeDropdownMode);
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", ctx_r5.arrow);
  }
}
const _c1 = ["*", [["select"]]];
const _c2 = ["*", "select"];
const _c3 = ["tuiMultiSelectGroup", ""];
function TuiMultiSelectGroupComponent_span_0_button_3_Template(rf, ctx) {
  if (rf & 1) {
    const _r5 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "button", 4);
    i0.ɵɵlistener("click", function TuiMultiSelectGroupComponent_span_0_button_3_Template_button_click_0_listener() {
      i0.ɵɵrestoreView(_r5);
      const value_r1 = i0.ɵɵnextContext().tuiLet;
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.onClick(value_r1));
    });
    i0.ɵɵpipe(1, "async");
    i0.ɵɵtext(2);
    i0.ɵɵpipe(3, "async");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const value_r1 = i0.ɵɵnextContext().tuiLet;
    const ctx_r2 = i0.ɵɵnextContext();
    let tmp_1_0;
    i0.ɵɵproperty("disabled", !!i0.ɵɵpipeBind1(1, 2, ctx_r2.disabled$));
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", (tmp_1_0 = i0.ɵɵpipeBind1(3, 4, ctx_r2.multiSelectTexts$)) == null ? null : tmp_1_0[value_r1 ? "none" : "all"], " ");
  }
}
function TuiMultiSelectGroupComponent_span_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span", 1)(1, "span", 2);
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(3, TuiMultiSelectGroupComponent_span_0_button_3_Template, 4, 6, "button", 3);
    i0.ɵɵpipe(4, "async");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r0.label);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.label && !i0.ɵɵpipeBind1(4, 2, ctx_r0.empty$));
  }
}
const _c4 = ["*"];
const _c5 = ["multiple", "", "tuiSelect", ""];
function TuiNativeMultiSelectComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TuiNativeMultiSelectComponent_tui_data_list_wrapper_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-data-list-wrapper", 3);
    i0.ɵɵpipe(1, "tuiStringifyContent");
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabledItemHandler", ctx_r1.disabledItemHandler || ctx_r1.host.disableItemHandler)("itemContent", i0.ɵɵpipeBind1(1, 3, ctx_r1.stringify))("items", ctx_r1.items);
  }
}
function TuiNativeMultiSelectComponent_option_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 4);
    i0.ɵɵpipe(1, "tuiMapper");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const option_r3 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabled", ctx_r2.disabledItemHandler ? ctx_r2.disabledItemHandler(option_r3) : ctx_r2.host.disableItemHandler(option_r3))("selected", i0.ɵɵpipeBind3(1, 4, option_r3, ctx_r2.selectedMapper, ctx_r2.control.value))("value", ctx_r2.stringify(option_r3));
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r2.stringify(option_r3), "\n");
  }
}
const _c6 = ["multiple", "", "tuiSelect", "", "labels", ""];
function TuiNativeMultiSelectGroupComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TuiNativeMultiSelectGroupComponent_tui_data_list_wrapper_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-data-list-wrapper", 3);
    i0.ɵɵpipe(1, "tuiStringifyContent");
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("disabledItemHandler", ctx_r1.disabledItemHandler || ctx_r1.host.disableItemHandler)("itemContent", i0.ɵɵpipeBind1(1, 4, ctx_r1.stringify))("items", ctx_r1.items)("labels", ctx_r1.labels);
  }
}
function TuiNativeMultiSelectGroupComponent_optgroup_3_option_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "option", 6);
    i0.ɵɵpipe(1, "tuiMapper");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const option_r6 = ctx.$implicit;
    const ctx_r5 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("disabled", ctx_r5.disabledItemHandler ? ctx_r5.disabledItemHandler(option_r6) : ctx_r5.host.disableItemHandler(option_r6))("selected", i0.ɵɵpipeBind3(1, 4, option_r6, ctx_r5.selectedMapper, ctx_r5.control.value))("value", ctx_r5.stringify(option_r6));
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r5.stringify(option_r6), " ");
  }
}
function TuiNativeMultiSelectGroupComponent_optgroup_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "optgroup", 4);
    i0.ɵɵtemplate(1, TuiNativeMultiSelectGroupComponent_optgroup_3_option_1_Template, 3, 8, "option", 5);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const group_r3 = ctx.$implicit;
    const index_r4 = ctx.index;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("label", ctx_r2.labels[index_r4]);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", group_r3);
  }
}
const _c7 = "[_nghost-%COMP%]{position:absolute;top:0;left:0;width:100%;height:100%;opacity:0}";
const TUI_MULTI_SELECT_DEFAULT_OPTIONS = {
  expandable: true,
  valueContent: ''
};
/**
 * Default parameters for MultiSelect component
 */
const TUI_MULTI_SELECT_OPTIONS = tuiCreateToken(TUI_MULTI_SELECT_DEFAULT_OPTIONS);
function tuiMultiSelectOptionsProvider(options) {
  return tuiProvideOptions(TUI_MULTI_SELECT_OPTIONS, options, TUI_MULTI_SELECT_DEFAULT_OPTIONS);
}
class AbstractTuiNativeMultiSelect extends AbstractTuiNativeSelect {
  constructor() {
    super(...arguments);
    this.selectedMapper = (option, value) => value.includes(option);
  }
  get stringify() {
    return this.host.stringify;
  }
}
AbstractTuiNativeMultiSelect.ɵfac = /* @__PURE__ */(() => {
  let ɵAbstractTuiNativeMultiSelect_BaseFactory;
  return function AbstractTuiNativeMultiSelect_Factory(t) {
    return (ɵAbstractTuiNativeMultiSelect_BaseFactory || (ɵAbstractTuiNativeMultiSelect_BaseFactory = i0.ɵɵgetInheritedFactory(AbstractTuiNativeMultiSelect)))(t || AbstractTuiNativeMultiSelect);
  };
})();
AbstractTuiNativeMultiSelect.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AbstractTuiNativeMultiSelect,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiNativeMultiSelect, [{
    type: Directive
  }], null, null);
})();
class TuiMultiSelectComponent extends AbstractTuiMultipleControl {
  constructor(control, cdr, arrowMode, itemsHandlers, options, controller, isMobile) {
    super(control, cdr);
    this.arrowMode = arrowMode;
    this.itemsHandlers = itemsHandlers;
    this.options = options;
    this.controller = controller;
    this.isMobile = isMobile;
    this.stringify = this.itemsHandlers.stringify;
    this.identityMatcher = this.itemsHandlers.identityMatcher;
    this.expandable = this.options.expandable;
    this.search = '';
    this.placeholder = '';
    this.editable = true;
    this.disabledItemHandler = this.itemsHandlers.disabledItemHandler;
    this.valueContent = this.options.valueContent;
    this.tagValidator = ALWAYS_TRUE_HANDLER;
    this.rows = Infinity;
    this.autoColor = false;
    this.searchChange = new EventEmitter();
    this.open = false;
    this.valueMapper = (value, stringify, group) => group ? EMPTY_ARRAY : value.map(item => new TuiStringifiableItem(item, stringify));
    this.disabledItemHandlerWrapper = handler => stringifiable => tuiIsString(stringifiable) || handler(stringifiable.item);
  }
  get size() {
    return this.controller.size;
  }
  get arrow() {
    return this.interactive ? this.arrowMode.interactive : this.arrowMode.disabled;
  }
  get nativeFocusableElement() {
    var _a, _b;
    return (_b = (_a = this.input) === null || _a === void 0 ? void 0 : _a.nativeFocusableElement) !== null && _b !== void 0 ? _b : null;
  }
  get focused() {
    var _a, _b;
    return !!((_a = this.input) === null || _a === void 0 ? void 0 : _a.focused) || !!((_b = this.hostedDropdown) === null || _b === void 0 ? void 0 : _b.focused);
  }
  get nativeDropdownMode() {
    return !!this.nativeSelect && this.isMobile && !this.editable;
  }
  get computedValue() {
    return this.computedGroup ? EMPTY_ARRAY : this.value;
  }
  // @bad TODO: think of a better way
  get searchOrSpace() {
    return this.computedGroup ? ' ' : this.searchString;
  }
  get searchString() {
    return this.search === null ? '' : this.search;
  }
  get computedGroup() {
    return !!this.valueContent && this.value.length > 0 && (!this.focused || !this.editable);
  }
  getStringifier(stringify) {
    return ({
      $implicit
    }) => stringify($implicit);
  }
  onSpace(event) {
    var _a;
    if (!this.editable) {
      event.preventDefault();
    }
    if (!this.readOnly) {
      (_a = this.hostedDropdown) === null || _a === void 0 ? void 0 : _a.updateOpen(true);
    }
  }
  handleOption(option) {
    const {
      value,
      identityMatcher
    } = this;
    const index = value.findIndex(item => identityMatcher(item, option));
    this.value = index === -1 ? [...value, option] : value.filter((_, i) => i !== index);
    this.updateSearch(null);
  }
  onEnter(event) {
    const {
      value
    } = this;
    const options = this.accessor ? this.accessor.getOptions() : [];
    if (options.length !== 1) {
      return;
    }
    event.preventDefault();
    this.value = tuiArrayToggle(value, options[0]);
    this.updateSearch(null);
  }
  onClick({
    nativeFocusableElement
  }) {
    var _a;
    if (this.interactive && nativeFocusableElement && tuiIsNativeFocused(nativeFocusableElement)) {
      (_a = this.hostedDropdown) === null || _a === void 0 ? void 0 : _a.updateOpen(!this.open);
    }
  }
  onInput(value) {
    this.value = value.map(({
      item
    }) => item);
  }
  onValueChange(value) {
    this.value = value;
  }
  onSearch(search) {
    var _a;
    // Clearing sets the empty value, the dropdown should not be opened on clear.
    if (search !== '') {
      (_a = this.hostedDropdown) === null || _a === void 0 ? void 0 : _a.updateOpen(true);
    }
    this.updateSearch(search);
  }
  onActiveZone(active) {
    this.updateFocused(active);
  }
  setDisabledState() {
    var _a;
    super.setDisabledState();
    (_a = this.hostedDropdown) === null || _a === void 0 ? void 0 : _a.updateOpen(false);
  }
  updateSearch(search) {
    if (this.search === search) {
      return;
    }
    this.search = search;
    this.searchChange.emit(search);
  }
}
TuiMultiSelectComponent.ɵfac = function TuiMultiSelectComponent_Factory(t) {
  return new (t || TuiMultiSelectComponent)(i0.ɵɵdirectiveInject(NgControl, 10), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(TUI_ARROW_MODE), i0.ɵɵdirectiveInject(TUI_ITEMS_HANDLERS), i0.ɵɵdirectiveInject(TUI_MULTI_SELECT_OPTIONS), i0.ɵɵdirectiveInject(TUI_TEXTFIELD_WATCHED_CONTROLLER), i0.ɵɵdirectiveInject(TUI_IS_MOBILE));
};
TuiMultiSelectComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiMultiSelectComponent,
  selectors: [["tui-multi-select"]],
  contentQueries: function TuiMultiSelectComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, TUI_DATA_LIST_ACCESSOR, 5);
      i0.ɵɵcontentQuery(dirIndex, AbstractTuiNativeMultiSelect, 7);
      i0.ɵɵcontentQuery(dirIndex, TuiDataListDirective, 5, TemplateRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.accessor = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.nativeSelect = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.datalist = _t.first);
    }
  },
  viewQuery: function TuiMultiSelectComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(TuiHostedDropdownComponent, 5);
      i0.ɵɵviewQuery(TuiInputTagComponent, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.hostedDropdown = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.input = _t.first);
    }
  },
  hostVars: 3,
  hostBindings: function TuiMultiSelectComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("data-size", ctx.size);
      i0.ɵɵclassProp("_editable", ctx.editable);
    }
  },
  inputs: {
    stringify: "stringify",
    identityMatcher: "identityMatcher",
    expandable: "expandable",
    search: "search",
    placeholder: "placeholder",
    editable: "editable",
    disabledItemHandler: "disabledItemHandler",
    valueContent: "valueContent",
    tagValidator: "tagValidator",
    rows: "rows",
    autoColor: "autoColor"
  },
  outputs: {
    searchChange: "searchChange"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsFocusableItemAccessor(TuiMultiSelectComponent), tuiAsControl(TuiMultiSelectComponent), tuiAsDataListHost(TuiMultiSelectComponent), TEXTFIELD_CONTROLLER_PROVIDER], [FIXED_DROPDOWN_CONTROLLER_PROVIDER]), i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c2,
  decls: 13,
  vars: 33,
  consts: [[1, "t-hosted", 3, "canOpen", "content", "open", "openChange", "tuiActiveZoneChange"], ["automation-id", "tui-multi-select__input", 1, "t-input", 3, "autoColor", "disabled", "disabledItemHandler", "editable", "expandable", "inputHidden", "nativeId", "ngModel", "placeholder", "pseudoFocus", "pseudoHover", "pseudoInvalid", "readOnly", "removable", "rows", "search", "tagValidator", "tuiTextfieldIcon", "click.prevent", "keydown.enter", "keydown.space", "ngModelChange", "searchChange"], ["inputTag", ""], ["select", ""], ["ngProjectAs", "select", 5, ["select"], 4, "ngTemplateOutlet"], ["ngProjectAs", "tuiContent", "class", "t-content", 5, ["tuiContent"], 3, "t-content_fullsize", 4, "ngIf"], ["icon", ""], [4, "ngIf"], ["ngProjectAs", "select", 5, ["select"]], ["ngProjectAs", "tuiContent", 5, ["tuiContent"], 1, "t-content"], ["class", "t-primitive", 4, "polymorpheusOutlet", "polymorpheusOutletContext"], [1, "t-primitive"], ["appearance", "icon", "automation-id", "tui-multi-select__arrow", "tuiPreventDefault", "mousedown", "tuiWrapper", "", 1, "t-arrow"], [4, "polymorpheusOutlet"]],
  template: function TuiMultiSelectComponent_Template(rf, ctx) {
    if (rf & 1) {
      const _r12 = i0.ɵɵgetCurrentView();
      i0.ɵɵprojectionDef(_c1);
      i0.ɵɵelementStart(0, "tui-hosted-dropdown", 0);
      i0.ɵɵlistener("openChange", function TuiMultiSelectComponent_Template_tui_hosted_dropdown_openChange_0_listener($event) {
        return ctx.open = $event;
      })("tuiActiveZoneChange", function TuiMultiSelectComponent_Template_tui_hosted_dropdown_tuiActiveZoneChange_0_listener($event) {
        return ctx.onActiveZone($event);
      });
      i0.ɵɵelementStart(1, "tui-input-tag", 1, 2);
      i0.ɵɵlistener("click.prevent", function TuiMultiSelectComponent_Template_tui_input_tag_click_prevent_1_listener() {
        i0.ɵɵrestoreView(_r12);
        const _r0 = i0.ɵɵreference(2);
        return i0.ɵɵresetView(ctx.onClick(_r0));
      })("keydown.enter", function TuiMultiSelectComponent_Template_tui_input_tag_keydown_enter_1_listener($event) {
        return ctx.onEnter($event);
      })("keydown.space", function TuiMultiSelectComponent_Template_tui_input_tag_keydown_space_1_listener($event) {
        return ctx.onSpace($event);
      })("ngModelChange", function TuiMultiSelectComponent_Template_tui_input_tag_ngModelChange_1_listener($event) {
        return ctx.onInput($event);
      })("searchChange", function TuiMultiSelectComponent_Template_tui_input_tag_searchChange_1_listener($event) {
        return ctx.onSearch($event);
      });
      i0.ɵɵpipe(3, "tuiMapper");
      i0.ɵɵpipe(4, "tuiMapper");
      i0.ɵɵpipe(5, "tuiMapper");
      i0.ɵɵprojection(6);
      i0.ɵɵtemplate(7, TuiMultiSelectComponent_ng_template_7_Template, 1, 1, "ng-template", null, 3, i0.ɵɵtemplateRefExtractor)(9, TuiMultiSelectComponent_ng_container_9_Template, 1, 0, "ng-container", 4)(10, TuiMultiSelectComponent_div_10_Template, 2, 6, "div", 5);
      i0.ɵɵelementEnd();
      i0.ɵɵtemplate(11, TuiMultiSelectComponent_ng_template_11_Template, 2, 3, "ng-template", null, 6, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      const _r2 = i0.ɵɵreference(8);
      const _r6 = i0.ɵɵreference(12);
      i0.ɵɵproperty("canOpen", ctx.interactive && !ctx.nativeDropdownMode)("content", ctx.datalist || "")("open", ctx.open);
      i0.ɵɵadvance();
      i0.ɵɵproperty("autoColor", ctx.autoColor)("disabled", ctx.disabled)("disabledItemHandler", i0.ɵɵpipeBind2(3, 23, ctx.disabledItemHandler, ctx.disabledItemHandlerWrapper))("editable", false)("expandable", ctx.expandable)("inputHidden", !ctx.editable)("nativeId", ctx.nativeId)("ngModel", i0.ɵɵpipeBind3(4, 26, ctx.computedValue, ctx.valueMapper, ctx.stringify))("placeholder", ctx.placeholder)("pseudoFocus", ctx.computedFocused)("pseudoHover", ctx.pseudoHover)("pseudoInvalid", ctx.computedInvalid)("readOnly", ctx.readOnly)("removable", !ctx.nativeDropdownMode)("rows", ctx.rows)("search", ctx.searchOrSpace)("tagValidator", i0.ɵɵpipeBind2(5, 30, ctx.tagValidator, ctx.disabledItemHandlerWrapper))("tuiTextfieldIcon", ctx.arrow ? _r6 : "");
      i0.ɵɵadvance(8);
      i0.ɵɵproperty("ngTemplateOutlet", _r2);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.computedGroup);
    }
  },
  dependencies: [i1.TuiHostedDropdownComponent, i2.TuiInputTagComponent, i3.TuiActiveZoneDirective, i4.NgControlStatus, i4.NgModel, i1.TuiTextfieldIconDirective, i2$1.NgIf, i2$1.NgTemplateOutlet, i6.PolymorpheusOutletDirective, i3.TuiPreventDefaultDirective, i1.TuiWrapperDirective, i3.TuiMapperPipe],
  styles: ["[_nghost-%COMP%]{position:relative;display:block;border-radius:var(--tui-radius-m)}._disabled[_nghost-%COMP%]{pointer-events:none}.t-hosted[_ngcontent-%COMP%]{display:block;border-radius:inherit}.t-input[_ngcontent-%COMP%]{border-radius:inherit}[_nghost-%COMP%]:not(._editable):not(._readonly)   .t-input[_ngcontent-%COMP%]{cursor:pointer}.t-content[_ngcontent-%COMP%]{display:flex;align-items:center;pointer-events:none;box-sizing:border-box}.t-primitive[_ngcontent-%COMP%]{white-space:nowrap;overflow:hidden;text-overflow:ellipsis}.t-arrow[_ngcontent-%COMP%]{pointer-events:auto;cursor:pointer}.t-arrow_native-dropdown[_ngcontent-%COMP%]{pointer-events:none}"],
  changeDetection: 0
});
__decorate([tuiPure], TuiMultiSelectComponent.prototype, "getStringifier", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiMultiSelectComponent, [{
    type: Component,
    args: [{
      selector: 'tui-multi-select',
      templateUrl: './multi-select.template.html',
      styleUrls: ['./multi-select.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsFocusableItemAccessor(TuiMultiSelectComponent), tuiAsControl(TuiMultiSelectComponent), tuiAsDataListHost(TuiMultiSelectComponent), TEXTFIELD_CONTROLLER_PROVIDER],
      viewProviders: [FIXED_DROPDOWN_CONTROLLER_PROVIDER]
    }]
  }], function () {
    return [{
      type: i4.NgControl,
      decorators: [{
        type: Optional
      }, {
        type: Self
      }, {
        type: Inject,
        args: [NgControl]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_ARROW_MODE]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_ITEMS_HANDLERS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_MULTI_SELECT_OPTIONS]
      }]
    }, {
      type: i1.TuiTextfieldController,
      decorators: [{
        type: Inject,
        args: [TUI_TEXTFIELD_WATCHED_CONTROLLER]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_IS_MOBILE]
      }]
    }];
  }, {
    accessor: [{
      type: ContentChild,
      args: [TUI_DATA_LIST_ACCESSOR]
    }],
    nativeSelect: [{
      type: ContentChild,
      args: [AbstractTuiNativeMultiSelect, {
        static: true
      }]
    }],
    hostedDropdown: [{
      type: ViewChild,
      args: [TuiHostedDropdownComponent]
    }],
    input: [{
      type: ViewChild,
      args: [TuiInputTagComponent]
    }],
    stringify: [{
      type: Input
    }],
    identityMatcher: [{
      type: Input
    }],
    expandable: [{
      type: Input
    }],
    search: [{
      type: Input
    }],
    placeholder: [{
      type: Input
    }],
    editable: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['class._editable']
    }],
    disabledItemHandler: [{
      type: Input
    }],
    valueContent: [{
      type: Input
    }],
    tagValidator: [{
      type: Input
    }],
    rows: [{
      type: Input
    }],
    autoColor: [{
      type: Input
    }],
    searchChange: [{
      type: Output
    }],
    datalist: [{
      type: ContentChild,
      args: [TuiDataListDirective, {
        read: TemplateRef
      }]
    }],
    size: [{
      type: HostBinding,
      args: ['attr.data-size']
    }],
    getStringifier: []
  });
})();
class TuiHideSelectedPipe {
  constructor(component) {
    this.component = component;
  }
  transform(items) {
    const {
      value,
      identityMatcher
    } = this.component;
    if (!items) {
      return null;
    }
    return tuiIsFlat(items) ? this.filter(items, value, identityMatcher) : this.filter2d(items, value, identityMatcher);
  }
  filter2d(items, value, matcher) {
    return items.map(subItems => this.filter(subItems, value, matcher));
  }
  filter(items, value, matcher) {
    return items.filter(item => value.every(selected => !matcher(selected, item)));
  }
}
TuiHideSelectedPipe.ɵfac = function TuiHideSelectedPipe_Factory(t) {
  return new (t || TuiHideSelectedPipe)(i0.ɵɵdirectiveInject(TuiMultiSelectComponent, 16));
};
TuiHideSelectedPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "tuiHideSelected",
  type: TuiHideSelectedPipe,
  pure: false
});
__decorate([tuiPure], TuiHideSelectedPipe.prototype, "filter2d", null);
__decorate([tuiPure], TuiHideSelectedPipe.prototype, "filter", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHideSelectedPipe, [{
    type: Pipe,
    args: [{
      name: 'tuiHideSelected',
      pure: false
    }]
  }], function () {
    return [{
      type: TuiMultiSelectComponent,
      decorators: [{
        type: Inject,
        args: [TuiMultiSelectComponent]
      }]
    }];
  }, {
    filter2d: [],
    filter: []
  });
})();
class TuiMultiSelectDirective extends AbstractTuiTextfieldHost {
  constructor() {
    super(...arguments);
    this.disableItemHandler = item => this.host.disabledItemHandler(item);
  }
  get readOnly() {
    return true;
  }
  onValueChange() {}
  get stringify() {
    return this.host.stringify;
  }
  onSelectionChange(value) {
    this.host.onValueChange(value);
  }
}
TuiMultiSelectDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiMultiSelectDirective_BaseFactory;
  return function TuiMultiSelectDirective_Factory(t) {
    return (ɵTuiMultiSelectDirective_BaseFactory || (ɵTuiMultiSelectDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiMultiSelectDirective)))(t || TuiMultiSelectDirective);
  };
})();
TuiMultiSelectDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiMultiSelectDirective,
  selectors: [["tui-multi-select"]],
  features: [i0.ɵɵProvidersFeature([tuiAsTextfieldHost(TuiMultiSelectDirective)]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiMultiSelectDirective, [{
    type: Directive,
    args: [{
      selector: 'tui-multi-select',
      providers: [tuiAsTextfieldHost(TuiMultiSelectDirective)]
    }]
  }], null, null);
})();
class TuiMultiSelectGroupComponent {
  constructor(multiSelectTexts$, host, control) {
    this.multiSelectTexts$ = multiSelectTexts$;
    this.host = host;
    this.control = control;
    this.options = EMPTY_QUERY;
    this.label = '';
  }
  get size() {
    var _a;
    return ((_a = this.options.first) === null || _a === void 0 ? void 0 : _a.size) || 'm';
  }
  get empty$() {
    return tuiQueryListChanges(this.options).pipe(map(({
      length
    }) => !length));
  }
  get disabled$() {
    return tuiQueryListChanges(this.options).pipe(map(items => items.every(({
      disabled
    }) => disabled)));
  }
  get value$() {
    return combineLatest([this.items$, this.valueChanges$]).pipe(map(([items, current]) => {
      let result = false;
      for (let i = 0; i < items.length; i++) {
        const selected = current.some(selected => this.matcher(selected, items[i]));
        if (!selected && result || selected && !result && i) {
          return null;
        }
        result = selected;
      }
      return result;
    }));
  }
  onClick(checked) {
    if (!this.control.control) {
      return;
    }
    const controlValue = this.control.value || [];
    const {
      values
    } = this;
    const filtered = controlValue.filter(current => values.every(item => !this.matcher(current, item)));
    this.control.control.setValue(checked ? filtered : [...filtered, ...values]);
  }
  get values() {
    return this.filter(tuiGetOriginalArrayFromQueryList(this.options));
  }
  get matcher() {
    return this.host.identityMatcher || TUI_DEFAULT_IDENTITY_MATCHER;
  }
  get items$() {
    return tuiQueryListChanges(this.options).pipe(map(options => options.map(({
      value
    }) => value).filter(tuiIsPresent)));
  }
  get valueChanges$() {
    return tuiControlValue(this.control).pipe(map(value => value || []));
  }
  filter(items) {
    return items.map(({
      value
    }) => value).filter(tuiIsPresent);
  }
}
TuiMultiSelectGroupComponent.ɵfac = function TuiMultiSelectGroupComponent_Factory(t) {
  return new (t || TuiMultiSelectGroupComponent)(i0.ɵɵdirectiveInject(TUI_MULTI_SELECT_TEXTS), i0.ɵɵdirectiveInject(TUI_DATA_LIST_HOST), i0.ɵɵdirectiveInject(NgControl));
};
TuiMultiSelectGroupComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiMultiSelectGroupComponent,
  selectors: [["tui-opt-group", "tuiMultiSelectGroup", ""]],
  contentQueries: function TuiMultiSelectGroupComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, TuiOptionComponent, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.options = _t);
    }
  },
  hostVars: 2,
  hostBindings: function TuiMultiSelectGroupComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("_label", ctx.label);
    }
  },
  inputs: {
    label: "label"
  },
  attrs: _c3,
  ngContentSelectors: _c4,
  decls: 3,
  vars: 3,
  consts: [["class", "t-wrapper", 4, "tuiLet"], [1, "t-wrapper"], [1, "t-label"], ["tuiLink", "", "type", "button", "class", "t-button", 3, "disabled", "click", 4, "ngIf"], ["tuiLink", "", "type", "button", 1, "t-button", 3, "disabled", "click"]],
  template: function TuiMultiSelectGroupComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵtemplate(0, TuiMultiSelectGroupComponent_span_0_Template, 5, 4, "span", 0);
      i0.ɵɵpipe(1, "async");
      i0.ɵɵprojection(2);
    }
    if (rf & 2) {
      i0.ɵɵproperty("tuiLet", i0.ɵɵpipeBind1(1, 1, ctx.value$));
    }
  },
  dependencies: [i1.TuiLinkComponent, i3.TuiLetDirective, i2$1.NgIf, i2$1.AsyncPipe],
  styles: ["._label[_nghost-%COMP%]:before{display:none}[_nghost-%COMP%]:not(:first-of-type)   .t-label[_ngcontent-%COMP%]:not(:empty){padding-top:1.25rem}[_nghost-%COMP%]:not(:first-of-type)   .t-button[_ngcontent-%COMP%]{margin-top:1.25rem}.t-wrapper[_ngcontent-%COMP%]{display:flex;align-items:flex-start}.t-label[_ngcontent-%COMP%]:not(:empty){flex:1;padding:.75rem 1rem .25rem .625rem}.t-button[_ngcontent-%COMP%]{margin:.75rem 1rem 0 auto}"],
  changeDetection: 0
});
__decorate([tuiPure], TuiMultiSelectGroupComponent.prototype, "empty$", null);
__decorate([tuiPure], TuiMultiSelectGroupComponent.prototype, "disabled$", null);
__decorate([tuiPure], TuiMultiSelectGroupComponent.prototype, "value$", null);
__decorate([tuiPure], TuiMultiSelectGroupComponent.prototype, "items$", null);
__decorate([tuiPure], TuiMultiSelectGroupComponent.prototype, "valueChanges$", null);
__decorate([tuiPure], TuiMultiSelectGroupComponent.prototype, "filter", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiMultiSelectGroupComponent, [{
    type: Component,
    args: [{
      selector: 'tui-opt-group[tuiMultiSelectGroup]',
      templateUrl: './multi-select-group.template.html',
      styleUrls: ['./multi-select-group.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_MULTI_SELECT_TEXTS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_DATA_LIST_HOST]
      }]
    }, {
      type: i4.NgControl,
      decorators: [{
        type: Inject,
        args: [NgControl]
      }]
    }];
  }, {
    options: [{
      type: ContentChildren,
      args: [TuiOptionComponent]
    }],
    label: [{
      type: HostBinding,
      args: ['class._label']
    }, {
      type: Input
    }],
    empty$: [],
    disabled$: [],
    value$: [],
    items$: [],
    valueChanges$: [],
    filter: []
  });
})();
const TUI_MULTI_SELECT_OPTION = new PolymorpheusComponent(TuiMultiSelectOptionComponent);
class TuiMultiSelectGroupDirective {}
TuiMultiSelectGroupDirective.ɵfac = function TuiMultiSelectGroupDirective_Factory(t) {
  return new (t || TuiMultiSelectGroupDirective)();
};
TuiMultiSelectGroupDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiMultiSelectGroupDirective,
  selectors: [["", "tuiMultiSelectGroup", ""]],
  features: [i0.ɵɵProvidersFeature([tuiAsOptionContent(TUI_MULTI_SELECT_OPTION), {
    provide: TUI_DATA_LIST_HOST,
    deps: [NgControl, [new Optional(), forwardRef(() => TuiMultiSelectComponent)]],
    useFactory: (control, host) => host || {
      handleOption: option => {
        var _a;
        return (_a = control.control) === null || _a === void 0 ? void 0 : _a.setValue(tuiArrayToggle(control.value || [], option));
      }
    }
  }, {
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useValue: {
      writeValue: EMPTY_FUNCTION,
      registerOnChange: EMPTY_FUNCTION,
      registerOnTouched: EMPTY_FUNCTION
    }
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiMultiSelectGroupDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiMultiSelectGroup]',
      providers: [tuiAsOptionContent(TUI_MULTI_SELECT_OPTION), {
        provide: TUI_DATA_LIST_HOST,
        deps: [NgControl, [new Optional(), forwardRef(() => TuiMultiSelectComponent)]],
        useFactory: (control, host) => host || {
          handleOption: option => {
            var _a;
            return (_a = control.control) === null || _a === void 0 ? void 0 : _a.setValue(tuiArrayToggle(control.value || [], option));
          }
        }
      }, {
        provide: NG_VALUE_ACCESSOR,
        multi: true,
        useValue: {
          writeValue: EMPTY_FUNCTION,
          registerOnChange: EMPTY_FUNCTION,
          registerOnTouched: EMPTY_FUNCTION
        }
      }]
    }]
  }], null, null);
})();
class TuiNativeMultiSelectComponent extends AbstractTuiNativeMultiSelect {
  constructor() {
    super(...arguments);
    this.items = [];
  }
  onValueChange(selectedOptions) {
    var _a;
    const selected = Array.from(selectedOptions).map(option => option.index);
    const value = ((_a = this.items) === null || _a === void 0 ? void 0 : _a.filter((_, index) => selected.includes(index))) || [];
    this.host.onSelectionChange(value);
  }
}
TuiNativeMultiSelectComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiNativeMultiSelectComponent_BaseFactory;
  return function TuiNativeMultiSelectComponent_Factory(t) {
    return (ɵTuiNativeMultiSelectComponent_BaseFactory || (ɵTuiNativeMultiSelectComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TuiNativeMultiSelectComponent)))(t || TuiNativeMultiSelectComponent);
  };
})();
TuiNativeMultiSelectComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiNativeMultiSelectComponent,
  selectors: [["select", "multiple", "", "tuiSelect", "", 3, "labels", ""]],
  hostVars: 3,
  hostBindings: function TuiNativeMultiSelectComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("change", function TuiNativeMultiSelectComponent_change_HostBindingHandler($event) {
        return ctx.onValueChange($event.target.selectedOptions);
      })("click.stop.silent", function TuiNativeMultiSelectComponent_click_stop_silent_HostBindingHandler() {
        return 0;
      })("mousedown.stop.silent", function TuiNativeMultiSelectComponent_mousedown_stop_silent_HostBindingHandler() {
        return 0;
      });
    }
    if (rf & 2) {
      i0.ɵɵhostProperty("disabled", ctx.host.disabled || ctx.control.readOnly)("tabIndex", ctx.host.focusable ? 0 : -1);
      i0.ɵɵattribute("aria-invalid", ctx.host.invalid);
    }
  },
  inputs: {
    items: "items"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsDataList(TuiNativeMultiSelectComponent), {
    provide: TemplateRef,
    deps: [TuiNativeMultiSelectComponent],
    useFactory: ({
      datalist
    }) => datalist
  }, {
    provide: AbstractTuiNativeMultiSelect,
    useExisting: TuiNativeMultiSelectComponent
  }]), i0.ɵɵInheritDefinitionFeature],
  attrs: _c5,
  decls: 4,
  vars: 4,
  consts: [[4, "ngIf"], ["tuiMultiSelectGroup", "", 3, "disabledItemHandler", "itemContent", "items", 4, "tuiDataList"], [3, "disabled", "selected", "value", 4, "ngFor", "ngForOf"], ["tuiMultiSelectGroup", "", 3, "disabledItemHandler", "itemContent", "items"], [3, "disabled", "selected", "value"]],
  template: function TuiNativeMultiSelectComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiNativeMultiSelectComponent_ng_container_0_Template, 1, 0, "ng-container", 0);
      i0.ɵɵpipe(1, "async");
      i0.ɵɵtemplate(2, TuiNativeMultiSelectComponent_tui_data_list_wrapper_2_Template, 2, 5, "tui-data-list-wrapper", 1)(3, TuiNativeMultiSelectComponent_option_3_Template, 3, 8, "option", 2);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 2, ctx.control.control == null ? null : ctx.control.control.valueChanges));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngForOf", ctx.items);
    }
  },
  dependencies: [i1$1.TuiDataListWrapperComponent, i2$1.NgIf, i1.TuiDataListDirective, TuiMultiSelectGroupDirective, i2$1.NgForOf, i4.NgSelectOption, i4.ɵNgSelectMultipleOption, i2$1.AsyncPipe, i6$1.TuiStringifyContentPipe, i3.TuiMapperPipe],
  styles: ["[_nghost-%COMP%]{position:absolute;top:0;left:0;width:100%;height:100%;opacity:0}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiNativeMultiSelectComponent, [{
    type: Component,
    args: [{
      selector: 'select[multiple][tuiSelect]:not([labels])',
      templateUrl: './native-multi-select.template.html',
      styleUrls: ['./native-multi-select.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsDataList(TuiNativeMultiSelectComponent), {
        provide: TemplateRef,
        deps: [TuiNativeMultiSelectComponent],
        useFactory: ({
          datalist
        }) => datalist
      }, {
        provide: AbstractTuiNativeMultiSelect,
        useExisting: TuiNativeMultiSelectComponent
      }],
      host: {
        '[attr.aria-invalid]': 'host.invalid',
        '[disabled]': 'host.disabled || control.readOnly',
        '[tabIndex]': 'host.focusable ? 0 : -1',
        '(change)': 'onValueChange($event.target.selectedOptions)',
        '(click.stop.silent)': '0',
        '(mousedown.stop.silent)': '0'
      }
    }]
  }], null, {
    items: [{
      type: Input
    }]
  });
})();
class TuiNativeMultiSelectGroupComponent extends AbstractTuiNativeMultiSelect {
  constructor() {
    super(...arguments);
    this.items = [];
    this.labels = [];
  }
  onValueChange(selectedOptions) {
    var _a;
    const selected = Array.from(selectedOptions).map(option => option.index);
    const flatItems = ((_a = this.items) === null || _a === void 0 ? void 0 : _a.reduce((acc, val) => acc.concat(val), [])) || [];
    const value = flatItems.filter((_, index) => selected.includes(index));
    this.host.onSelectionChange(value);
  }
}
TuiNativeMultiSelectGroupComponent.ɵfac = /* @__PURE__ */(() => {
  let ɵTuiNativeMultiSelectGroupComponent_BaseFactory;
  return function TuiNativeMultiSelectGroupComponent_Factory(t) {
    return (ɵTuiNativeMultiSelectGroupComponent_BaseFactory || (ɵTuiNativeMultiSelectGroupComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TuiNativeMultiSelectGroupComponent)))(t || TuiNativeMultiSelectGroupComponent);
  };
})();
TuiNativeMultiSelectGroupComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiNativeMultiSelectGroupComponent,
  selectors: [["select", "multiple", "", "tuiSelect", "", "labels", ""]],
  hostVars: 3,
  hostBindings: function TuiNativeMultiSelectGroupComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("change", function TuiNativeMultiSelectGroupComponent_change_HostBindingHandler($event) {
        return ctx.onValueChange($event.target.selectedOptions);
      })("click.stop.silent", function TuiNativeMultiSelectGroupComponent_click_stop_silent_HostBindingHandler() {
        return 0;
      })("mousedown.stop.silent", function TuiNativeMultiSelectGroupComponent_mousedown_stop_silent_HostBindingHandler() {
        return 0;
      });
    }
    if (rf & 2) {
      i0.ɵɵhostProperty("disabled", ctx.host.disabled || ctx.control.readOnly)("tabIndex", ctx.host.focusable ? 0 : -1);
      i0.ɵɵattribute("aria-invalid", ctx.host.invalid);
    }
  },
  inputs: {
    items: "items",
    labels: "labels"
  },
  features: [i0.ɵɵProvidersFeature([tuiAsDataList(TuiNativeMultiSelectGroupComponent), {
    provide: TemplateRef,
    deps: [TuiNativeMultiSelectGroupComponent],
    useFactory: ({
      datalist
    }) => datalist
  }, {
    provide: AbstractTuiNativeMultiSelect,
    useExisting: TuiNativeMultiSelectGroupComponent
  }]), i0.ɵɵInheritDefinitionFeature],
  attrs: _c6,
  decls: 4,
  vars: 4,
  consts: [[4, "ngIf"], ["tuiMultiSelectGroup", "", 3, "disabledItemHandler", "itemContent", "items", "labels", 4, "tuiDataList"], [3, "label", 4, "ngFor", "ngForOf"], ["tuiMultiSelectGroup", "", 3, "disabledItemHandler", "itemContent", "items", "labels"], [3, "label"], [3, "disabled", "selected", "value", 4, "ngFor", "ngForOf"], [3, "disabled", "selected", "value"]],
  template: function TuiNativeMultiSelectGroupComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiNativeMultiSelectGroupComponent_ng_container_0_Template, 1, 0, "ng-container", 0);
      i0.ɵɵpipe(1, "async");
      i0.ɵɵtemplate(2, TuiNativeMultiSelectGroupComponent_tui_data_list_wrapper_2_Template, 2, 6, "tui-data-list-wrapper", 1)(3, TuiNativeMultiSelectGroupComponent_optgroup_3_Template, 2, 2, "optgroup", 2);
    }
    if (rf & 2) {
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(1, 2, ctx.control.control == null ? null : ctx.control.control.valueChanges));
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("ngForOf", ctx.items);
    }
  },
  dependencies: [i1$1.TuiDataListGroupWrapperComponent, i2$1.NgIf, i1.TuiDataListDirective, TuiMultiSelectGroupDirective, i2$1.NgForOf, i4.NgSelectOption, i4.ɵNgSelectMultipleOption, i2$1.AsyncPipe, i6$1.TuiStringifyContentPipe, i3.TuiMapperPipe],
  styles: [_c7],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiNativeMultiSelectGroupComponent, [{
    type: Component,
    args: [{
      selector: 'select[multiple][tuiSelect][labels]',
      templateUrl: './native-multi-select-group.template.html',
      styleUrls: ['./native-multi-select.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsDataList(TuiNativeMultiSelectGroupComponent), {
        provide: TemplateRef,
        deps: [TuiNativeMultiSelectGroupComponent],
        useFactory: ({
          datalist
        }) => datalist
      }, {
        provide: AbstractTuiNativeMultiSelect,
        useExisting: TuiNativeMultiSelectGroupComponent
      }],
      host: {
        '[attr.aria-invalid]': 'host.invalid',
        '[disabled]': 'host.disabled || control.readOnly',
        '[tabIndex]': 'host.focusable ? 0 : -1',
        '(change)': 'onValueChange($event.target.selectedOptions)',
        '(click.stop.silent)': '0',
        '(mousedown.stop.silent)': '0'
      }
    }]
  }], null, {
    items: [{
      type: Input
    }],
    labels: [{
      type: Input
    }]
  });
})();
class TuiMultiSelectModule {}
TuiMultiSelectModule.ɵfac = function TuiMultiSelectModule_Factory(t) {
  return new (t || TuiMultiSelectModule)();
};
TuiMultiSelectModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiMultiSelectModule
});
TuiMultiSelectModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, FormsModule, PolymorpheusModule, TuiWrapperModule, TuiPreventDefaultModule, TuiActiveZoneModule, TuiArrowModule, TuiLetModule, TuiMapperPipeModule, TuiSvgModule, TuiHostedDropdownModule, TuiInputTagModule, TuiMultiSelectOptionModule, TuiDataListWrapperModule, TuiMapperPipeModule, TuiLinkModule, TuiDataListModule, TuiTextfieldControllerModule, TuiStringifyContentPipeModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiMultiSelectModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, FormsModule, PolymorpheusModule, TuiWrapperModule, TuiPreventDefaultModule, TuiActiveZoneModule, TuiArrowModule, TuiLetModule, TuiMapperPipeModule, TuiSvgModule, TuiHostedDropdownModule, TuiInputTagModule, TuiMultiSelectOptionModule, TuiDataListWrapperModule, TuiMapperPipeModule, TuiLinkModule, TuiDataListModule, TuiTextfieldControllerModule, TuiStringifyContentPipeModule],
      declarations: [TuiMultiSelectComponent, TuiMultiSelectGroupComponent, TuiMultiSelectGroupDirective, TuiHideSelectedPipe, TuiNativeMultiSelectComponent, TuiNativeMultiSelectGroupComponent, TuiMultiSelectDirective],
      exports: [TuiMultiSelectComponent, TuiMultiSelectGroupComponent, TuiMultiSelectGroupDirective, TuiHideSelectedPipe, TuiMultiSelectDirective, TuiNativeMultiSelectComponent, TuiNativeMultiSelectGroupComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AbstractTuiNativeMultiSelect, TUI_MULTI_SELECT_DEFAULT_OPTIONS, TUI_MULTI_SELECT_OPTION, TUI_MULTI_SELECT_OPTIONS, TuiHideSelectedPipe, TuiMultiSelectComponent, TuiMultiSelectDirective, TuiMultiSelectGroupComponent, TuiMultiSelectGroupDirective, TuiMultiSelectModule, TuiNativeMultiSelectComponent, TuiNativeMultiSelectGroupComponent, tuiMultiSelectOptionsProvider };
