import { Pipe, PipeTransform } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslocoService } from '@ngneat/transloco';
import { PositionFactory } from 'src/app/core/factories/position.factory';

@UntilDestroy()
@Pipe({
  name: 'myPositions',
})
export class MyPositionsPipe implements PipeTransform {
  constructor(
    public translocoService: TranslocoService,
    public positionFactory: PositionFactory,
  ) {}

  transform(ids: number[]): string {
    if (!ids) {
      return '';
    }

    return getItemHtml(this, ids);
  }
}

function getItemHtml(that: MyPositionsPipe, ids: number[]): string {
  const translatedArr = ids.map((id) => that.translocoService.translate(`positions.${id}`));

  return `<span class="positions-in-column">${translatedArr.join(', ')}</span>`;
}
