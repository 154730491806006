import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './app-alert.component.html',
  styleUrl: './app-alert.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppAlertComponent {
  @Input() type: AlertType = AlertType.Info;
  @Input() showIcon?: boolean = true;
}

export enum AlertType {
  Info = 'info',
  Error = 'error',
  Warning = 'warning',
}
