import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class ClientSideSearchService {
  doFilterItems(filterItem: FilterRequestItem, data: any, type: SearchCriterionsDataType): any {
    const propertyNames = filterItem?.propertyNames?.[0] ?? '';

    if (type === SearchCriterionsDataType.Boolean) {
      return data.filter(
        (item: any) =>
          _.get(item, propertyNames) === (filterItem.propertyValue === ActiveInactiveEnum.INACTIVE),
      );
    }
    if (type === SearchCriterionsDataType.Integer) {
      return this.processIntegerContainsFromArray(filterItem, data);
    } else if (type === SearchCriterionsDataType.ArrayNumber) {
      return this.processArrayNumberContainsFromArray(filterItem, data);
    } else {
      switch (filterItem.operator) {
        case FILTER_OPERATOR.Equal: {
          return data.filter(
            (item: any) =>
              _.toLower(_.toString(_.get(item, propertyNames))) ===
              filterItem.propertyValue?.toLowerCase()?.toString(),
          );
        }
        case FILTER_OPERATOR.Contains: {
          return data.filter((item: any) =>
            _.includes(
              _.toLower(_.toString(_.get(item, propertyNames))),
              filterItem.propertyValue?.toLowerCase()?.toString(),
            ),
          );
        }
      }
    }
  }

  processIntegerContainsFromArray(filterItem: FilterRequestItem, data: any): boolean {
    const propertyNames = filterItem?.propertyNames?.[0] ?? '';
    const arrOfFilterValues = filterItem.propertyValue?.split(',') || [];
    const arrOfFilterValuesAsNumbers = arrOfFilterValues.map((filterValue) => +filterValue);

    return data.filter((item: any) => {
      if (filterItem.propertyValue === '') {
        return true;
      }

      let isOk = false;
      const dataValue = _.get(item, propertyNames);

      if (arrOfFilterValuesAsNumbers.indexOf(dataValue) !== -1) {
        isOk = true;
      }

      return isOk;
    });
  }

  processArrayNumberContainsFromArray(filterItem: FilterRequestItem, data: any): boolean {
    const propertyNames = filterItem?.propertyNames?.[0] ?? '';
    const arrOfFilterValues = filterItem.propertyValue?.split(',') || [];
    const arrOfFilterValuesAsNumbers = arrOfFilterValues.map((filterValue) => +filterValue);

    return data.filter((item: any) => {
      if (filterItem.propertyValue === '') {
        return true;
      }

      let isOk = false;
      const dataValues = _.get(item, propertyNames);

      dataValues.forEach((dataValue: number) => {
        if (arrOfFilterValuesAsNumbers.indexOf(dataValue) !== -1) {
          isOk = true;
        }
      });

      return isOk;
    });
  }
}

export enum FILTER_OPERATOR {
  Equal = 1,
  Contains = 2,
  ContainsFromArray = 3,
}

export class FilterRequestItem {
  entity?: string;
  propertyNames?: string[];
  propertyValue?: string;
  operator?: FILTER_OPERATOR;
}

export enum SearchCriterionsDataType {
  String = 1,
  Integer = 2,
  Decimal = 3,
  DateTime = 4,
  Boolean = 5,
  ArrayNumber = 6,
}

export class SearchOperator {
  id!: number;
  description!: string;
}

export enum ActiveInactiveEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}
