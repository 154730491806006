import * as i0 from '@angular/core';
import { ElementRef, Directive, Inject, Self, Input, NgModule } from '@angular/core';
import { TuiDestroyService } from '@taiga-ui/cdk';
import { TUI_SCROLL_INTO_VIEW } from '@taiga-ui/core/constants';
import * as i1 from 'rxjs';
import { timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * Directive scrolls element into view inside tui-scrollbar
 */
class TuiScrollIntoViewDirective {
  constructor(el, destroy$) {
    this.el = el;
    this.destroy$ = destroy$;
  }
  set tuiScrollIntoView(scroll) {
    if (!scroll) {
      return;
    }
    // Timeout is necessary in order to give element render cycle to get into its final spot
    // (for example if it is inside dropdown box which has to be positioned first)
    timer(0).pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.el.nativeElement.dispatchEvent(new CustomEvent(TUI_SCROLL_INTO_VIEW, {
        bubbles: true,
        detail: this.el.nativeElement
      }));
    });
  }
}
TuiScrollIntoViewDirective.ɵfac = function TuiScrollIntoViewDirective_Factory(t) {
  return new (t || TuiScrollIntoViewDirective)(i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(TuiDestroyService, 2));
};
TuiScrollIntoViewDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiScrollIntoViewDirective,
  selectors: [["", "tuiScrollIntoView", ""]],
  inputs: {
    tuiScrollIntoView: "tuiScrollIntoView"
  },
  features: [i0.ɵɵProvidersFeature([TuiDestroyService])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiScrollIntoViewDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiScrollIntoView]',
      providers: [TuiDestroyService]
    }]
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }];
  }, {
    tuiScrollIntoView: [{
      type: Input
    }]
  });
})();
class TuiScrollIntoViewModule {}
TuiScrollIntoViewModule.ɵfac = function TuiScrollIntoViewModule_Factory(t) {
  return new (t || TuiScrollIntoViewModule)();
};
TuiScrollIntoViewModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiScrollIntoViewModule
});
TuiScrollIntoViewModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiScrollIntoViewModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiScrollIntoViewDirective],
      exports: [TuiScrollIntoViewDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiScrollIntoViewDirective, TuiScrollIntoViewModule };
