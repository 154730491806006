import * as i1 from '@angular/common';
import { isPlatformServer, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { ChangeDetectorRef, PLATFORM_ID, Component, ChangeDetectionStrategy, Inject, Self, NgModule } from '@angular/core';
import * as i3 from '@taiga-ui/cdk';
import { TuiDestroyService, TuiLetModule } from '@taiga-ui/cdk';
import * as i2 from '@taiga-ui/core/services';
import { TuiSvgService } from '@taiga-ui/core/services';
import { takeUntil } from 'rxjs/operators';
function TuiSvgDefsHostComponent__svg_ng_container_1__svg_defs_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelement(0, "defs", 4);
  }
  if (rf & 2) {
    const def_r4 = ctx.$implicit;
    i0.ɵɵproperty("innerHTML", def_r4, i0.ɵɵsanitizeHtml);
  }
}
function TuiSvgDefsHostComponent__svg_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TuiSvgDefsHostComponent__svg_ng_container_1__svg_defs_1_Template, 1, 1, "defs", 3);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngForOf", ctx_r0.items);
  }
}
function TuiSvgDefsHostComponent__svg_ng_template_2__svg_defs_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(0, "defs");
    i0.ɵɵelement(1, "svg", 6);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const def_r6 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵproperty("outerHTML", def_r6, i0.ɵɵsanitizeHtml);
  }
}
function TuiSvgDefsHostComponent__svg_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵnamespaceSVG();
    i0.ɵɵtemplate(0, TuiSvgDefsHostComponent__svg_ng_template_2__svg_defs_0_Template, 2, 1, "defs", 5);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngForOf", ctx_r1.items);
  }
}
class TuiSvgDefsHostComponent {
  constructor(svgService, cdr, destroy$, platformId) {
    this.svgService = svgService;
    this.cdr = cdr;
    this.destroy$ = destroy$;
    this.isBrowser = true;
    this.isBrowser = !isPlatformServer(platformId);
  }
  // @bad TODO: Looks like it could be async piped but it was probably written like that for a reason
  ngOnInit() {
    this.svgService.items$.pipe(takeUntil(this.destroy$)).subscribe(defsMap => {
      this.items = defsMap.values();
      this.cdr.detectChanges();
    });
  }
}
TuiSvgDefsHostComponent.ɵfac = function TuiSvgDefsHostComponent_Factory(t) {
  return new (t || TuiSvgDefsHostComponent)(i0.ɵɵdirectiveInject(TuiSvgService), i0.ɵɵdirectiveInject(ChangeDetectorRef), i0.ɵɵdirectiveInject(TuiDestroyService, 2), i0.ɵɵdirectiveInject(PLATFORM_ID));
};
TuiSvgDefsHostComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiSvgDefsHostComponent,
  selectors: [["tui-svg-defs-host"]],
  features: [i0.ɵɵProvidersFeature([TuiDestroyService])],
  decls: 4,
  vars: 2,
  consts: [["version", "1.1", 0, "xmlns", "xlink", "http://www.w3.org/1999/xlink", "xmlns", "http://www.w3.org/2000/svg"], [4, "ngIf", "ngIfElse"], ["server", ""], [3, "innerHTML", 4, "ngFor", "ngForOf"], [3, "innerHTML"], [4, "ngFor", "ngForOf"], [3, "outerHTML"]],
  template: function TuiSvgDefsHostComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵnamespaceSVG();
      i0.ɵɵelementStart(0, "svg", 0);
      i0.ɵɵtemplate(1, TuiSvgDefsHostComponent__svg_ng_container_1_Template, 2, 1, "ng-container", 1)(2, TuiSvgDefsHostComponent__svg_ng_template_2_Template, 1, 1, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      const _r2 = i0.ɵɵreference(3);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", ctx.isBrowser)("ngIfElse", _r2);
    }
  },
  dependencies: [i1.NgIf, i1.NgForOf],
  styles: ["[_nghost-%COMP%]{display:none}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSvgDefsHostComponent, [{
    type: Component,
    args: [{
      selector: 'tui-svg-defs-host',
      templateUrl: './svg-defs-host.template.html',
      styleUrls: ['./svg-defs-host.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [TuiDestroyService]
    }]
  }], function () {
    return [{
      type: i2.TuiSvgService,
      decorators: [{
        type: Inject,
        args: [TuiSvgService]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: i3.TuiDestroyService,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }];
  }, null);
})();
class TuiSvgDefsHostModule {}
TuiSvgDefsHostModule.ɵfac = function TuiSvgDefsHostModule_Factory(t) {
  return new (t || TuiSvgDefsHostModule)();
};
TuiSvgDefsHostModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiSvgDefsHostModule
});
TuiSvgDefsHostModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, TuiLetModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSvgDefsHostModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiLetModule],
      declarations: [TuiSvgDefsHostComponent],
      exports: [TuiSvgDefsHostComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiSvgDefsHostComponent, TuiSvgDefsHostModule };
