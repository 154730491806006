import * as i0 from '@angular/core';
import { Injectable, ComponentFactoryResolver, INJECTOR, Inject, ElementRef, ChangeDetectorRef, Self, NgZone, PLATFORM_ID } from '@angular/core';
import * as i1 from 'rxjs';
import { ReplaySubject, Observable, defer, merge, fromEvent, of, timer, from } from 'rxjs';
import { tuiFocusVisibleObservable, tuiWatch, tuiTypedFromEvent, tuiZoneOptimized, tuiZonefree, tuiPreventDefault } from '@taiga-ui/cdk/observables';
import { takeUntil, delay, throttleTime, map, startWith, distinctUntilChanged, switchMap, filter, pairwise, repeat, catchError, debounceTime, share, endWith, tap, shareReplay, scan } from 'rxjs/operators';
import { WINDOW, ANIMATION_FRAME, PERFORMANCE } from '@ng-web-apis/common';
import { POLLING_TIME, EMPTY_ARRAY } from '@taiga-ui/cdk/constants';
import { tuiGetElementObscures } from '@taiga-ui/cdk/utils/dom';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { ResizeObserverService, RESIZE_OBSERVER_SUPPORT, RESIZE_OPTION_BOX } from '@ng-web-apis/resize-observer';
import { tuiAssert } from '@taiga-ui/cdk/classes';
import { tuiClamp } from '@taiga-ui/cdk/utils/math';
import { tuiEaseInOutQuad, tuiGetSwipeDirection, tuiIsPresent } from '@taiga-ui/cdk/utils/miscellaneous';
import { __awaiter } from 'tslib';
import { fromFetch } from 'rxjs/fetch';
import { TUI_SWIPE_OPTIONS, TUI_ZOOM_OPTIONS } from '@taiga-ui/cdk/tokens';
import { tuiDistanceBetweenTouches } from '@taiga-ui/cdk/utils';

/**
 * @note:
 * Observable abstraction over ngOnDestroy to use with takeUntil
 *
 * Why we use `ReplaySubject` instead of `Subject`?
 * Well, we’ll use ReplaySubject to emit the last message in case
 * the subscription is ended after the component is destroyed.
 */
class TuiDestroyService extends ReplaySubject {
  constructor() {
    super(1);
  }
  ngOnDestroy() {
    this.next();
  }
}
TuiDestroyService.ɵfac = function TuiDestroyService_Factory(t) {
  return new (t || TuiDestroyService)();
};
TuiDestroyService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiDestroyService,
  factory: TuiDestroyService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDestroyService, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();

/**
 * Service to use styles with directives
 */
class TuiDirectiveStylesService {
  constructor(resolver, injector) {
    this.resolver = resolver;
    this.injector = injector;
    this.map = new Map();
  }
  addComponent(component) {
    if (!this.map.has(component)) {
      this.map.set(component, this.resolver.resolveComponentFactory(component).create(this.injector));
    }
  }
  ngOnDestroy() {
    this.map.forEach(value => value.destroy());
  }
}
TuiDirectiveStylesService.ɵfac = function TuiDirectiveStylesService_Factory(t) {
  return new (t || TuiDirectiveStylesService)(i0.ɵɵinject(ComponentFactoryResolver), i0.ɵɵinject(INJECTOR));
};
TuiDirectiveStylesService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiDirectiveStylesService,
  factory: TuiDirectiveStylesService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiDirectiveStylesService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: i0.ComponentFactoryResolver,
      decorators: [{
        type: Inject,
        args: [ComponentFactoryResolver]
      }]
    }, {
      type: i0.Injector,
      decorators: [{
        type: Inject,
        args: [INJECTOR]
      }]
    }];
  }, null);
})();

/**
 * Service to imitate :focus-visible
 * (https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible)
 * in browsers that do not support it
 */
class TuiFocusVisibleService extends Observable {
  constructor({
    nativeElement
  }, cdr, destroy$) {
    super(subscriber => this.focusVisible$.subscribe(subscriber));
    this.focusVisible$ = tuiFocusVisibleObservable(nativeElement).pipe(tuiWatch(cdr), takeUntil(destroy$));
  }
}
TuiFocusVisibleService.ɵfac = function TuiFocusVisibleService_Factory(t) {
  return new (t || TuiFocusVisibleService)(i0.ɵɵinject(ElementRef), i0.ɵɵinject(ChangeDetectorRef), i0.ɵɵinject(TuiDestroyService, 2));
};
TuiFocusVisibleService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiFocusVisibleService,
  factory: TuiFocusVisibleService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFocusVisibleService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i0.ChangeDetectorRef,
      decorators: [{
        type: Inject,
        args: [ChangeDetectorRef]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }];
  }, null);
})();
const TUI = 'tui_';
/**
 * Generates unique ids
 */
class TuiIdService {
  generate() {
    return `${TUI}${TuiIdService.autoId++}${Date.now()}`;
  }
}
TuiIdService.autoId = 0;
TuiIdService.ɵfac = function TuiIdService_Factory(t) {
  return new (t || TuiIdService)();
};
TuiIdService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiIdService,
  factory: TuiIdService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiIdService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();

/**
 * Service that subscribes to scroll events of all parent elements
 */
class TuiParentsScrollService extends Observable {
  constructor(
  // Destructuring here causes memory leak
  el, win) {
    super(subscriber => this.callback$.subscribe(subscriber));
    this.callback$ = defer(() => {
      let {
        nativeElement
      } = el;
      const eventTargets = [win, nativeElement];
      while (nativeElement.parentElement) {
        nativeElement = nativeElement.parentElement;
        eventTargets.push(nativeElement);
      }
      return merge(...eventTargets.map(element => tuiTypedFromEvent(element, 'scroll')));
    });
  }
}
TuiParentsScrollService.ɵfac = function TuiParentsScrollService_Factory(t) {
  return new (t || TuiParentsScrollService)(i0.ɵɵinject(ElementRef), i0.ɵɵinject(WINDOW));
};
TuiParentsScrollService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiParentsScrollService,
  factory: TuiParentsScrollService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiParentsScrollService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: Window,
      decorators: [{
        type: Inject,
        args: [WINDOW]
      }]
    }];
  }, null);
})();

// @bad TODO: Consider Intersection Observer with fallback to current implementation
/**
 * Service that monitors element visibility by subscribing to scrolls
 * and polling with set interval, returns either null or an array
 * of elements that overlap given element edges
 */
class TuiObscuredService extends Observable {
  constructor(parentsScroll$, {
    nativeElement
  }, zone, win, destroy$, animationFrame$) {
    super(subscriber => this.obscured$.subscribe(subscriber));
    this.obscured$ = merge(
    // delay is added so it will not interfere with other listeners
    merge(parentsScroll$, fromEvent(win, 'resize')).pipe(delay(0)), animationFrame$.pipe(throttleTime(POLLING_TIME))).pipe(map(() => tuiGetElementObscures(nativeElement)), startWith(null), distinctUntilChanged(), tuiZoneOptimized(zone), takeUntil(destroy$));
  }
}
TuiObscuredService.ɵfac = function TuiObscuredService_Factory(t) {
  return new (t || TuiObscuredService)(i0.ɵɵinject(TuiParentsScrollService, 2), i0.ɵɵinject(ElementRef), i0.ɵɵinject(NgZone), i0.ɵɵinject(WINDOW), i0.ɵɵinject(TuiDestroyService, 2), i0.ɵɵinject(ANIMATION_FRAME));
};
TuiObscuredService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiObscuredService,
  factory: TuiObscuredService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiObscuredService, [{
    type: Injectable
  }], function () {
    return [{
      type: TuiParentsScrollService,
      decorators: [{
        type: Inject,
        args: [TuiParentsScrollService]
      }, {
        type: Self
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i0.NgZone,
      decorators: [{
        type: Inject,
        args: [NgZone]
      }]
    }, {
      type: Window,
      decorators: [{
        type: Inject,
        args: [WINDOW]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [ANIMATION_FRAME]
      }]
    }];
  }, null);
})();
class TuiPanService extends Observable {
  constructor({
    nativeElement
  }, doc) {
    super(subscriber => {
      merge(tuiTypedFromEvent(nativeElement, 'touchstart', {
        passive: true
      }), tuiTypedFromEvent(nativeElement, 'mousedown')).pipe(switchMap(() => merge(tuiTypedFromEvent(doc, 'touchmove', {
        passive: true
      }).pipe(filter(({
        touches
      }) => touches.length < 2), map(({
        touches
      }) => touches[0])), tuiTypedFromEvent(doc, 'mousemove'))), pairwise(), map(([first, second]) => {
        const deltaX = second.clientX - first.clientX;
        const deltaY = second.clientY - first.clientY;
        return [deltaX, deltaY];
      }),
      // eslint-disable-next-line rxjs/no-unsafe-takeuntil
      takeUntil(merge(tuiTypedFromEvent(doc, 'touchend'), tuiTypedFromEvent(doc, 'mouseup'))), repeat()).subscribe(subscriber);
    });
  }
}
TuiPanService.ɵfac = function TuiPanService_Factory(t) {
  return new (t || TuiPanService)(i0.ɵɵinject(ElementRef), i0.ɵɵinject(DOCUMENT));
};
TuiPanService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiPanService,
  factory: TuiPanService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPanService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }];
  }, null);
})();

// TODO: Remove in 4.0 when Safari is bumped to 13
class TuiResizeService extends ResizeObserverService {
  constructor(el, zone, destroy$, support, box, animationFrame$) {
    super(el, zone, support, box);
    return this.pipe(catchError(() =>
    /**
     * @note: if not supported ResizeObserver
     * remove `catchError` after supports modern browsers
     */
    animationFrame$.pipe(throttleTime(POLLING_TIME), map(() => `${el.nativeElement.clientWidth} ${el.nativeElement.clientHeight}`), distinctUntilChanged(), map(() => EMPTY_ARRAY))), debounceTime(0), tuiZonefree(zone), share(), takeUntil(destroy$));
  }
}
TuiResizeService.ɵfac = function TuiResizeService_Factory(t) {
  return new (t || TuiResizeService)(i0.ɵɵinject(ElementRef), i0.ɵɵinject(NgZone), i0.ɵɵinject(TuiDestroyService, 2), i0.ɵɵinject(RESIZE_OBSERVER_SUPPORT), i0.ɵɵinject(RESIZE_OPTION_BOX), i0.ɵɵinject(ANIMATION_FRAME));
};
TuiResizeService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiResizeService,
  factory: TuiResizeService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiResizeService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i0.NgZone,
      decorators: [{
        type: Inject,
        args: [NgZone]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [RESIZE_OBSERVER_SUPPORT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [RESIZE_OPTION_BOX]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [ANIMATION_FRAME]
      }]
    }];
  }, null);
})();
const SCROLL_TIME = 300;
function getX(elementOrWindow) {
  return 'scrollX' in elementOrWindow ? elementOrWindow.scrollX : elementOrWindow.scrollLeft;
}
function getY(elementOrWindow) {
  return 'scrollY' in elementOrWindow ? elementOrWindow.scrollY : elementOrWindow.scrollTop;
}
class TuiScrollService {
  constructor(performanceRef, animationFrame$) {
    this.performanceRef = performanceRef;
    this.animationFrame$ = animationFrame$;
  }
  scroll$(elementOrWindow, scrollTop, scrollLeft = getX(elementOrWindow), duration = SCROLL_TIME) {
    ngDevMode && tuiAssert.assert(duration >= 0, 'Duration cannot be negative');
    ngDevMode && tuiAssert.assert(scrollTop >= 0, 'scrollTop cannot be negative');
    ngDevMode && tuiAssert.assert(scrollLeft >= 0, 'scrollLeft cannot be negative');
    const initialTop = getY(elementOrWindow);
    const initialLeft = getX(elementOrWindow);
    const deltaTop = scrollTop - initialTop;
    const deltaLeft = scrollLeft - initialLeft;
    const observable = !duration ? of([scrollTop, scrollLeft]) : defer(() => of(this.performanceRef.now())).pipe(switchMap(start => this.animationFrame$.pipe(map(now => now - start))), map(elapsed => tuiEaseInOutQuad(tuiClamp(elapsed / duration, 0, 1))), map(percent => [initialTop + deltaTop * percent, initialLeft + deltaLeft * percent]), takeUntil(timer(duration)), endWith([scrollTop, scrollLeft]));
    return observable.pipe(tap(([scrollTop, scrollLeft]) => {
      var _a;
      (_a = elementOrWindow.scrollTo) === null || _a === void 0 ? void 0 : _a.call(elementOrWindow, scrollLeft, scrollTop);
    }));
  }
}
TuiScrollService.ɵfac = function TuiScrollService_Factory(t) {
  return new (t || TuiScrollService)(i0.ɵɵinject(PERFORMANCE), i0.ɵɵinject(ANIMATION_FRAME));
};
TuiScrollService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiScrollService,
  factory: TuiScrollService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiScrollService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: Performance,
      decorators: [{
        type: Inject,
        args: [PERFORMANCE]
      }]
    }, {
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [ANIMATION_FRAME]
      }]
    }];
  }, null);
})();
class TuiStaticRequestService {
  constructor(win, platformId) {
    this.win = win;
    this.platformId = platformId;
    this.cache = new Map();
  }
  request(url) {
    const cache = this.cache.get(url);
    if (cache) {
      return cache;
    }
    const response$ = 'AbortController' in this.win || isPlatformServer(this.platformId) ? fromFetch(url) :
    /**
     * Fallback for Firefox 55 and 56
     * TODO: drop after browser support bump
     */
    defer(() => from(fetch(url)));
    const piped = response$.pipe(switchMap(response => __awaiter(this, void 0, void 0, function* () {
      if (response.ok) {
        return response.text();
      }
      throw new Error(`Failed to load ${url} (${response.statusText})`);
    })), shareReplay({
      bufferSize: 1,
      refCount: false
    }));
    this.cache.set(url, piped);
    return piped;
  }
}
TuiStaticRequestService.ɵfac = function TuiStaticRequestService_Factory(t) {
  return new (t || TuiStaticRequestService)(i0.ɵɵinject(WINDOW), i0.ɵɵinject(PLATFORM_ID));
};
TuiStaticRequestService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiStaticRequestService,
  factory: TuiStaticRequestService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiStaticRequestService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: Window,
      decorators: [{
        type: Inject,
        args: [WINDOW]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [PLATFORM_ID]
      }]
    }];
  }, null);
})();
class TuiSwipeService extends Observable {
  constructor({
    nativeElement
  }, {
    timeout,
    threshold
  }, doc) {
    super(subscriber => {
      merge(tuiTypedFromEvent(nativeElement, 'touchstart', {
        passive: true
      }), tuiTypedFromEvent(doc, 'touchend')).pipe(pairwise(), filter(([first, second]) => !!first.touches.length && first.touches[0].identifier === second.changedTouches[0].identifier), map(([start, end]) => {
        const startX = start.touches[0].clientX;
        const startY = start.touches[0].clientY;
        const endX = end.changedTouches[0].clientX;
        const endY = end.changedTouches[0].clientY;
        const distanceX = startX - endX;
        const distanceY = startY - endY;
        const duration = end.timeStamp - start.timeStamp;
        if ((Math.abs(distanceX) > threshold || Math.abs(distanceY) > threshold) && duration < timeout) {
          return {
            direction: tuiGetSwipeDirection(distanceX, distanceY),
            events: [start, end]
          };
        }
        return null;
      }), filter(tuiIsPresent)).subscribe(subscriber);
    });
  }
}
TuiSwipeService.ɵfac = function TuiSwipeService_Factory(t) {
  return new (t || TuiSwipeService)(i0.ɵɵinject(ElementRef), i0.ɵɵinject(TUI_SWIPE_OPTIONS), i0.ɵɵinject(DOCUMENT));
};
TuiSwipeService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiSwipeService,
  factory: TuiSwipeService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSwipeService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_SWIPE_OPTIONS]
      }]
    }, {
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }];
  }, null);
})();
const TOUCH_SENSITIVITY = 0.01;
class TuiZoomService extends Observable {
  constructor({
    nativeElement
  }, {
    wheelSensitivity
  }) {
    super(subscriber => {
      merge(tuiTypedFromEvent(nativeElement, 'touchstart', {
        passive: true
      }).pipe(filter(({
        touches
      }) => touches.length > 1), switchMap(startEvent => tuiTypedFromEvent(nativeElement, 'touchmove', {
        passive: true
      }).pipe(tuiPreventDefault(), scan((prev, event) => {
        const distance = tuiDistanceBetweenTouches(event);
        return {
          event,
          distance,
          delta: (distance - prev.distance) * TOUCH_SENSITIVITY
        };
      }, {
        event: startEvent,
        distance: tuiDistanceBetweenTouches(startEvent),
        delta: 0
      }), map(({
        event,
        delta
      }) => {
        const clientX = (event.touches[0].clientX + event.touches[1].clientX) / 2;
        const clientY = (event.touches[0].clientY + event.touches[1].clientY) / 2;
        return {
          clientX,
          clientY,
          delta,
          event
        };
      }), takeUntil(tuiTypedFromEvent(nativeElement, 'touchend'))))), tuiTypedFromEvent(nativeElement, 'wheel', {
        passive: false
      }).pipe(tuiPreventDefault(), map(wheel => ({
        clientX: wheel.clientX,
        clientY: wheel.clientY,
        delta: -wheel.deltaY * wheelSensitivity,
        event: wheel
      })))).subscribe(subscriber);
    });
  }
}
TuiZoomService.ɵfac = function TuiZoomService_Factory(t) {
  return new (t || TuiZoomService)(i0.ɵɵinject(ElementRef), i0.ɵɵinject(TUI_ZOOM_OPTIONS));
};
TuiZoomService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiZoomService,
  factory: TuiZoomService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiZoomService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_ZOOM_OPTIONS]
      }]
    }];
  }, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiDestroyService, TuiDirectiveStylesService, TuiFocusVisibleService, TuiIdService, TuiObscuredService, TuiPanService, TuiParentsScrollService, TuiResizeService, TuiScrollService, TuiStaticRequestService, TuiSwipeService, TuiZoomService };
