import { OnInit, Pipe, PipeTransform } from '@angular/core';
import { PartyDto } from '../../modules/counterparties/services/parties-api.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getPartyById } from 'src/app/core/utils/parties.utils';
import { selectCounterparties } from 'src/app/modules/counterparties/store/selectors';
import { Store } from '@ngrx/store';
import { isPresent } from 'src/app/core/utils/isPresent';
import { filter, withLatestFrom } from 'rxjs';
import { selectUser } from 'src/app/core/store/selectors';

@UntilDestroy()
@Pipe({
  name: 'parties',
  pure: false,
})
export class PartiesPipe implements PipeTransform, OnInit {
  private parties!: PartyDto[] | null;

  constructor(private readonly store$: Store) {}

  getParties$ = this.store$
    .select(selectCounterparties)
    .pipe(
      untilDestroyed(this),
      filter(isPresent),
      withLatestFrom(this.store$.select(selectUser).pipe(filter(isPresent))),
    )
    .subscribe(([parties, user]) => {
      this.parties = parties;
    });

  ngOnInit(): void {}

  transform(ids: number[]): string {
    if (!ids || !this.parties) {
      return '';
    }

    return getItemsHtml(ids, this.parties);
  }
}

function getItemsHtml(ids: number[], allParties: PartyDto[]): string {
  let partiesNames = ids.map((partyId: number) => {
    return getPartyById(partyId, allParties)?.name;
  });

  return `<span class="party-in-column">${partiesNames.join(', ')}</span>`;
}
