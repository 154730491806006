import * as i0 from '@angular/core';
import { Pipe, NgModule } from '@angular/core';
class TuiStringifyContentPipe {
  transform(stringify) {
    return ({
      $implicit
    }) => stringify($implicit);
  }
}
TuiStringifyContentPipe.ɵfac = function TuiStringifyContentPipe_Factory(t) {
  return new (t || TuiStringifyContentPipe)();
};
TuiStringifyContentPipe.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
  name: "tuiStringifyContent",
  type: TuiStringifyContentPipe,
  pure: true
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiStringifyContentPipe, [{
    type: Pipe,
    args: [{
      name: 'tuiStringifyContent'
    }]
  }], null, null);
})();
class TuiStringifyContentPipeModule {}
TuiStringifyContentPipeModule.ɵfac = function TuiStringifyContentPipeModule_Factory(t) {
  return new (t || TuiStringifyContentPipeModule)();
};
TuiStringifyContentPipeModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiStringifyContentPipeModule
});
TuiStringifyContentPipeModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiStringifyContentPipeModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiStringifyContentPipe],
      exports: [TuiStringifyContentPipe]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiStringifyContentPipe, TuiStringifyContentPipeModule };
