import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DataTableDirective } from 'angular-datatables';
import { BehaviorSubject, Subject, debounceTime, take, throttleTime } from 'rxjs';
import { constants } from 'src/app/core/constants/constants';
import { dataTableEvents } from 'src/app/core/constants/data-table-events';
import { Criterion, Pagination, SearchItemModel } from 'src/app/core/services/base-event-service';
import {
  ClientSideSearchService,
  FilterRequestItem,
} from 'src/app/core/services/client-side-search.service';
import { processCommonMenuItems } from 'src/app/core/services/table-menu-process.service';

@UntilDestroy()
@Component({
  selector: 'app-client-side-data-table',
  templateUrl: './client-side-data-table.component.html',
})
export class ClientSideDataTableComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {
  @Input()
  id!: string;
  @Input()
  columns!: DataTables.ColumnSettings[];
  @Input() sort: any;
  @Input() eventService: any;
  @Input() data: any;
  @Input() paging: any = true;
  @Input() ordering: any = true;
  @Input() refreshIt: BehaviorSubject<any> | undefined;

  @ViewChild(DataTableDirective, { static: false })
  dtOptions!: DataTables.Settings;

  dtTrigger = new Subject<any>();

  selectedIds: string[] = [];
  filterItems!: SearchItemModel[];

  listenerFn: (() => void) | undefined;
  tableTranslationsData: {} | undefined;

  constructor(
    private renderer: Renderer2,
    private clientSideSearchService: ClientSideSearchService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit(): void {
    this.initTranslations();
    this.initSubscriptions();
    this.dtOptions = {
      ...this.getDTOptions(),
      ajax: (dataTablesParameters: any, callback) => {
        this.ajaxFunction(dataTablesParameters, callback);
      },
    };
  }

  ngOnDestroy() {
    if (this.listenerFn) {
      this.listenerFn();
    }

    const datatable = $(`#${this.id}`).DataTable();
    datatable.destroy();

    this.dtTrigger.unsubscribe();
    this.eventService.dataWasLoaded = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (
    //   changes?.['data'] &&
    //   changes?.['data'].currentValue !== undefined &&
    //   JSON.stringify(changes['data'].currentValue) !== JSON.stringify(changes['data'].previousValue)
    // ) {
    //   const datatable = $(`#${this.id}`).DataTable();
    //   datatable.destroy();
    //   this.rerender();
    // }
  }

  rerender(data?: any): void {
    const datatable = $(`#${this.id}`).DataTable();
    datatable.destroy();

    $(`#${this.id}`).DataTable({
      ...this.getDTOptions(),
      ajax: (dataTablesParameters: any, callback) => {
        this.ajaxFunction(dataTablesParameters, callback, data);
      },
    });
  }

  ajaxFunction(dataTablesParameters: any, callback: any, data?: any): any {
    this.eventService.dataWasLoaded = true;
    callback({
      data: data || this.data,
    });
  }

  getDTOptions(): any {
    return {
      destroy: true,
      paging: this.paging,
      pagingType: 'full_numbers',
      pageLength: this.getPageLength(),
      ordering: this.ordering,
      order: this.sort,
      serverSide: false,
      processing: false,
      stateSave: false,
      searching: false,
      columns: this.columns,
      language: this.tableTranslationsData,
      stateSaveParams: (data: any) => {
        // FunctionStateSaveParams
        const pagination = {
          start: data.oSavedState.start,
          length: data.oSavedState.length,
        };

        this.eventService?.savePagination(pagination);
      },
    };
  }

  getPageLength(): number {
    const preSavedPagination: Pagination = this.eventService?.getPagination();

    if (preSavedPagination?.length) {
      return preSavedPagination.length;
    }

    return constants.defaultDataTablePageLength;
  }

  ngAfterViewInit(): void {
    this.listenerFn = this.renderer.listen('document', 'click', (event) => {
      this.processThreeDotsMenu(event);
      this.processSelectors(event);

      processCommonMenuItems(this.eventService, event);

      event.stopPropagation();
    });
    this.dtTrigger.next(null);
  }

  resetSelected(): void {
    $('input#selectItem').prop('checked', false);
    $('input#selectAll').prop('checked', false);
    this.selectedIds = [];
  }

  processThreeDotsMenu(event: any) {
    const menus = document.getElementsByClassName('dropdown-content');

    for (let i = 0; i < menus.length; i++) {
      menus[i].classList.remove('show');
    }

    if (event.target.id === 'threeDots') {
      if (event.target.nextSibling.nextSibling.classList.contains('show')) {
        event.target.nextSibling.nextSibling.classList.remove('show');
      } else {
        event.target.nextSibling.nextSibling.classList.toggle('show');
      }
    }
  }

  processSelectors(event: any) {
    if (event.target.id === 'selectAll') {
      $('input#selectItem').prop('checked', event.target.checked);
      this.selectedIds = [];
      if (event.target.checked) {
        this.selectedIds = $('input#selectItem')
          .map(function () {
            return this.getAttribute(dataTableEvents.common.checkedId);
          })
          .get();
      }
    }
    if (event.target.id === 'selectItem') {
      const itemId = event.target.getAttribute(dataTableEvents.common.checkedId);

      if (event.target.checked) {
        this.selectedIds.push(itemId);
      } else {
        this.selectedIds = this.selectedIds.filter((id: string) => {
          return id !== itemId;
        });
      }
    }

    this.eventService.onSelected(this.selectedIds);
  }

  clearSelectedItems(): void {
    this.selectedIds = [];
    $('input#selectAll').prop('checked', false);
  }

  private initTranslations(): void {
    this.translocoService.langChanges$.pipe(untilDestroyed(this)).subscribe(() => {
      this.translocoService
        .selectTranslation()
        .pipe(take(1), untilDestroyed(this))
        .subscribe((data) => {
          this.tableTranslationsData = {
            info: data['dataTableLanguageSettings.info'],
            infoEmpty: data['dataTableLanguageSettings.infoEmpty'],
            lengthMenu: data['dataTableLanguageSettings.lengthMenu'],
            zeroRecords: data['dataTableLanguageSettings.zeroRecords'],
            paginate: {
              first: data['dataTableLanguageSettings.paginate.first'],
              last: data['dataTableLanguageSettings.paginate.last'],
              next: data['dataTableLanguageSettings.paginate.next'],
              previous: data['dataTableLanguageSettings.paginate.previous'],
            },
          };
        });
      this.rerender();
    });
  }

  private initSubscriptions(): void {
    this.refreshIt?.pipe(untilDestroyed(this)).subscribe((refresh: any) => {
      if (refresh) {
        this.columns = refresh;
        const datatable = $(`#${this.id}`).DataTable();
        datatable.destroy();
        this.rerender();
      }
    });

    this.eventService
      .getFilterConditionsSubject()
      .pipe(untilDestroyed(this))
      .subscribe((filterItems: SearchItemModel[]) => {
        this.filterItems = filterItems.map((item: SearchItemModel) => {
          item.searchValue = item.searchValue?.toString();
          return item;
        });
        this.processFilters();
      });
  }

  private processFilters(): void {
    if (this.filterItems?.length > 0) {
      let dataFiltered = Object.assign(this.data);

      this.filterItems.forEach((filterItem) => {
        const criterion = this.eventService
          ?.getFilterCriterions()
          .find((filterCriterion: Criterion) => filterItem.criterion === filterCriterion.id);

        dataFiltered = this.clientSideSearchService.doFilterItems(
          {
            propertyNames: criterion.data,
            propertyValue: filterItem.searchValue,
            operator: filterItem.operator,
          } as FilterRequestItem,
          dataFiltered,
          criterion.type,
        );
      });

      this.rerender(dataFiltered);
    } else {
      setTimeout(() => {
        this.rerender(this.data);
      });
    }
  }
}

export class DataTableItem {
  title!: string;
  event!: string;
  icon!: string;
}
