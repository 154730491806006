import { BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

export const environment = {
  appBaseUrl: 'https://app.dev.autolex.ai',
  baseUrl: 'https://api.dev.autolex.ai',
  authServerUrl: 'https://auth.dev.autolex.ai/',
  production: false,
  msalConfig: {
    auth: {
      clientId: 'd70d6afe-8e2a-40cb-a903-27257c493a5a', // This is the ONLY mandatory field that you need to supply.

      /**
       *In Azure AD, authority is a URL indicating the Azure active directory that MSAL uses to obtain tokens.
       * It is of the form https://login.microsoftonline.com/{Enter_the_Tenant_Info_Here}
       * If your application supports Accounts in one organizational directory, replace "Enter_the_Tenant_Info_Here" value with the Tenant Id or Tenant name (for example, contoso.microsoft.com).
       * If your application supports Accounts in any organizational directory, replace "Enter_the_Tenant_Info_Here" value with organizations.
       * If your application supports Accounts in any organizational directory and personal Microsoft accounts, replace "Enter_the_Tenant_Info_Here" value with common.
       * To restrict support to Personal Microsoft accounts only, replace "Enter_the_Tenant_Info_Here" value with consumers.
       */
      authority: 'https://login.microsoftonline.com/common',
      redirectUri: '/auth', // Points to window.location.origin by default. You must register this URI on Azure portal/App Registration.
      postLogoutRedirectUri: '/', // Points to window.location.origin by default.
      clientCapabilities: ['CP1'], // This lets the resource server know that this client can handle claim challenges.
    },
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge. Remove this line to use Angular Universal
  },
  system: {
    /**
     * Below you can configure MSAL.js logs. For more information, visit:
     * https://docs.microsoft.com/azure/active-directory/develop/msal-logging-js
     */
    loggerOptions: {
      loggerCallback(logLevel: LogLevel, message: string) {
        console.log(message);
      },
      logLevel: LogLevel.Verbose,
      piiLoggingEnabled: false,
    },
  },
  /**
   * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
   */

  protectedResources: {
    apiTodoList: {
      endpoint: 'https://api.dev.autolex.ai/utility',
      scopes: {
        read: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
        write: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
      },
    },
    apiUser: {
      endpoint: 'https://api.dev.autolex.ai/user',
      scopes: {
        read: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
        write: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
      },
    },
    apiDocuments: {
      endpoint: 'https://api.dev.autolex.ai/documents',
      scopes: {
        read: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
        write: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
      },
    },
    apiBaselineDocuments: {
      endpoint: 'https://api.dev.autolex.ai/baselineDocuments',
      scopes: {
        read: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
        write: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
      },
    },
    apiDocumentTypes: {
      endpoint: 'https://api.dev.autolex.ai/documentTypes',
      scopes: {
        read: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
        write: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
      },
    },
    apiPositions: {
      endpoint: 'https://api.dev.autolex.ai/positions',
      scopes: {
        read: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
        write: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
      },
    },
    apiParties: {
      endpoint: 'https://api.dev.autolex.ai/parties',
      scopes: {
        read: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
        write: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
      },
    },
    apiClauses: {
      endpoint: 'https://api.dev.autolex.ai/clauses',
      scopes: {
        read: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
        write: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
      },
    },
    apiDocumentStatuses: {
      endpoint: 'https://api.dev.autolex.ai/documentStatuses',
      scopes: {
        read: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
        write: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
      },
    },
    apiLanguages: {
      endpoint: 'https://api.dev.autolex.ai/languages',
      scopes: {
        read: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
        write: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
      },
    },
    apiKeyQuestions: {
      endpoint: 'https://api.dev.autolex.ai/keyQuestions',
      scopes: {
        read: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
        write: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
      },
    },
    apiReporting: {
      endpoint: 'https://api.dev.autolex.ai/reporting',
      scopes: {
        read: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
        write: ['api://d70d6afe-8e2a-40cb-a903-27257c493a5a/User.Read'],
      },
    },
  },

  /**
   * Scopes you add here will be prompted for user consent during sign-in.
   * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
   * For more information about OIDC scopes, visit:
   * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
   */ loginRequest: {
    scopes: ['openid', 'profile', 'email', 'offline_access'],
  },
};
