import {
  DocumentTypeSearchModel,
  GetDocumentTypeDto,
} from 'src/app/modules/doc-types/models/doc-types.model';
import { FILTER_OPERATOR } from '../services/client-side-search.service';
import { SearchItemModel } from '../services/base-event-service';
import {
  DOC_TYPES_SEARCH_CRITERION,
  DocTypesSearchCriterionsFactory,
} from '../factories/doc-types-search-criterions.factory';

export function getDocTypeById(
  docTypeid: number,
  allDocTypes: GetDocumentTypeDto[],
): GetDocumentTypeDto | undefined {
  return allDocTypes.find((docType) => docType.id === docTypeid);
}

export function getDocTypeFilterConditionsFromModel(
  model: DocumentTypeSearchModel,
  docTypesSearchCriterionsFactory: DocTypesSearchCriterionsFactory,
): SearchItemModel[] {
  let conditions: SearchItemModel[] = [];

  if (!!model.docTypeName) {
    const criterion = docTypesSearchCriterionsFactory.getById(DOC_TYPES_SEARCH_CRITERION.NAME);
    const item = {
      criterion: criterion.id,
      operator: FILTER_OPERATOR.Contains,
      type: criterion.type,
      searchValue: model.docTypeName,
      controlName: criterion.controlName,
    } as SearchItemModel;

    conditions.push(item);
  }

  return conditions;
}
