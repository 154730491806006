import * as i0 from '@angular/core';
import { ElementRef, Directive, Inject, Output, NgModule } from '@angular/core';
import { tuiPressedObservable } from '@taiga-ui/cdk/observables';
import { TUI_TAKE_ONLY_TRUSTED_EVENTS } from '@taiga-ui/cdk/tokens';
class TuiPressedDirective {
  constructor(el, takeOnlyTrustedEvents) {
    this.el = el;
    this.takeOnlyTrustedEvents = takeOnlyTrustedEvents;
    this.tuiPressedChange = tuiPressedObservable(this.el.nativeElement, {
      onlyTrusted: this.takeOnlyTrustedEvents
    });
  }
}
TuiPressedDirective.ɵfac = function TuiPressedDirective_Factory(t) {
  return new (t || TuiPressedDirective)(i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(TUI_TAKE_ONLY_TRUSTED_EVENTS));
};
TuiPressedDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiPressedDirective,
  selectors: [["", "tuiPressedChange", ""]],
  outputs: {
    tuiPressedChange: "tuiPressedChange"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPressedDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiPressedChange]'
    }]
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_TAKE_ONLY_TRUSTED_EVENTS]
      }]
    }];
  }, {
    tuiPressedChange: [{
      type: Output
    }]
  });
})();
class TuiPressedModule {}
TuiPressedModule.ɵfac = function TuiPressedModule_Factory(t) {
  return new (t || TuiPressedModule)();
};
TuiPressedModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiPressedModule
});
TuiPressedModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPressedModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiPressedDirective],
      exports: [TuiPressedDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiPressedDirective, TuiPressedModule };
