import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class ValidationErrorsService {
  constructor(private translocoService: TranslocoService) {}

  public getControlErrorMessage(errors: ValidationErrors): string {
    if (errors['required']) {
      return this.translocoService.translate('errors.requiredField');
    }
    if (errors['emailFormat']) {
      return this.translocoService.translate('errors.wrongDataFormat');
    }
    if (errors['partyNameAlreadyExist']) {
      return this.translocoService.translate('errors.createCounterparty');
    }
    if (errors['docTypeNameAlreadyExist']) {
      return this.translocoService.translate('errors.createDocType');
    }
    if (errors['custom']) {
      return '';
    }
    return this.translocoService.translate('errors.validationError');
  }
}
