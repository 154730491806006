export enum DOC_STATUS {
  INPROGRESS = 1,
  VALIDATED = 2,
  SIGNED = 3,
  DROPPED = 4,
}

export enum BASELINE_DOC_STATUS {
  WAITING_FOR_PROCESSING = 1,
  PROCESSED_SUCCESSFULLY = 2,
  ERROR_WHILE_PROCESSING = 3,
}
