<div class="app-dropdown">
  @if (label) {
    <div class="app-dropdown__label">
      {{ label }}:
      <ng-container *ngIf="isRequired">*</ng-container>
    </div>
  }

  <mat-form-field class="app-dropdown__field" appearance="outline">
    <mat-select
      [formControl]="control"
      value=""
      [placeholder]="placeholder"
      (selectionChange)="selectedValue($event)"
      multiple
    >
      <mat-select-trigger class="trigger">
        {{ selectedData?.text }}
      </mat-select-trigger>
      <mat-option *ngFor="let option of values" [value]="option[optionValue]"
        ><mat-icon *ngIf="optionIcon" [style.color]="option[optionIconColor]" class="smallIcon">{{
          option[optionIcon]
        }}</mat-icon
        >{{ option[optionLabel] }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-error class="app-dropdown__error" *ngIf="control?.invalid && control?.touched">
    {{ getErrorMessage() }}
  </mat-error>
</div>
