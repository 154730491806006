import * as i0 from '@angular/core';
import { Optional, Directive, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR, DefaultValueAccessor } from '@angular/forms';
import { TUI_VALUE_ACCESSOR } from '@taiga-ui/core/tokens';
class TuiMaskAccessorDirective {}
TuiMaskAccessorDirective.ɵfac = function TuiMaskAccessorDirective_Factory(t) {
  return new (t || TuiMaskAccessorDirective)();
};
TuiMaskAccessorDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiMaskAccessorDirective,
  selectors: [["input", "tuiMaskAccessor", ""]],
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    deps: [[new Optional(), TUI_VALUE_ACCESSOR], DefaultValueAccessor],
    multi: true,
    useFactory: (accessor, fallback) => accessor ? accessor[0] : fallback
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiMaskAccessorDirective, [{
    type: Directive,
    args: [{
      selector: 'input[tuiMaskAccessor]',
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        deps: [[new Optional(), TUI_VALUE_ACCESSOR], DefaultValueAccessor],
        multi: true,
        useFactory: (accessor, fallback) => accessor ? accessor[0] : fallback
      }]
    }]
  }], null, null);
})();

/**
 * @internal
 */
class TuiMaskAccessorModule {}
TuiMaskAccessorModule.ɵfac = function TuiMaskAccessorModule_Factory(t) {
  return new (t || TuiMaskAccessorModule)();
};
TuiMaskAccessorModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiMaskAccessorModule
});
TuiMaskAccessorModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiMaskAccessorModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiMaskAccessorDirective],
      exports: [TuiMaskAccessorDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiMaskAccessorDirective, TuiMaskAccessorModule };
