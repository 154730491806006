import { Injectable } from '@angular/core';
import { POSITION } from '../models/position.enum';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class PositionFactory {
  constructor(private translocoService: TranslocoService) {}
  protected readonly entities = {
    [POSITION.RECEIVING]: {
      id: POSITION.RECEIVING,
      name: this.translocoService.translate(`positions.${POSITION.RECEIVING}`),
    },
    [POSITION.SENDING]: {
      id: POSITION.SENDING,
      name: this.translocoService.translate(`positions.${POSITION.SENDING}`),
    },
  };

  getAllItemsAsArray() {
    return Object.values(this.entities);
  }

  getById(id: number | POSITION) {
    const item = { ...this.entities[id as POSITION] };
    if (!item) {
      throw new Error(`Unknown id ${id} in ${this.constructor.name}`);
    }
    return item;
  }
}
