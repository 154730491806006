import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncText' })
export class TruncTextPipe implements PipeTransform {
  constructor() {}

  transform(textOriginal: string, limit: number = 100) {
    const preparedText =
      textOriginal.length > limit ? textOriginal.slice(0, limit) + '...' : textOriginal;
    return `<span title="${textOriginal}">${preparedText}<span>`;
  }
}
