<div class="app-button" [ngClass]="{}">
  <button
    class="app-button__button"
    mat-button
    [disabled]="disabled"
    [ngClass]="{
      'mat-mdc-button': type === 'basic',
      'mdc-button--outlined mat-mdc-outlined-button': type === 'stroked',
      'icon-stroked': type === 'iconStroked',
      'mdc-button--unelevated mat-mdc-unelevated-button': type === 'flat',
      'custom-color-ERROR': color === 'ERROR',
      'full-width': size === 'fullWidth',
      'width-60-prc': size === 'width60prc',
      'full-height': size === 'fullHeight',
      'in-filter-row': size === 'inFilterRow',
      extraSmall: size === 'extraSmall'
    }"
    (click)="click()"
    [type]="htmlType"
    [style.color]="labelColor"
  >
    <mat-icon *ngIf="icon" [style.color]="iconColor">{{ icon }}</mat-icon>
    {{ label }}
  </button>
</div>
