<div
  class="app-status"
  [ngClass]="{
    _green: isGreen,
    _blue: isBlue,
    _red: isRed,
    _yellow: isYellow
  }"
>
  {{ statusText }}
</div>
