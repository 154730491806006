import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, distinctUntilChanged, filter, fromEvent, map, startWith } from 'rxjs';
import { isPresent } from 'src/app/core/utils/isPresent';
import { ValidationErrorsService } from 'src/app/shared/services/validation-errors.service';

@UntilDestroy()
@Component({
  selector: 'app-single-select-autocomplete',
  templateUrl: './single-select-autocomplete.component.html',
  styleUrl: './single-select-autocomplete.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleSelectAutocompleteComponent
  implements OnInit, ControlValueAccessor, AfterViewInit
{
  @Input() label: string | undefined;
  @Input() autocompleteOptions: any[] = [];
  @Input() optionIcon: string = '';
  @Input() optionIconColor: string = '';
  @Input() optionLabel: string = 'name';
  @Input() optionValue: string = 'id';
  @Input() placeholderKey: string = 'placeholders.select';
  @ViewChild('optionInput')
  optionInput!: ElementRef<HTMLInputElement>;

  @Output() onSelect: EventEmitter<string[]> = new EventEmitter<string[]>();
  selectedOptionValue: any;
  filteredOptions$: Observable<any[]> | undefined;
  focusOut$: Observable<any> | undefined;

  get control(): FormControl {
    return this.ngControl?.control as FormControl;
  }
  get isRequired(): boolean {
    const validator = this.control.validator ? this.control.validator({} as AbstractControl) : null;
    return validator && validator['required'];
  }

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private validationErrorsService: ValidationErrorsService,
    private cdr: ChangeDetectorRef,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    this.selectedOptionValue = this.control.value;
    this.resetFilteredOptions();
  }

  ngAfterViewInit(): void {
    this.initSubscriptions();
  }

  initSubscriptions() {
    this.processValueChanges();
    this.processFocusOut();
  }

  resetFilteredOptions(): void {
    this.filteredOptions$ = this.control.valueChanges.pipe(
      startWith<string>(''),
      map((filter) => this.filter(filter)),
    );
  }

  processValueChanges(): void {
    this.control?.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged(), filter(isPresent))
      .subscribe((value) => {
        if (
          this.autocompleteOptions.findIndex(
            (item) => item[this.optionLabel] === value || item[this.optionValue] === value,
          ) === -1 &&
          this.selectedOptionValue
        ) {
          this.filteredOptions$ = this.control.valueChanges.pipe(
            startWith<string>(''),
            map((filter) => this.filter(filter)),
          );
          this.selectedOptionValue = undefined;
          this.onSelect.emit(undefined);
          this.control.patchValue(undefined);
        }
      });
  }

  processFocusOut(): void {
    this.focusOut$ = fromEvent(this.optionInput.nativeElement, 'blur');
    this.focusOut$.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe((event: any) => {
      if (
        this.autocompleteOptions.findIndex((item) => {
          return item[this.optionLabel] === this.optionInput.nativeElement.value;
        }) === -1
      ) {
        this.control.patchValue(undefined);
      } else {
        this.resetFilteredOptions();
      }
    });
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedOptionValue = event.option.value;
    this.control.patchValue(event.option.value);
    this.onSelect.emit(this.selectedOptionValue);
  }

  private filter(filter: string): any[] {
    if (typeof filter === 'number') {
      filter = filter + '';
    }

    if (filter) {
      return this.autocompleteOptions
        .filter((option) => {
          const filterOk =
            option[this.optionLabel].toLowerCase().indexOf(filter.toLowerCase()) >= 0;
          return filterOk;
        })
        .sort((a, b) => a[this.optionLabel].localeCompare(b[this.optionLabel]));
    } else {
      return [...this.autocompleteOptions].sort((a, b) =>
        a[this.optionLabel].localeCompare(b[this.optionLabel]),
      );
    }
  }

  displayFn(value: any): string {
    return (
      this.autocompleteOptions.find((item) => {
        return item[this.optionValue] === value;
      })?.[this.optionLabel] || ''
    );
  }

  public getErrorMessage(): string {
    if (this.control?.invalid && this.control?.errors) {
      return this.validationErrorsService.getControlErrorMessage(this.control.errors);
    }
    return '';
  }

  public writeValue(value: any): void {}

  onChange: any = (value: string) => {};
  onTouched: any = () => {};

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
