import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ALL_DROPDOWN_ITEM } from 'src/app/core/constants/constants';
import { SelectedData } from 'src/app/core/models/selected-data';
import { ValidationErrorsService } from 'src/app/shared/services/validation-errors.service';

@Component({
  selector: 'app-multi-drop-down',
  templateUrl: './multi-drop-down.component.html',
  styleUrl: './multi-drop-down.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiDropDownComponent implements ControlValueAccessor {
  @Input() label: string | undefined;
  @Input() values: any[] = [];
  @Input() optionIcon: string = '';
  @Input() optionIconColor: string = '';
  @Input() optionLabel: string = 'name';
  @Input() optionValue: string = 'id';
  @Input() placeholder: string = '';

  @Output() onSelect: EventEmitter<string[]> = new EventEmitter<string[]>();
  selectedData: SelectedData | undefined;
  prevSelectedData: SelectedData | undefined;

  get control(): FormControl {
    return this.ngControl?.control as FormControl;
  }
  
  get isRequired(): boolean {
    const validator = this.control.validator ? this.control.validator({} as AbstractControl) : null;
    return validator && validator['required'];
  }

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private validationErrorsService: ValidationErrorsService,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  selectedValue(event: MatSelectChange) {
    this.selectedData = {
      value: event.value,
      text: this.getSelectedItemsText(event.value),
    };
    this.processAllItemSelection();
    this.emitValue();
  }

  public writeValue(value: any): void {
    this.selectedData = {
      value: value,
      text: this.getSelectedItemsText(value),
    };
    this.processAllItemSelection();
  }

  private emitValue(): void {
    const allDDItemValue = ALL_DROPDOWN_ITEM[this.optionValue as keyof typeof ALL_DROPDOWN_ITEM];

    if (this.selectedData?.value.length === 1 && this.selectedData?.value[0] === allDDItemValue) {
      const valuesExceptAllDDItem = this.values
        .map((item) => {
          return item[this.optionValue];
        })
        .filter((item) => {
          return item !== allDDItemValue;
        });
      this.onSelect.emit(valuesExceptAllDDItem);
    } else {
      this.onSelect.emit(this.selectedData?.value);
    }
  }

  getSelectedItemsText(controlValue: any): string {
    let res: string[] = [];
    if (!!controlValue) {
      controlValue.forEach((value: string) => {
        let text = this.values.find((item: any) => {
          return item[this.optionValue] === value;
        })?.[this.optionLabel];

        if (text) {
          res.push(text);
        }
      });
    }

    return res.join(', ');
  }

  processAllItemSelection(): void {
    if (this.selectedData?.value.length > 1) {
      const allItemIndex = this.selectedData?.value.indexOf(
        ALL_DROPDOWN_ITEM[this.optionValue as keyof typeof ALL_DROPDOWN_ITEM],
      );

      if (this.isAllWasPrevSelected()) {
        if (allItemIndex !== -1) {
          this.selectSmtExceptAllItem(allItemIndex);
        }
      } else {
        if (allItemIndex !== -1 || this.selectedData?.value.length === this.values.length - 1) {
          this.selectOnlyAllItem();
        }
      }
    }

    this.prevSelectedData = Object.assign({}, this.selectedData);
  }

  public selectSmtExceptAllItem(allItemIndex: number): void {
    this.selectedData?.value.splice(allItemIndex, 1);
    this.selectedData = {
      value: this.selectedData?.value,
      text: this.getSelectedItemsText(this.selectedData?.value),
    };
    this.control?.patchValue(this.selectedData.value, { emitEvent: false });
  }

  public selectOnlyAllItem(): void {
    this.selectedData = {
      value: [ALL_DROPDOWN_ITEM[this.optionValue as keyof typeof ALL_DROPDOWN_ITEM]],
      text: ALL_DROPDOWN_ITEM[this.optionLabel as keyof typeof ALL_DROPDOWN_ITEM],
    };
    this.control?.patchValue(this.selectedData.value);
  }

  public isAllWasPrevSelected(): boolean {
    if (!this.prevSelectedData) {
      return false;
    }

    const allIndex = this.prevSelectedData?.value.indexOf(
      ALL_DROPDOWN_ITEM[this.optionValue as keyof typeof ALL_DROPDOWN_ITEM],
    );

    return allIndex !== -1;
  }

  public getErrorMessage(): string {
    if (this.control?.invalid && this.control?.errors) {
      return this.validationErrorsService.getControlErrorMessage(this.control.errors);
    }
    return '';
  }

  onChange: any = (value: string) => {};
  onTouched: any = () => {};

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
}
