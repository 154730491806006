<span class="app-tooltip">
  <mat-icon
    class="icon material-symbols-rounded"
    [pTooltip]="htmlText"
    tooltipPosition="{{ tooltipPosition }}"
    hideDelay="{{ hideDelay }}"
    tooltipStyleClass="{{ class }}"
    [escape]="false"
    >{{ 'help' }}</mat-icon
  >
</span>
