import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BaselineDocumentStatusFactory } from 'src/app/core/factories/baseline-doc-status.factory';
import { BASELINE_DOC_STATUS } from 'src/app/core/models/status.enum';

@Pipe({ name: 'baselineDocStatus' })
export class BaselineDocStatusPipe implements PipeTransform {
  constructor(
    private translocoService: TranslocoService,
    private baselineDocStatusFactory: BaselineDocumentStatusFactory,
  ) {}

  private getColor(status: BASELINE_DOC_STATUS) {
    return this.baselineDocStatusFactory.getById(status)?.color;
  }

  transform(status: BASELINE_DOC_STATUS) {
    const color = this.getColor(status);

    return `<span> <mat-icon role="img" class="mat-icon smallIcon in-column material-icons mat-ligature-font" aria-hidden="true" data-mat-icon-type="font" style="color: ${color}">circle</mat-icon> ${this.translocoService.translate(
      'baselineStatus.' + status,
    )}</span>`;
  }
}
