import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { BASELINE_DOC_STATUS } from '../models/status.enum';

@Injectable({
  providedIn: 'root',
})
export class BaselineDocumentStatusFactory {
  constructor(private translocoService: TranslocoService) {}
  protected readonly entities = {
    [BASELINE_DOC_STATUS.PROCESSED_SUCCESSFULLY]: {
      id: BASELINE_DOC_STATUS.PROCESSED_SUCCESSFULLY,
      name: this.translocoService.translate(
        `baselineStatus.${BASELINE_DOC_STATUS.PROCESSED_SUCCESSFULLY}`,
      ),
      color: '#10ae0d',
      cssClass: '_green',
    },
    [BASELINE_DOC_STATUS.WAITING_FOR_PROCESSING]: {
      id: BASELINE_DOC_STATUS.WAITING_FOR_PROCESSING,
      name: this.translocoService.translate(
        `baselineStatus.${BASELINE_DOC_STATUS.WAITING_FOR_PROCESSING}`,
      ),
      color: '#ffac0c',
      cssClass: '_yellow',
    },
    [BASELINE_DOC_STATUS.ERROR_WHILE_PROCESSING]: {
      id: BASELINE_DOC_STATUS.ERROR_WHILE_PROCESSING,
      name: this.translocoService.translate(
        `baselineStatus.${BASELINE_DOC_STATUS.ERROR_WHILE_PROCESSING}`,
      ),
      color: '#da365d',
      cssClass: '_red',
    },
  };

  getAllItemsAsArray() {
    return Object.values(this.entities);
  }

  getById(id: number | BASELINE_DOC_STATUS) {
    const item = { ...this.entities[id as BASELINE_DOC_STATUS] };
    if (!item) {
      throw new Error(`Unknown id ${id} in ${this.constructor.name}`);
    }
    return item;
  }
}
