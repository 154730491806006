<div class="dataTable">
  <table
    id="{{ id }}"
    [ngClass]="{ hidden: !this.eventService.dataWasLoaded }"
    datatable
    [dtOptions]="dtOptions"
    [dtTrigger]="dtTrigger"
    class="row-border hover"
    style="width: 100%"
  ></table>
</div>
