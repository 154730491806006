import { NgModule } from '@angular/core';
import { ThreeDotsMenuPipe } from './three-dots-menu.pipe';
import { DateShortPipe } from './date-short.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { DocStatusPipe } from './doc-status.pipe';
import { PartiesPipe } from './parties.pipe';
import { DocTypePipe } from './doc-type.pipe';
import { MyPositionPipe } from './my-position.pipe';
import { TruncTextPipe } from './trunc-text.pipe';
import { DocTypesPipe } from './doc-types.pipe';
import { MyPositionsPipe } from './my-position.spipe';
import { BaselineDocStatusPipe } from './baseline-doc-status.pipe';

@NgModule({
  declarations: [
    ThreeDotsMenuPipe,
    DateShortPipe,
    SafeHtmlPipe,
    DocStatusPipe,
    PartiesPipe,
    DocTypePipe,
    MyPositionPipe,
    TruncTextPipe,
    DocTypesPipe,
    MyPositionsPipe,
    BaselineDocStatusPipe,
  ],
  imports: [],
  exports: [
    ThreeDotsMenuPipe,
    DateShortPipe,
    SafeHtmlPipe,
    DocStatusPipe,
    PartiesPipe,
    DocTypePipe,
    MyPositionPipe,
    TruncTextPipe,
    DocTypesPipe,
    MyPositionsPipe,
    BaselineDocStatusPipe,
  ],
  providers: [
    ThreeDotsMenuPipe,
    DateShortPipe,
    DocStatusPipe,
    PartiesPipe,
    DocTypePipe,
    MyPositionPipe,
    TruncTextPipe,
    DocTypesPipe,
    MyPositionsPipe,
    BaselineDocStatusPipe,
  ],
})
export class PipesModule {}
