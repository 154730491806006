import * as i0 from '@angular/core';
import { Directive, Input, HostBinding, NgModule } from '@angular/core';

/**
 * Abstraction over `tabindex`
 */
class TuiFocusableDirective {
  constructor() {
    /**
     * Element can be focused with keyboard
     */
    this.focusable = true;
  }
  get tabIndex() {
    return this.focusable ? 0 : -1;
  }
}
TuiFocusableDirective.ɵfac = function TuiFocusableDirective_Factory(t) {
  return new (t || TuiFocusableDirective)();
};
TuiFocusableDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiFocusableDirective,
  selectors: [["", "tuiFocusable", ""]],
  hostVars: 1,
  hostBindings: function TuiFocusableDirective_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵhostProperty("tabIndex", ctx.tabIndex);
    }
  },
  inputs: {
    focusable: [i0.ɵɵInputFlags.None, "tuiFocusable", "focusable"]
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFocusableDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiFocusable]'
    }]
  }], null, {
    focusable: [{
      type: Input,
      args: ['tuiFocusable']
    }],
    tabIndex: [{
      type: HostBinding,
      args: ['tabIndex']
    }]
  });
})();
class TuiFocusableModule {}
TuiFocusableModule.ɵfac = function TuiFocusableModule_Factory(t) {
  return new (t || TuiFocusableModule)();
};
TuiFocusableModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiFocusableModule
});
TuiFocusableModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiFocusableModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiFocusableDirective],
      exports: [TuiFocusableDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiFocusableDirective, TuiFocusableModule };
