import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Optional,
  Self,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TuiContextWithImplicit, TuiStringHandler } from '@taiga-ui/cdk';
import { filter } from 'rxjs';
import { isPresent } from 'src/app/core/utils/isPresent';
import { ValidationErrorsService } from 'src/app/shared/services/validation-errors.service';

@UntilDestroy()
@Component({
  selector: 'app-tui-multi-select-autocomplete',
  templateUrl: './app-tui-multi-select-autocomplete.component.html',
  styleUrl: './app-tui-multi-select-autocomplete.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppTuiMultiSelectAutocompleteComponent implements OnInit, ControlValueAccessor {
  @Input() showErrors?: boolean = false;
  @Input() autocompleteOptions: any[] = [];
  @Input() optionLabel: string = 'name';
  @Input() hiddenOptions: number[] = [];
  @Input() optionValue: string = 'id';
  @Input() placeholderKey: string = 'placeholders.select';

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private validationErrorsService: ValidationErrorsService,
    private translocoService: TranslocoService,
    private cdr: ChangeDetectorRef,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  private selectedText = this.translocoService.translate('placeholders.selected');

  readonly valueContent: TuiStringHandler<TuiContextWithImplicit<readonly string[]>> = ({
    $implicit,
  }) => {
    if (!$implicit.length) {
      return 'All';
    }

    return `${this.selectedText}: ${$implicit.length}`;
  };

  get control(): FormControl {
    return this.ngControl?.control as FormControl;
  }

  get isRequired(): boolean {
    const validator = this.control.validator ? this.control.validator({} as AbstractControl) : null;
    return validator && validator['required'];
  }

  initSubscriptions(): void {
    this.translocoService.langChanges$
      .pipe(filter(isPresent), untilDestroyed(this))
      .subscribe(() => {
        this.selectedText = this.translocoService.translate('placeholders.selected');
        this.control.updateValueAndValidity();
        this.cdr.markForCheck();
      });
  }

  public getErrorMessage(): string {
    if (this.control?.invalid && this.control?.errors) {
      return this.validationErrorsService.getControlErrorMessage(this.control.errors);
    }
    return '';
  }

  onChange: any = (value: string) => {};
  onTouched: any = () => {};

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: any): void {}
}
