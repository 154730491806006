import { Injectable } from '@angular/core';
import { SearchCriterionsDataType } from '../services/client-side-search.service';
import { Criterion } from '../services/base-event-service';

@Injectable({
  providedIn: 'root',
})
export class CounterpartySearchCriterionsFactory {
  constructor() {}
  protected readonly entities = {
    [COUNTERPARTY_SEARCH_CRITERION.NAME]: {
      id: COUNTERPARTY_SEARCH_CRITERION.NAME,
      title: 'Counterparty name',
      data: ['name'],
      type: SearchCriterionsDataType.String,
      controlName: 'counterpartyName',
    },
  };

  getAllItemsAsArray() {
    return Object.values(this.entities) as Criterion[];
  }

  getById(id: number | COUNTERPARTY_SEARCH_CRITERION) {
    const item = { ...this.entities[id as COUNTERPARTY_SEARCH_CRITERION] };
    if (!item) {
      throw new Error(`Unknown id ${id} in ${this.constructor.name}`);
    }
    return item;
  }
}

export enum COUNTERPARTY_SEARCH_CRITERION {
  NAME = 1,
}
