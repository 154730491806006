import { Pipe, PipeTransform } from '@angular/core';
import { DataTableItem } from '../components/data-table/client-side-data-table/client-side-data-table.component';

@Pipe({ name: 'threeDotsMenu' })
export class ThreeDotsMenuPipe implements PipeTransform {
  constructor() {}

  getItemsHtml(id: string, dataTableMenuItems: DataTableItem[]): string {
    let res = '';

    dataTableMenuItems.forEach((item: DataTableItem, index: number) => {
      res += `<span class="menu-item" ${item.event}='${id}'>`;
      res += `<div ${item.event}='${id}'></div>`;
      res += `<span class="table-menu-icon material-symbols-rounded"> ${item.icon} </span> ${item.title}`;
      res += '</span>';

      if (index !== dataTableMenuItems.length - 1) {
        res += '<span class="menu-item-separator"></span>';
      }
    });

    return res;
  }

  transform(id: string, dataTableMenuItems: DataTableItem[]) {
    return (
      `<div class='three-dots-menu' id='threeDotsMenu'>
    <div class='three-dots div-to-center' id='threeDots'>&#8943</div>
    <div id="myDropdown" class="dropdown-content">` +
      this.getItemsHtml(id, dataTableMenuItems) +
      '</div></div>'
    );
  }
}
