import { ChangeDetectionStrategy, Component, Input, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { ValidationErrorsService } from 'src/app/shared/services/validation-errors.service';

@Component({
  selector: 'app-input',
  templateUrl: './app-input.component.html',
  styleUrl: './app-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppInputComponent implements ControlValueAccessor {
  @Input() type: string = 'text';
  @Input() controlType: string = 'text';
  @Input() label: string | undefined;
  @Input() showErrors?: boolean = true;
  @Input() maxLength?: number = 256;
  @Input() readonly = false;
  @Input() mask = '';
  @Input() prefix = '';
  @Input() placeholder: string = '';
  @Input() highlightAsError?: boolean = false;
  @Input() name: string = '';
  @Input() id: string = '';
  @Input() autocomplete?: string = '';

  public hide: boolean = false;

  get control(): FormControl {
    return this.ngControl?.control as FormControl;
  }

  get isRequired(): boolean {
    const validator = this.control.validator ? this.control.validator({} as AbstractControl) : null;
    return validator && validator['required'];
  }

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private validationErrorsService: ValidationErrorsService,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public getErrorMessage(): string {
    if (this.control?.invalid && this.control?.errors) {
      return this.validationErrorsService.getControlErrorMessage(this.control.errors);
    }
    return '';
  }

  onChange: any = (value: string) => {};
  onTouched: any = () => {};

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: any): void {}
}
