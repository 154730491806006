<div
  class="app-alert"
  [ngClass]="{ info: type === 'info', error: type === 'error', warning: type === 'warning' }"
>
  <div class="app-alert__icon" *ngIf="showIcon">
    <mat-icon>info_outline</mat-icon>
  </div>
  <div class="app-alert__content">
    <ng-content></ng-content>
  </div>
</div>
