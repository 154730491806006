<div class="app-dropdown f-control" [ngClass]="{ 'with-label': !!label }">
  @if (label) {
    <div class="label">
      {{ label }}:
      <ng-container *ngIf="isRequired">*</ng-container>
    </div>
  }

  <mat-form-field class="field" appearance="outline">
    <input
      type="text"
      [placeholder]="placeholderKey | transloco"
      matInput
      [formControl]="control"
      [matAutocomplete]="auto"
      #optionInput
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayFn.bind(this)"
      (optionSelected)="selected($event)"
    >
      @for (option of filteredOptions$ | async; track option) {
        <mat-option [value]="option[optionValue]">{{ option[optionLabel] }}</mat-option>
      }
    </mat-autocomplete>
  </mat-form-field>
  <mat-error class="errors" *ngIf="control?.invalid && control?.touched">
    {{ getErrorMessage() }}
  </mat-error>
</div>
