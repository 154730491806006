export enum AppButtonType {
  Basic = 'basic',
  Raised = 'raised',
  Stroked = 'stroked',
  Flat = 'flat',
  Icon = 'icon',
  Fab = 'fab',
  Icon_Stroked = 'iconStroked',
}

export enum AppButtonSize {
  Basic = 'basic',
  InFilterRow = 'inFilterRow',
  FullWidth = 'fullWidth',
  Width60prc = 'width60prc',
  Small = 'small',
  ExtraSmall = 'extraSmall',
  FullHeight = 'fullHeight',
}

export enum AppLabelColors {
  ORANGE = 'orange',
  RED = 'red',
  BLUE = 'blue',
  GREY = 'grey',
}
