import { AppState } from 'src/app/core/store/state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectRootState = createFeatureSelector<AppState>('rootState');

export const selectUser = createSelector(selectRootState, (state: AppState) => state.user);

export const selectDefaultContentLanguageId = createSelector(
  selectRootState,
  (state: AppState) => state.user?.defaultContentLanguageId,
);
export const selectDefaultInterfaceLanguageId = createSelector(
  selectRootState,
  (state: AppState) => state.user?.defaultInterfaceLanguageId,
);

export const selectLoginError = createSelector(
  selectRootState,
  (state: AppState) => state.loginError,
);

export const selectOnlyEmailAuthOption = createSelector(
  selectRootState,
  (state: AppState) => state.onlyEmailAuthOption,
);

export const selectIsGettingUserDetails = createSelector(
  selectRootState,
  (state: AppState) => state.gettingUserDetails,
);

export const selectIsSavingUserDetails = createSelector(
  selectRootState,
  (state: AppState) => state.savingUserDetails,
);

export const selectIsLoggedIn = createSelector(
  selectRootState,
  (state: AppState) => state.isLoggedIn,
);

export const selectLoginning = createSelector(
  selectRootState,
  (state: AppState) => state.loginning,
);

export const selectInviting = createSelector(selectRootState, (state: AppState) => state.inviting);

export const selectGreenAuthUser = createSelector(
  selectRootState,
  (state: AppState) => state.greenAuthUser,
);
export const selectAuthType = createSelector(selectRootState, (state: AppState) => state.authType);
export const selectAuthCode = createSelector(selectRootState, (state: AppState) => state.authCode);
export const selectLicenseExpired = createSelector(
  selectRootState,
  (state: AppState) => state.licenseExpired,
);

export const selectAuthenticationResult = createSelector(
  selectRootState,
  (state: AppState) => state.authenticationResult,
);

export const selectAuthenticatedAccount = createSelector(
  selectRootState,
  (state: AppState) => state.authenticatedAccount,
);

export const selectClauseNameToBeFiltered = createSelector(
  selectRootState,
  (state: AppState) => state.clauseNameToBeFiltered,
);
