import { tuiCreateToken, tuiProvideOptions } from '@taiga-ui/cdk';
import { TuiAppearance } from '@taiga-ui/core/enums';
import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Inject, Input, HostBinding, NgModule } from '@angular/core';
import * as i1 from '@taiga-ui/core/components/svg';
import { TuiSvgModule } from '@taiga-ui/core/components/svg';
import * as i2 from '@taiga-ui/core/directives/wrapper';
import { TuiWrapperModule } from '@taiga-ui/core/directives/wrapper';
import * as i3 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';

/** Default values for the checkbox options. */
function TuiPrimitiveCheckboxComponent_tui_svg_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-svg", 2);
  }
  if (rf & 2) {
    const src_r1 = ctx.polymorpheusOutlet;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassProp("t-mark_on", !ctx_r0.empty);
    i0.ɵɵproperty("src", src_r1);
  }
}
const _c0 = a0 => ({
  $implicit: a0
});
const _c1 = ["*"];
const TUI_CHECKBOX_DEFAULT_OPTIONS = {
  size: 'm',
  appearances: {
    unchecked: TuiAppearance.Outline,
    checked: TuiAppearance.Primary,
    indeterminate: TuiAppearance.Primary
  },
  icons: {
    checked: ({
      $implicit
    }) => $implicit === 'm' ? 'tuiIconCheck' : 'tuiIconCheckLarge',
    indeterminate: ({
      $implicit
    }) => $implicit === 'm' ? 'tuiIconMinus' : 'tuiIconMinusLarge'
  }
};
/**
 * Default parameters for checkbox component
 */
const TUI_CHECKBOX_OPTIONS = tuiCreateToken(TUI_CHECKBOX_DEFAULT_OPTIONS);
function tuiCheckboxOptionsProvider(options) {
  return tuiProvideOptions(TUI_CHECKBOX_OPTIONS, options, TUI_CHECKBOX_DEFAULT_OPTIONS);
}
class TuiPrimitiveCheckboxComponent {
  constructor(options) {
    this.options = options;
    this.size = this.options.size;
    this.disabled = false;
    this.focused = false;
    this.hovered = false;
    this.pressed = false;
    this.invalid = false;
    this.icon = this.options.icons.checked;
    this.value = false;
  }
  set valueSetter(value) {
    if (value !== false) {
      this.setCurrentIcon(value);
    }
    this.value = value;
  }
  get appearance() {
    switch (this.value) {
      case false:
        return this.options.appearances.unchecked;
      case true:
        return this.options.appearances.checked;
      default:
        return this.options.appearances.indeterminate;
    }
  }
  get empty() {
    return this.value === false;
  }
  setCurrentIcon(value) {
    this.icon = value === null ? this.options.icons.indeterminate : this.options.icons.checked;
  }
}
TuiPrimitiveCheckboxComponent.ɵfac = function TuiPrimitiveCheckboxComponent_Factory(t) {
  return new (t || TuiPrimitiveCheckboxComponent)(i0.ɵɵdirectiveInject(TUI_CHECKBOX_OPTIONS));
};
TuiPrimitiveCheckboxComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiPrimitiveCheckboxComponent,
  selectors: [["tui-primitive-checkbox"]],
  hostVars: 1,
  hostBindings: function TuiPrimitiveCheckboxComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵattribute("data-size", ctx.size);
    }
  },
  inputs: {
    size: "size",
    disabled: "disabled",
    focused: "focused",
    hovered: "hovered",
    pressed: "pressed",
    invalid: "invalid",
    valueSetter: [i0.ɵɵInputFlags.None, "value", "valueSetter"]
  },
  ngContentSelectors: _c1,
  decls: 3,
  vars: 10,
  consts: [["tuiWrapper", "", 3, "active", "appearance", "disabled", "focus", "hover", "invalid"], ["class", "t-mark", 3, "t-mark_on", "src", 4, "polymorpheusOutlet", "polymorpheusOutletContext"], [1, "t-mark", 3, "src"]],
  template: function TuiPrimitiveCheckboxComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵelementStart(0, "div", 0);
      i0.ɵɵtemplate(1, TuiPrimitiveCheckboxComponent_tui_svg_1_Template, 1, 3, "tui-svg", 1);
      i0.ɵɵprojection(2);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      i0.ɵɵproperty("active", ctx.pressed)("appearance", ctx.appearance)("disabled", ctx.disabled)("focus", ctx.focused)("hover", ctx.hovered)("invalid", ctx.invalid);
      i0.ɵɵadvance();
      i0.ɵɵproperty("polymorpheusOutlet", ctx.icon)("polymorpheusOutletContext", i0.ɵɵpureFunction1(8, _c0, ctx.size));
    }
  },
  dependencies: [i1.TuiSvgComponent, i2.TuiWrapperDirective, i3.PolymorpheusOutletDirective],
  styles: ["[_nghost-%COMP%]{display:block;font-size:0;flex-shrink:0}[data-size=m][_nghost-%COMP%]{width:1rem;height:1rem;border-radius:var(--tui-radius-xs)}[data-size=l][_nghost-%COMP%]{width:1.5rem;height:1.5rem;border-radius:var(--tui-radius-s)}.t-mark[_ngcontent-%COMP%]{transition-property:transform;transition-duration:var(--tui-duration, .3s);transition-timing-function:ease-in-out;display:block;width:100%;height:100%;transform:scale(0)}.t-mark_on[_ngcontent-%COMP%]{transform:scale(1)}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPrimitiveCheckboxComponent, [{
    type: Component,
    args: [{
      selector: 'tui-primitive-checkbox',
      templateUrl: './primitive-checkbox.template.html',
      styleUrls: ['./primitive-checkbox.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_CHECKBOX_OPTIONS]
      }]
    }];
  }, {
    size: [{
      type: Input
    }, {
      type: HostBinding,
      args: ['attr.data-size']
    }],
    disabled: [{
      type: Input
    }],
    focused: [{
      type: Input
    }],
    hovered: [{
      type: Input
    }],
    pressed: [{
      type: Input
    }],
    invalid: [{
      type: Input
    }],
    valueSetter: [{
      type: Input,
      args: ['value']
    }]
  });
})();
class TuiPrimitiveCheckboxModule {}
TuiPrimitiveCheckboxModule.ɵfac = function TuiPrimitiveCheckboxModule_Factory(t) {
  return new (t || TuiPrimitiveCheckboxModule)();
};
TuiPrimitiveCheckboxModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiPrimitiveCheckboxModule
});
TuiPrimitiveCheckboxModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[TuiSvgModule, TuiWrapperModule, PolymorpheusModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPrimitiveCheckboxModule, [{
    type: NgModule,
    args: [{
      imports: [TuiSvgModule, TuiWrapperModule, PolymorpheusModule],
      declarations: [TuiPrimitiveCheckboxComponent],
      exports: [TuiPrimitiveCheckboxComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_CHECKBOX_DEFAULT_OPTIONS, TUI_CHECKBOX_OPTIONS, TuiPrimitiveCheckboxComponent, TuiPrimitiveCheckboxModule, tuiCheckboxOptionsProvider };
