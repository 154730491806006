import { __decorate } from 'tslib';
import * as i2 from '@angular/common';
import { DOCUMENT, CommonModule } from '@angular/common';
import * as i0 from '@angular/core';
import { inject, InjectionToken, SkipSelf, Optional, SecurityContext, ElementRef, Component, ChangeDetectionStrategy, Inject, Input, NgModule } from '@angular/core';
import * as i4 from '@angular/platform-browser';
import { DomSanitizer } from '@angular/platform-browser';
import { WINDOW } from '@ng-web-apis/common';
import * as i1 from '@taiga-ui/cdk';
import { tuiSvgLinearGradientProcessor, tuiCreateTokenFromFactory, tuiIsString, tuiAssert, tuiGetDocumentOrShadowRoot, TuiStaticRequestService, TUI_BASE_HREF, tuiPure, TuiLetModule } from '@taiga-ui/cdk';
import { TUI_CACHE_BUSTING_PAYLOAD, TUI_ICON_ERROR } from '@taiga-ui/core/constants';
import * as i3 from '@taiga-ui/core/services';
import { TuiSvgService } from '@taiga-ui/core/services';
import { TUI_DEFAULT_ICONS_PLACE, TUI_ICONS_PLACE, TUI_ICONS_PATH, TUI_SVG_SRC_PROCESSOR, TUI_SVG_CONTENT_PROCESSOR, TUI_SANITIZER } from '@taiga-ui/core/tokens';
import { tuiIsPresumedHTMLString } from '@taiga-ui/core/utils/miscellaneous';
import { identity, ReplaySubject, of } from 'rxjs';
import { switchMap, startWith, catchError, map } from 'rxjs/operators';
import { tuiIconsPathFactory } from '@taiga-ui/core/utils';
function TuiSvgComponent_ng_container_0_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 3);
  }
  if (rf & 2) {
    const innerHTML_r1 = i0.ɵɵnextContext().tuiLet;
    i0.ɵɵproperty("innerHTML", innerHTML_r1, i0.ɵɵsanitizeHtml);
  }
}
function TuiSvgComponent_ng_container_0_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    const _r7 = i0.ɵɵgetCurrentView();
    i0.ɵɵnamespaceSVG();
    i0.ɵɵelementStart(0, "svg", 4);
    i0.ɵɵlistener("error", function TuiSvgComponent_ng_container_0_ng_template_2_Template__svg_svg_error_0_listener() {
      i0.ɵɵrestoreView(_r7);
      const ctx_r6 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r6.onError());
    });
    i0.ɵɵelement(1, "use");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r3 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵattribute("href", ctx_r3.use, null, "xlink");
  }
}
function TuiSvgComponent_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, TuiSvgComponent_ng_container_0_div_1_Template, 1, 1, "div", 1)(2, TuiSvgComponent_ng_container_0_ng_template_2_Template, 2, 1, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const _r4 = i0.ɵɵreference(3);
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.isInnerHTML)("ngIfElse", _r4);
  }
}
const TUI_DEPRECATED_ICONS = {
  tuiIconAttach: 'tuiIconPaperclip',
  tuiIconAttention: 'tuiIconAlertCircle',
  tuiIconBackCircle: 'tuiIconArrowLeftCircle',
  tuiIconCall: 'tuiIconPhone',
  tuiIconCallIn: 'tuiIconPhoneIncoming',
  tuiIconCallOut: 'tuiIconPhoneOutgoing',
  tuiIconCallTransfer: 'tuiIconPhoneForwarded',
  tuiIconCancel: 'tuiIconXCircle',
  tuiIconCancelCircle: 'tuiIconSlash',
  tuiIconCard: 'tuiIconCreditCard',
  tuiIconCards: 'tuiIconCreditCard',
  tuiIconChartBar: 'tuiIconBarChart',
  tuiIconChart: 'tuiIconBarChart',
  tuiIconCheckList: 'tuiIconClipboard',
  tuiIconCloseCircle: 'tuiIconXCircle',
  tuiIconCollapse: 'tuiIconMinimize',
  tuiIconComment: 'tuiIconMessageSquare',
  tuiIconCommentFw: 'tuiIconMessageForward',
  tuiIconCompany: 'tuiIconBriefcase',
  tuiIconDefaultDoc: 'tuiIconFile',
  tuiIconDesktop: 'tuiIconMonitor',
  tuiIconDesktopOff: 'tuiIconMonitorOff',
  tuiIconDislike: 'tuiIconThumbsDown',
  tuiIconDone: 'tuiIconCheckCircle',
  tuiIconDraft: 'tuiIconEdit2',
  tuiIconEyeOpen: 'tuiIconEye',
  tuiIconEyeClosed: 'tuiIconEyeOff',
  tuiIconExpand: 'tuiIconMaximize',
  tuiIconExternal: 'tuiIconExternalLink',
  tuiIconFrame: 'tuiIconLayout',
  tuiIconGeo: 'tuiIconMapPin',
  tuiIconHeartFilled: 'tuiIconHeart',
  tuiIconHide: 'tuiIconEyeOff',
  tuiIconImg: 'tuiIconImage',
  tuiIconInfoCircle: 'tuiIconInfo',
  tuiIconLike: 'tuiIconThumbsUp',
  tuiIconLockOpen: 'tuiIconUnlock',
  tuiIconMobile: 'tuiIconSmartphone',
  tuiIconMoreHor: 'tuiIconMoreHorizontal',
  tuiIconMoreVer: 'tuiIconMoreVertical',
  tuiIconMoreVert: 'tuiIconMoreVertical',
  tuiIconPicture: 'tuiIconImage',
  tuiIconPin: 'tuiIconMapPin',
  tuiIconPrint: 'tuiIconPrinter',
  tuiIconRefresh: 'tuiIconRefreshCw',
  tuiIconRemove: 'tuiIconX',
  tuiIconShow: 'tuiIconEye',
  tuiIconSound: 'tuiIconVolume',
  tuiIconSoundOff: 'tuiIconVolumeX',
  tuiIconStarFilled: 'tuiIconStar',
  tuiIconStop: 'tuiIconSquare',
  tuiIconStructure: 'tuiIconGrid',
  tuiIconTime: 'tuiIconClock',
  tuiIconTooltip: 'tuiIconHelpCircle',
  tuiIconViewList: 'tuiIconList',
  tuiIconWifiOn: 'tuiIconWifi'
};
const TUI_SVG_DEFAULT_OPTIONS = {
  iconsPlace: TUI_DEFAULT_ICONS_PLACE,
  path: tuiIconsPathFactory(TUI_DEFAULT_ICONS_PLACE),
  srcProcessor: identity,
  contentProcessor: tuiSvgLinearGradientProcessor,
  deprecated: src => {
    const oldIcon = src.replace('Large', '').replace('Outline', '');
    const newIcon = TUI_DEPRECATED_ICONS[oldIcon];
    return newIcon ? `${oldIcon}/(Large|Outline) is deprecated, use ${newIcon}/(Large|Outline) instead` : '';
  }
};
/**
 * SVG component options
 */
const TUI_SVG_OPTIONS = tuiCreateTokenFromFactory(() => ({
  iconsPlace: inject(TUI_ICONS_PLACE),
  path: inject(TUI_ICONS_PATH),
  deprecated: TUI_SVG_DEFAULT_OPTIONS.deprecated,
  srcProcessor: inject(TUI_SVG_SRC_PROCESSOR),
  contentProcessor: inject(TUI_SVG_CONTENT_PROCESSOR)
}));
const TUI_SVG_SRC_INTERCEPTORS = new InjectionToken('[TUI_SVG_SRC_INTERCEPTORS]');
function tuiSvgSrcInterceptors(interceptor) {
  return {
    provide: TUI_SVG_SRC_INTERCEPTORS,
    useValue: interceptor,
    multi: true
  };
}
const tuiSvgOptionsProvider = options => ({
  provide: TUI_SVG_OPTIONS,
  deps: [[new SkipSelf(), new Optional(), TUI_SVG_OPTIONS], [new Optional(), TUI_ICONS_PLACE], [new Optional(), TUI_ICONS_PATH], [new Optional(), TUI_SVG_SRC_PROCESSOR], [new Optional(), TUI_SVG_CONTENT_PROCESSOR]],
  useFactory: (fallback, iconsPlace, path, srcProcessor, contentProcessor) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    return {
      iconsPlace: (_c = (_b = (_a = options.iconsPlace) !== null && _a !== void 0 ? _a : fallback === null || fallback === void 0 ? void 0 : fallback.iconsPlace) !== null && _b !== void 0 ? _b : iconsPlace) !== null && _c !== void 0 ? _c : TUI_SVG_DEFAULT_OPTIONS.iconsPlace,
      path: tuiIsString(options.path) ? tuiIconsPathFactory(options.path) : (_f = (_e = (_d = options.path) !== null && _d !== void 0 ? _d : fallback === null || fallback === void 0 ? void 0 : fallback.path) !== null && _e !== void 0 ? _e : path) !== null && _f !== void 0 ? _f : TUI_SVG_DEFAULT_OPTIONS.path,
      deprecated: (_h = (_g = options.deprecated) !== null && _g !== void 0 ? _g : fallback === null || fallback === void 0 ? void 0 : fallback.deprecated) !== null && _h !== void 0 ? _h : TUI_SVG_DEFAULT_OPTIONS.deprecated,
      srcProcessor: (_l = (_k = (_j = options.srcProcessor) !== null && _j !== void 0 ? _j : fallback === null || fallback === void 0 ? void 0 : fallback.srcProcessor) !== null && _k !== void 0 ? _k : srcProcessor) !== null && _l !== void 0 ? _l : TUI_SVG_DEFAULT_OPTIONS.srcProcessor,
      contentProcessor: (_p = (_o = (_m = options.contentProcessor) !== null && _m !== void 0 ? _m : fallback === null || fallback === void 0 ? void 0 : fallback.contentProcessor) !== null && _o !== void 0 ? _o : contentProcessor) !== null && _p !== void 0 ? _p : TUI_SVG_DEFAULT_OPTIONS.contentProcessor
    };
  }
});
const UNDEFINED_NAMED_ICON = 'Attempted to use undefined named icon';
const MISSING_EXTERNAL_ICON = 'External icon is missing on the given URL';
const FAILED_EXTERNAL_ICON = 'Failed to load external SVG';
// TODO: Consider moving to CDK along with SvgService and SvgDefsHostComponent
class TuiSvgComponent {
  constructor(doc, win, options, srcInterceptors, tuiSanitizer, svgService, staticRequestService, sanitizer, el, baseHref) {
    this.doc = doc;
    this.win = win;
    this.options = options;
    this.srcInterceptors = srcInterceptors;
    this.tuiSanitizer = tuiSanitizer;
    this.svgService = svgService;
    this.staticRequestService = staticRequestService;
    this.sanitizer = sanitizer;
    this.el = el;
    this.baseHref = baseHref;
    this.src$ = new ReplaySubject(1);
    this.icon = '';
    this.innerHTML$ = this.src$.pipe(switchMap(() => {
      if (tuiIsString(this.icon)) {
        return this.isExternal ? this.getExternalIcon(this.icon) : of(this.getSafeHtml(this.icon));
      }
      return of(this.icon);
    }), startWith(''));
  }
  set src(src) {
    var _a;
    const deprecated = this.options.deprecated(String(src));
    ngDevMode && tuiAssert.assert(!deprecated, deprecated);
    this.icon = ((_a = this.srcInterceptors) !== null && _a !== void 0 ? _a : []).reduce((newSrc, interceptor) => interceptor(newSrc, this.options), this.options.srcProcessor(src || ''));
    this.src$.next();
  }
  get src() {
    return this.icon;
  }
  get use() {
    if (tuiIsString(this.icon)) {
      return this.icon.includes('.svg#') ? this.icon : this.resolveName(this.icon, this.options.path);
    }
    return '';
  }
  get isInnerHTML() {
    return !tuiIsString(this.icon) || this.isSrc || this.isExternal || this.isName && this.isShadowDOM;
  }
  get isShadowDOM() {
    return tuiGetDocumentOrShadowRoot(this.el.nativeElement) !== this.doc;
  }
  get isUse() {
    return this.use.replace(TUI_CACHE_BUSTING_PAYLOAD, '').includes('.svg#');
  }
  get isExternal() {
    return this.isUrl || this.isCrossDomain;
  }
  get isUrl() {
    return tuiIsString(this.icon) && this.icon.replace(TUI_CACHE_BUSTING_PAYLOAD, '').endsWith('.svg');
  }
  get isSrc() {
    return tuiIsString(this.icon) && tuiIsPresumedHTMLString(this.icon);
  }
  get isName() {
    return !this.isUrl && !this.isUse && !this.isSrc;
  }
  get isCrossDomain() {
    const {
      use,
      isUse,
      win
    } = this;
    return isUse && use.startsWith('http') && !!win.origin && !use.startsWith(win.origin);
  }
  onError(message = MISSING_EXTERNAL_ICON) {
    const {
      icon
    } = this;
    const event = new CustomEvent(TUI_ICON_ERROR, {
      bubbles: true,
      detail: {
        message,
        icon: icon
      }
    });
    ngDevMode && tuiAssert.assert(false, message, icon);
    this.el.nativeElement.dispatchEvent(event);
  }
  resolveName(name, iconsPath) {
    return iconsPath(name, this.baseHref);
  }
  getSafeHtml(src) {
    return this.isSrc ? this.sanitize(src) : this.process(src);
  }
  process(src) {
    const icon = this.svgService.getOriginal(src);
    if (this.isName && !icon && !!src) {
      this.onError(UNDEFINED_NAMED_ICON);
    }
    // Empty line for innerHTML when icon is shown through USE tag
    return !this.isShadowDOM || !this.isName ? '' : this.sanitize(icon || '');
  }
  sanitize(src) {
    src = this.options.contentProcessor(src);
    return this.tuiSanitizer && tuiIsString(src) ? this.sanitizer.bypassSecurityTrustHtml(this.tuiSanitizer.sanitize(SecurityContext.HTML, src) || '') : src;
  }
  getExternalIcon(src) {
    const url = src.includes('.svg') ? src : this.use;
    return this.staticRequestService.request(url).pipe(catchError(() => {
      this.onError(FAILED_EXTERNAL_ICON);
      return of('');
    }), map(response => this.sanitize(response.replace('<svg', '<svg focusable="false"'))));
  }
}
TuiSvgComponent.ɵfac = function TuiSvgComponent_Factory(t) {
  return new (t || TuiSvgComponent)(i0.ɵɵdirectiveInject(DOCUMENT), i0.ɵɵdirectiveInject(WINDOW), i0.ɵɵdirectiveInject(TUI_SVG_OPTIONS), i0.ɵɵdirectiveInject(TUI_SVG_SRC_INTERCEPTORS, 8), i0.ɵɵdirectiveInject(TUI_SANITIZER, 8), i0.ɵɵdirectiveInject(TuiSvgService), i0.ɵɵdirectiveInject(TuiStaticRequestService), i0.ɵɵdirectiveInject(DomSanitizer), i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(TUI_BASE_HREF));
};
TuiSvgComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiSvgComponent,
  selectors: [["tui-svg"]],
  inputs: {
    src: "src"
  },
  decls: 2,
  vars: 3,
  consts: [[4, "tuiLet"], ["class", "t-src", 3, "innerHTML", 4, "ngIf", "ngIfElse"], ["useTemplate", ""], [1, "t-src", 3, "innerHTML"], ["focusable", "false", "height", "100%", "version", "1.1", "width", "100%", 0, "xmlns", "xlink", "http://www.w3.org/1999/xlink", "xmlns", "http://www.w3.org/2000/svg", 1, "t-svg", 3, "error"]],
  template: function TuiSvgComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiSvgComponent_ng_container_0_Template, 4, 2, "ng-container", 0);
      i0.ɵɵpipe(1, "async");
    }
    if (rf & 2) {
      i0.ɵɵproperty("tuiLet", i0.ɵɵpipeBind1(1, 1, ctx.innerHTML$));
    }
  },
  dependencies: [i1.TuiLetDirective, i2.NgIf, i2.AsyncPipe],
  styles: ["[_nghost-%COMP%]{display:inline-flex;vertical-align:middle;flex-shrink:0;align-items:center;justify-content:center;line-height:0;height:1.5rem;width:1.5rem;fill:transparent;stroke:transparent;font-size:1rem}.t-src[_ngcontent-%COMP%]{display:flex;width:100%;height:100%;align-items:center;justify-content:center}.t-svg[_ngcontent-%COMP%]{overflow:visible}"],
  changeDetection: 0
});
__decorate([tuiPure], TuiSvgComponent.prototype, "resolveName", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSvgComponent, [{
    type: Component,
    args: [{
      selector: 'tui-svg',
      templateUrl: './svg.template.html',
      styleUrls: ['./svg.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], function () {
    return [{
      type: Document,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }, {
      type: Window,
      decorators: [{
        type: Inject,
        args: [WINDOW]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_SVG_OPTIONS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TUI_SVG_SRC_INTERCEPTORS]
      }]
    }, {
      type: i0.Sanitizer,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TUI_SANITIZER]
      }]
    }, {
      type: i3.TuiSvgService,
      decorators: [{
        type: Inject,
        args: [TuiSvgService]
      }]
    }, {
      type: i1.TuiStaticRequestService,
      decorators: [{
        type: Inject,
        args: [TuiStaticRequestService]
      }]
    }, {
      type: i4.DomSanitizer,
      decorators: [{
        type: Inject,
        args: [DomSanitizer]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_BASE_HREF]
      }]
    }];
  }, {
    src: [{
      type: Input
    }],
    resolveName: []
  });
})();
class TuiSvgModule {}
TuiSvgModule.ɵfac = function TuiSvgModule_Factory(t) {
  return new (t || TuiSvgModule)();
};
TuiSvgModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiSvgModule
});
TuiSvgModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, TuiLetModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSvgModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiLetModule],
      declarations: [TuiSvgComponent],
      exports: [TuiSvgComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_DEPRECATED_ICONS, TUI_SVG_DEFAULT_OPTIONS, TUI_SVG_OPTIONS, TUI_SVG_SRC_INTERCEPTORS, TuiSvgComponent, TuiSvgModule, tuiSvgOptionsProvider, tuiSvgSrcInterceptors };
