import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CounterpartiesState } from './state';

export const CounterpartiesFeature = 'CounterpartiesFeature';

export const playbooksFeatureStateSelector =
  createFeatureSelector<CounterpartiesState>(CounterpartiesFeature);

export const selectCounterparties = createSelector(
  playbooksFeatureStateSelector,
  (state: CounterpartiesState) => state.counterparties,
);

export const selectCounterpartiesLoading = createSelector(
  playbooksFeatureStateSelector,
  (state: CounterpartiesState) => state.loadingCounterparties,
);

export const selectCounterpartyCreating = createSelector(
  playbooksFeatureStateSelector,
  (state: CounterpartiesState) => state.loadingCounterparty,
);

export const selectCloseCreatingCounterpartyModal = createSelector(
  playbooksFeatureStateSelector,
  (state: CounterpartiesState) => state.closeCreatingCounterpartyModal,
);

export const selectCounterpartyLoading = createSelector(
  playbooksFeatureStateSelector,
  (state: CounterpartiesState) => state.loadingCounterparty,
);

export const selectCounterparty = createSelector(
  playbooksFeatureStateSelector,
  (state: CounterpartiesState) => state.counterparty,
);

export const selectCounterpartyEditing = createSelector(
  playbooksFeatureStateSelector,
  (state: CounterpartiesState) => state.editingCounterparty,
);

export const selectCloseEditingCounterpartyModal = createSelector(
  playbooksFeatureStateSelector,
  (state: CounterpartiesState) => state.closeEditingCounterpartyModal,
);

export const fromCounterparties = {
  selectCounterparties,
  selectCounterpartiesLoading,
  selectCloseCreatingCounterpartyModal,
  selectCounterpartyCreating,
  selectCounterparty,
  selectCounterpartyLoading,
  selectCounterpartyEditing,
  selectCloseEditingCounterpartyModal,
};
