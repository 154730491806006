import * as i0 from '@angular/core';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, EventEmitter, Directive, Inject, HostBinding, Output, HostListener, NgModule } from '@angular/core';
import * as i1 from '@taiga-ui/cdk/services';
import { TuiDirectiveStylesService } from '@taiga-ui/cdk/services';
class TuiAutofilledStyleComponent {}
TuiAutofilledStyleComponent.ɵfac = function TuiAutofilledStyleComponent_Factory(t) {
  return new (t || TuiAutofilledStyleComponent)();
};
TuiAutofilledStyleComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiAutofilledStyleComponent,
  selectors: [["ng-component"]],
  hostAttrs: [1, "tui-autofilled-styles"],
  decls: 0,
  vars: 0,
  template: function TuiAutofilledStyleComponent_Template(rf, ctx) {},
  styles: [".tui-autofill input,input.tui-autofill{transition:box-shadow .01s}\n"],
  encapsulation: 2,
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiAutofilledStyleComponent, [{
    type: Component,
    args: [{
      host: {
        class: 'tui-autofilled-styles'
      },
      template: '',
      styleUrls: ['./autofilled.style.less'],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class TuiAutofilledDirective {
  constructor(directiveStyles) {
    this.autofilled = false;
    this.tuiAutofilledChange = new EventEmitter();
    directiveStyles.addComponent(TuiAutofilledStyleComponent);
  }
  transitionStartHandler({
    propertyName,
    target
  }) {
    var _a;
    const matchedAutofill = propertyName.includes('box-shadow') && ((_a = target) === null || _a === void 0 ? void 0 : _a.matches('input'));
    if (matchedAutofill) {
      this.autofilled = !this.autofilled;
      this.tuiAutofilledChange.emit(this.autofilled);
    }
  }
}
TuiAutofilledDirective.ɵfac = function TuiAutofilledDirective_Factory(t) {
  return new (t || TuiAutofilledDirective)(i0.ɵɵdirectiveInject(TuiDirectiveStylesService));
};
TuiAutofilledDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiAutofilledDirective,
  selectors: [["", "tuiAutofilledChange", ""]],
  hostAttrs: [1, "tui-autofill"],
  hostVars: 2,
  hostBindings: function TuiAutofilledDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("transitionstart", function TuiAutofilledDirective_transitionstart_HostBindingHandler($event) {
        return ctx.transitionStartHandler($event);
      });
    }
    if (rf & 2) {
      i0.ɵɵclassProp("_autofilled", ctx.autofilled);
    }
  },
  outputs: {
    tuiAutofilledChange: "tuiAutofilledChange"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiAutofilledDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiAutofilledChange]',
      host: {
        class: 'tui-autofill'
      }
    }]
  }], function () {
    return [{
      type: i1.TuiDirectiveStylesService,
      decorators: [{
        type: Inject,
        args: [TuiDirectiveStylesService]
      }]
    }];
  }, {
    autofilled: [{
      type: HostBinding,
      args: ['class._autofilled']
    }],
    tuiAutofilledChange: [{
      type: Output
    }],
    transitionStartHandler: [{
      type: HostListener,
      args: ['transitionstart', ['$event']]
    }]
  });
})();
class TuiAutofilledModule {}
TuiAutofilledModule.ɵfac = function TuiAutofilledModule_Factory(t) {
  return new (t || TuiAutofilledModule)();
};
TuiAutofilledModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiAutofilledModule
});
TuiAutofilledModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiAutofilledModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiAutofilledDirective, TuiAutofilledStyleComponent],
      exports: [TuiAutofilledDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiAutofilledDirective, TuiAutofilledModule, TuiAutofilledStyleComponent };
