import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Self, Inject, ViewChild, Input, HostBinding, HostListener, NgModule } from '@angular/core';
import { TuiDestroyService, TUI_IS_MOBILE } from '@taiga-ui/cdk';
import { TuiHintOptionsDirective, TUI_HINT_OPTIONS, TuiHintHoverDirective } from '@taiga-ui/core/directives';
import { MODE_PROVIDER } from '@taiga-ui/core/providers';
import { TUI_MODE } from '@taiga-ui/core/tokens';
import { takeUntil } from 'rxjs/operators';
import * as i1 from '@taiga-ui/core/components/svg';
import { TuiSvgModule } from '@taiga-ui/core/components/svg';
import * as i2 from '@taiga-ui/core/directives/wrapper';
import { TuiWrapperModule } from '@taiga-ui/core/directives/wrapper';
import * as i3 from '@taiga-ui/core/directives/hint';
import { TuiHintModule } from '@taiga-ui/core/directives/hint';
import * as i4 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import * as i5 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i6 from 'rxjs';
function TuiTooltipComponent_tui_svg_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-svg", 3);
  }
  if (rf & 2) {
    const src_r2 = ctx.polymorpheusOutlet;
    i0.ɵɵproperty("src", src_r2);
  }
}
class TuiTooltipComponent extends TuiHintOptionsDirective {
  constructor(destroy$, mode$, options, isMobile) {
    super(options);
    this.isMobile = isMobile;
    this.mode = null;
    this.describeId = '';
    mode$.pipe(takeUntil(destroy$)).subscribe(mode => {
      this.mode = mode;
    });
  }
  get computedAppearance() {
    return this.appearance || this.mode || '';
  }
  stopOnMobile(event) {
    var _a;
    if (this.isMobile) {
      event.preventDefault();
      event.stopPropagation();
    }
    (_a = this.driver$) === null || _a === void 0 ? void 0 : _a.toggle();
  }
}
TuiTooltipComponent.ɵfac = function TuiTooltipComponent_Factory(t) {
  return new (t || TuiTooltipComponent)(i0.ɵɵdirectiveInject(TuiDestroyService, 2), i0.ɵɵdirectiveInject(TUI_MODE), i0.ɵɵdirectiveInject(TUI_HINT_OPTIONS), i0.ɵɵdirectiveInject(TUI_IS_MOBILE));
};
TuiTooltipComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiTooltipComponent,
  selectors: [["tui-tooltip"]],
  viewQuery: function TuiTooltipComponent_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(TuiHintHoverDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.driver$ = _t.first);
    }
  },
  hostVars: 1,
  hostBindings: function TuiTooltipComponent_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("mousedown", function TuiTooltipComponent_mousedown_HostBindingHandler($event) {
        return ctx.stopOnMobile($event);
      });
    }
    if (rf & 2) {
      i0.ɵɵattribute("data-appearance", ctx.computedAppearance);
    }
  },
  inputs: {
    content: "content",
    direction: "direction",
    appearance: "appearance",
    showDelay: "showDelay",
    hideDelay: "hideDelay",
    describeId: "describeId",
    context: "context"
  },
  features: [i0.ɵɵProvidersFeature([TuiDestroyService, MODE_PROVIDER]), i0.ɵɵInheritDefinitionFeature],
  decls: 4,
  vars: 11,
  consts: [["appearance", "icon", "automation-id", "tui-tooltip__icon", "tuiWrapper", "", 1, "t-tooltip-icon", 3, "hover", "tuiHint", "tuiHintAppearance", "tuiHintContext", "tuiHintDescribe", "tuiHintDirection", "tuiHintHideDelay", "tuiHintShowDelay"], ["driver", "tuiHintHover"], [3, "src", 4, "polymorpheusOutlet"], [3, "src"]],
  template: function TuiTooltipComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementStart(0, "span", 0, 1);
      i0.ɵɵpipe(2, "async");
      i0.ɵɵtemplate(3, TuiTooltipComponent_tui_svg_3_Template, 1, 1, "tui-svg", 2);
      i0.ɵɵelementEnd();
    }
    if (rf & 2) {
      const _r0 = i0.ɵɵreference(1);
      i0.ɵɵproperty("hover", i0.ɵɵpipeBind1(2, 9, _r0) || null)("tuiHint", ctx.content)("tuiHintAppearance", ctx.computedAppearance)("tuiHintContext", ctx.context)("tuiHintDescribe", ctx.describeId)("tuiHintDirection", ctx.direction)("tuiHintHideDelay", ctx.hideDelay)("tuiHintShowDelay", ctx.showDelay);
      i0.ɵɵadvance(3);
      i0.ɵɵproperty("polymorpheusOutlet", ctx.icon);
    }
  },
  dependencies: [i1.TuiSvgComponent, i2.TuiWrapperDirective, i3.TuiHintDirective, i3.TuiHintDriverDirective, i3.TuiHintHoverDirective, i3.TuiHintPositionDirective, i3.TuiHintDescribeDirective, i4.PolymorpheusOutletDirective, i5.AsyncPipe],
  styles: ["[_nghost-%COMP%]{transition-property:color;transition-duration:var(--tui-duration, .3s);transition-timing-function:ease-in-out;position:relative;display:inline-block;width:1.5rem;height:1.5rem;vertical-align:middle;font-size:0;line-height:0;pointer-events:auto;cursor:pointer;color:var(--tui-text-01)}[data-appearance=error][_nghost-%COMP%]{color:var(--tui-error-fill)}.t-tooltip-icon[_ngcontent-%COMP%]{display:inline-block;width:100%;height:100%;color:inherit}[tuiWrapper][data-appearance=\"textfield\"][data-state=\"disabled\"][_nghost-%COMP%]   .t-tooltip-icon[_ngcontent-%COMP%], [tuiWrapper][data-appearance=\"textfield\"][data-state=\"disabled\"]   [_nghost-%COMP%]   .t-tooltip-icon[_ngcontent-%COMP%]{opacity:1}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTooltipComponent, [{
    type: Component,
    args: [{
      selector: 'tui-tooltip',
      templateUrl: './tooltip.template.html',
      styleUrls: ['./tooltip.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [TuiDestroyService, MODE_PROVIDER],
      inputs: ['content', 'direction', 'appearance', 'showDelay', 'hideDelay']
    }]
  }], function () {
    return [{
      type: i6.Observable,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiDestroyService]
      }]
    }, {
      type: i6.Observable,
      decorators: [{
        type: Inject,
        args: [TUI_MODE]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_HINT_OPTIONS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_IS_MOBILE]
      }]
    }];
  }, {
    driver$: [{
      type: ViewChild,
      args: [TuiHintHoverDirective]
    }],
    describeId: [{
      type: Input
    }],
    context: [{
      type: Input
    }],
    computedAppearance: [{
      type: HostBinding,
      args: ['attr.data-appearance']
    }],
    stopOnMobile: [{
      type: HostListener,
      args: ['mousedown', ['$event']]
    }]
  });
})();
class TuiTooltipModule {}
TuiTooltipModule.ɵfac = function TuiTooltipModule_Factory(t) {
  return new (t || TuiTooltipModule)();
};
TuiTooltipModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiTooltipModule
});
TuiTooltipModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, TuiWrapperModule, TuiSvgModule, TuiHintModule, PolymorpheusModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiTooltipModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiWrapperModule, TuiSvgModule, TuiHintModule, PolymorpheusModule],
      declarations: [TuiTooltipComponent],
      exports: [TuiTooltipComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiTooltipComponent, TuiTooltipModule };
