import { LanguageDto } from '../services/languages-api.service';

export interface SharedState {
  contentLanguages: LanguageDto[] | null;
  interfaceLanguages: LanguageDto[] | null;
}

export const initialState: SharedState = {
  contentLanguages: null,
  interfaceLanguages: null,
};
