import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SharedState } from './state';

export const SharedFeature = 'SharedFeature';

export const sharedFeatureStateSelector = createFeatureSelector<SharedState>(SharedFeature);

export const selectContentLanguages = createSelector(
  sharedFeatureStateSelector,
  (state: SharedState) => state.contentLanguages,
);

export const selectInterfaceLanguages = createSelector(
  sharedFeatureStateSelector,
  (state: SharedState) => state.interfaceLanguages,
);

export const fromShared = {
  selectContentLanguages,
  selectInterfaceLanguages,
};
