<div class="app-checkbox">
  <div class="app-checkbox__input-wrapper">
    <input
      type="checkbox"
      id="{{ id }}"
      class="app-checkbox__input"
      [formControl]="control"
      [ngClass]="{ _bigCheckbox: size === CHECK_BOX_SIZE.BIG }"
    />
  </div>

  <label
    for="{{ id }}"
    class="app-checkbox__label"
    [ngClass]="{ _normal: fontSize === FONT_SIZE.NORMAL }"
  >
    <ng-container *ngIf="label; else labelTemplate">{{ label }}</ng-container>

    <ng-template #labelTemplate>
      <span><ng-content></ng-content></span>
    </ng-template>
  </label>
</div>
