import { createReducer, on } from '@ngrx/store';
import { SharedActions } from './actions';
import { initialState } from './state';

export const featureReducer = createReducer(
  initialState,
  on(SharedActions.setContentLanguages, (state, { languages }) => ({
    ...state,
    contentLanguages: languages,
  })),
  on(SharedActions.setInterfaceLanguages, (state, { languages }) => ({
    ...state,
    interfaceLanguages: languages,
  })),
);
