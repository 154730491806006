import { ChangeDetectionStrategy, Component, Input, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';

@Component({
  selector: 'app-search-input',
  templateUrl: './app-search-input.component.html',
  styleUrl: './app-search-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppSearchInputComponent implements ControlValueAccessor {
  @Input() label: string | undefined;
  @Input() placeholder: string = '';
  @Input() name: string = '';
  @Input() id: string = '';

  public hide: boolean = false;

  get control(): FormControl {
    return this.ngControl?.control as FormControl;
  }

  get isRequired(): boolean {
    const validator = this.control.validator ? this.control.validator({} as AbstractControl) : null;
    return validator && validator['required'];
  }

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  onChange: any = (value: string) => {};
  onTouched: any = () => {};

  public clear(): void {
    this.control.patchValue(null);
  }

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: any): void {}
}
