<div class="app-radio f-control" [ngClass]="{ 'with-label': !!label }">
  <mat-label *ngIf="label" class="label"
    >{{ label }}:
    <ng-container *ngIf="isRequired">*</ng-container>
  </mat-label>

  <mat-radio-group
    aria-label="Select an option"
    [formControl]="control"
    [ngClass]="{ readonly: readonly }"
  >
    <mat-radio-button *ngFor="let option of items" [value]="option[optionValue]">
      {{ option[optionLabel] }}
    </mat-radio-button>
  </mat-radio-group>

  <mat-error class="errors" *ngIf="showErrors && control?.invalid && control?.touched"
    >{{ getErrorMessage() }}
  </mat-error>
</div>
