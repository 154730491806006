import { ChangeDetectionStrategy, Component, Input, Optional, Self } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { ValidationErrorsService } from 'src/app/shared/services/validation-errors.service';

@Component({
  selector: 'app-radio',
  templateUrl: './app-radio.component.html',
  styleUrl: './app-radio.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppRadioComponent implements ControlValueAccessor {
  @Input() label: string | undefined;
  @Input() items!: any[];
  @Input() optionLabel: string = 'name';
  @Input() optionValue: string = 'id';
  @Input() readonly = false;
  @Input() showErrors?: boolean = true;

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private validationErrorsService: ValidationErrorsService,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public getErrorMessage(): string {
    if (this.control?.invalid && this.control?.errors) {
      return this.validationErrorsService.getControlErrorMessage(this.control.errors);
    }
    return '';
  }

  get control(): FormControl {
    return this.ngControl?.control as FormControl;
  }

  get isRequired(): boolean {
    const validator = this.control.validator ? this.control.validator({} as AbstractControl) : null;
    return validator && validator['required'];
  }

  onChange: any = (value: string) => {};
  onTouched: any = () => {};

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(value: any): void {}
}
