import * as i0 from '@angular/core';
import { TemplateRef, Directive, Inject, Input, NgModule } from '@angular/core';
import * as i1 from '@taiga-ui/cdk/components/dropdown-host';
import { TuiDropdownPortalService } from '@taiga-ui/cdk/components/dropdown-host';
class TuiPortalDirective {
  constructor(templateRef, portalService) {
    this.templateRef = templateRef;
    this.portalService = portalService;
  }
  set tuiPortal(show) {
    var _a;
    (_a = this.viewRef) === null || _a === void 0 ? void 0 : _a.destroy();
    if (show) {
      this.viewRef = this.portalService.addTemplate(this.templateRef);
    }
  }
  ngOnDestroy() {
    var _a;
    (_a = this.viewRef) === null || _a === void 0 ? void 0 : _a.destroy();
  }
}
TuiPortalDirective.ɵfac = function TuiPortalDirective_Factory(t) {
  return new (t || TuiPortalDirective)(i0.ɵɵdirectiveInject(TemplateRef), i0.ɵɵdirectiveInject(TuiDropdownPortalService));
};
TuiPortalDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiPortalDirective,
  selectors: [["", "tuiPortal", ""]],
  inputs: {
    tuiPortal: "tuiPortal"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPortalDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiPortal]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef,
      decorators: [{
        type: Inject,
        args: [TemplateRef]
      }]
    }, {
      type: i1.TuiDropdownPortalService,
      decorators: [{
        type: Inject,
        args: [TuiDropdownPortalService]
      }]
    }];
  }, {
    tuiPortal: [{
      type: Input
    }]
  });
})();
class TuiPortalModule {}
TuiPortalModule.ɵfac = function TuiPortalModule_Factory(t) {
  return new (t || TuiPortalModule)();
};
TuiPortalModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiPortalModule
});
TuiPortalModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiPortalModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiPortalDirective],
      exports: [TuiPortalDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiPortalDirective, TuiPortalModule };
