class TuiDayRangePeriod {
    constructor(range, name, content) {
        this.range = range;
        this.name = name;
        this.content = content;
    }
    toString() {
        return this.name;
    }
}

class TuiNamedDay {
    constructor(day, name, displayDay = day) {
        this.day = day;
        this.name = name;
        this.displayDay = displayDay;
    }
    toString() {
        return this.name;
    }
}

/**
 * Wrapper around an item to add `toString()` method
 */
class TuiStringifiableItem {
    constructor(item, stringify) {
        this.item = item;
        this.stringify = stringify;
    }
    toString() {
        return this.stringify(this.item);
    }
}

/**
 * Generated bundle index. Do not edit.
 */

export { TuiDayRangePeriod, TuiNamedDay, TuiStringifiableItem };

