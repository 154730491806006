import { tuiIsString } from '@taiga-ui/cdk/utils/miscellaneous';

function makeRandomSalt() {
    return Math.floor(Math.random() * Date.now());
}
function escapeRegExp(search) {
    return search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
function extractLinearGradientIdsFromSvg(svg) {
    var _a;
    const ids = ((_a = svg.match(/url\(("?)('*)#(.*?)('*)\)/g)) !== null && _a !== void 0 ? _a : []).map(url => url.slice(4, url.length - 1).replace(/['"#]+/g, ''));
    return Array.from(new Set(ids));
}
/**
 * @description:
 * Any ‘linearGradient’ attributes which are defined on the referenced
 * element which are not defined on this element are inherited by this element.
 * If this element has no defined gradient stops, and the referenced element does
 * (possibly due to its own ‘xlink:href’ attribute), then this element inherits
 * the gradient stop from the referenced element. Inheritance can be indirect
 * to an arbitrary level; thus, if the referenced element inherits attribute
 * or gradient stops due to its own ‘xlink:href’ attribute, then the current
 * element can inherit those attributes or gradient stops.
 *
 * Documentation: https://www.w3.org/TR/SVG11/pservers.html
 *
 */
function tuiSvgLinearGradientProcessor(svg, salt = makeRandomSalt()) {
    if (tuiIsString(svg)) {
        const uniqueIds = extractLinearGradientIdsFromSvg(svg);
        return uniqueIds.reduce((newSvg, previousId) => {
            const escapedId = escapeRegExp(previousId);
            const newId = `id_${salt}_${previousId}`;
            return newSvg
                .replace(new RegExp(`"${escapedId}"`, 'g'), `"${newId}"`)
                .replace(new RegExp(`'${escapedId}'`, 'g'), `'${newId}'`)
                .replace(new RegExp(`url\\('#${escapedId}'\\)`, 'g'), `url('#${newId}')`)
                .replace(new RegExp(`url\\("#${escapedId}"\\)`, 'g'), `url("#${newId}")`)
                .replace(new RegExp(`url\\(#${escapedId}\\)`, 'g'), `url(#${newId})`);
        }, svg);
    }
    return svg;
}

/**
 * Generated bundle index. Do not edit.
 */

export { tuiSvgLinearGradientProcessor };

