import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  AfterViewInit,
  Self,
  Optional,
  ChangeDetectorRef,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { Observable, fromEvent, of } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';
import { ValidationErrorsService } from '../../../services/validation-errors.service';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ALL_DROPDOWN_ITEM } from 'src/app/core/constants/constants';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isPresent } from 'src/app/core/utils/isPresent';

@UntilDestroy()
@Component({
  selector: 'app-multi-select-autocomplete',
  templateUrl: './multi-select-autocomplete.component.html',
  styleUrl: './multi-select-autocomplete.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectAutocompleteComponent
  implements OnChanges, AfterViewInit, ControlValueAccessor
{
  @Input() label: string | undefined;
  @Input() autocompleteOptions: any[] = [];
  @Input() optionLabel: string = 'name';
  @Input() hiddenOptions: number[] = [];
  @Input() optionValue: string = 'id';
  @Input() placeholderKey: string = 'placeholders.select';
  @ViewChild('optionInput')
  optionInput!: ElementRef<HTMLInputElement>;
  @Output() onSelect: EventEmitter<string[]> = new EventEmitter<string[]>();

  focusOut$: Observable<any> | undefined;
  filteredOptions$!: Observable<any[]>;
  selectedOptionValues: any[] = [];
  selectAllValue = ALL_DROPDOWN_ITEM[this.optionValue as keyof typeof ALL_DROPDOWN_ITEM];
  prevValues: (never[] & number[]) | undefined;

  constructor(
    @Self() @Optional() public ngControl: NgControl,
    private validationErrorsService: ValidationErrorsService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngAfterViewInit(): void {
    this.initSubscriptions();
  }

  initSubscriptions() {
    this.processValueChanges();
    this.processFocusOut();
  }

  processValueChanges(): void {
    this.control.valueChanges
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe((value) => {
        if (value === '' && this.selectedOptionValues.length > 0) {
          this.control.patchValue(this.selectedOptionValues.join(','), { emitEvent: false });
          this.control.markAsPristine();
          this.control.updateValueAndValidity();
        }
      });
  }

  processFocusOut(): void {
    this.focusOut$ = fromEvent(this.optionInput.nativeElement, 'blur');
    this.focusOut$.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe((event: any) => {
      this.optionInput.nativeElement.value = '';

      if (this.selectedOptionValues.length === 0) {
        this.control.patchValue(undefined);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.['autocompleteOptions']?.currentValue &&
      JSON.stringify(changes['autocompleteOptions'].currentValue) !==
        JSON.stringify(changes['autocompleteOptions'].previousValue)
    ) {
      this.initFilteredOptions();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const isSelectAll = event.option.value === this.selectAllValue;

    if (isSelectAll) {
      this.selectedOptionValues = [this.selectAllValue];
      const valuesWithoutAll = this.autocompleteOptions
        .map((item) => item?.[this.optionValue])
        .filter((value) => {
          return value !== this.selectAllValue;
        });
      this.onSelect.emit(valuesWithoutAll);
    } else {
      this.selectedOptionValues.push(event.option.value);
      this.control.patchValue(this.selectedOptionValues.join(','), { emitEvent: false });
      this.onSelect.emit(this.selectedOptionValues);
    }

    this.optionInput.nativeElement.value = '';
    this.initFilteredOptions(isSelectAll);
  }

  initFilteredOptions(isSelectAll: boolean = false): void {
    if (isSelectAll) {
      this.filteredOptions$ = of([]);
    } else {
      this.filteredOptions$ = this.control?.valueChanges.pipe(
        startWith<string>(''),
        filter(isPresent),
        map((filter) => {
          return this.filter(filter);
        }),
      );
    }
  }

  filter(filter: string): any[] {
    if (typeof filter === 'number') {
      filter = filter + '';
    }

    if (typeof filter === 'object' && filter?.[0]) {
      filter = filter?.[0] + '';
    }

    if (filter) {
      return this.autocompleteOptions
        .filter((option) => {
          const filterOk =
            option[this.optionLabel].toLowerCase().indexOf(filter.toLowerCase()) >= 0;
          const notSelected = !this.selectedOptionValues.find((selectedValue) => {
            return option[this.optionValue].toString().indexOf(selectedValue.toString()) !== -1;
          });
          return filterOk && notSelected;
        })
        .sort((a, b) => a[this.optionLabel].localeCompare(b[this.optionLabel]));
    } else {
      return this.autocompleteOptions
        .slice()
        .filter((option) => {
          const notSelected = !this.selectedOptionValues.find((selectedValue) => {
            return selectedValue === option[this.optionValue];
          });
          return notSelected;
        })
        .sort((a, b) => a[this.optionLabel].localeCompare(b[this.optionLabel]));
    }
  }

  remove(value: string): void {
    const index = this.selectedOptionValues.indexOf(value);

    if (index >= 0) {
      this.selectedOptionValues.splice(index, 1);
    }

    if (this.selectedOptionValues.length === 0) {
      this.control.patchValue(undefined);
    }

    this.control.patchValue(this.selectedOptionValues.join(','), { emitEvent: false });
    this.control.markAsDirty();
    this.onSelect.emit(this.selectedOptionValues);
    this.initFilteredOptions();
  }

  getLabel(value: string): string {
    return this.autocompleteOptions.find((item) => {
      return item[this.optionValue] === value;
    })?.[this.optionLabel];
  }

  public getErrorMessage(): string {
    if (this.control?.invalid && this.control?.errors) {
      return this.validationErrorsService.getControlErrorMessage(this.control.errors);
    }
    return '';
  }

  onChange: any = (value: string) => {};
  onTouched: any = () => {};

  public registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public writeValue(values: number[]): void {
    if (typeof values === 'string' || values === null) {
      return; // string is internal case, no need to process
    }

    if (JSON.stringify(this.prevValues) === JSON.stringify(values)) {
      return;
    }

    this.prevValues = Object.assign([], values);
    this.selectedOptionValues = [];

    values?.forEach((value) => {
      const toBeSelectedItem = this.autocompleteOptions.find((item) => {
        return item[this.optionValue] === value;
      });

      if (toBeSelectedItem) {
        this.selectedOptionValues.push(value);
      }
    });

    if (this.selectedOptionValues?.length > 0) {
      if (this.ngControl && this.ngControl.control) {
        this.control.patchValue(this.selectedOptionValues.join(','));
        this.cdr.markForCheck();
      }

      this.onSelect.emit(this.selectedOptionValues);
      this.initFilteredOptions();
    }
  }

  isHiddenOption(value: number): boolean {
    return this.hiddenOptions.indexOf(value) !== -1;
  }

  get isRequired(): boolean {
    const validator = this.control.validator ? this.control.validator({} as AbstractControl) : null;
    return validator && validator['required'];
  }

  get control(): FormControl {
    return this.ngControl?.control as FormControl;
  }
}
