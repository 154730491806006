<div class="app-multi-select f-control" [ngClass]="{ 'with-label': !!label }">
  @if (label) {
    <div class="label">
      {{ label }}:
      <ng-container *ngIf="isRequired">*</ng-container>
    </div>
  }

  <mat-form-field
    appearance="outline"
    class="field"
    [ngClass]="{ 'mat-form-field-invalid': !this.control.valid && control.touched }"
  >
    <mat-chip-grid #chipGrid>
      @for (value of selectedOptionValues; track value) {
        <!-- <span class="selected-option-value" (click)="remove(value)">{{ getLabel(value) }} <mat-icon>close</mat-icon></span> -->
        <mat-chip-row (removed)="remove(value)">
          {{ getLabel(value) }}
          <button matChipRemove>
            <mat-icon>close</mat-icon>
          </button>
        </mat-chip-row>
      }
    </mat-chip-grid>
    <input
      class="input-field"
      [placeholder]="placeholderKey | transloco"
      #optionInput
      [formControl]="control"
      [matChipInputFor]="chipGrid"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
      @for (option of filteredOptions$ | async; track option) {
        <mat-option
          [value]="option[this.optionValue]"
          class="autocomplete-option"
          [ngClass]="{
            'd-none': isHiddenOption(option[this.optionValue])
          }"
          >{{ option[this.optionLabel] }}</mat-option
        >
      }
    </mat-autocomplete>
  </mat-form-field>

  <mat-error class="errors" *ngIf="control?.invalid && control?.touched">
    {{ getErrorMessage() }}
  </mat-error>
</div>
