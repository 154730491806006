import * as i0 from '@angular/core';
import { Component, ChangeDetectionStrategy, Inject, HostBinding, NgModule } from '@angular/core';
import * as i1 from '@taiga-ui/core';
import { tuiSizeBigger, TuiHostedDropdownComponent, TUI_TEXTFIELD_SIZE, TuiSvgModule } from '@taiga-ui/core';
import * as i2 from '@tinkoff/ng-polymorpheus';
import { PolymorpheusComponent, PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import { tuiCreateToken, tuiProvideOptions } from '@taiga-ui/cdk';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';

/** Default values for arrow options */
function TuiArrowComponent_tui_svg_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-svg", 2);
  }
  if (rf & 2) {
    const src_r2 = ctx.polymorpheusOutlet;
    i0.ɵɵproperty("src", src_r2);
  }
}
function TuiArrowComponent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
const TUI_ARROW_DEFAULT_OPTIONS = {
  iconSmall: 'tuiIconChevronDown',
  iconLarge: 'tuiIconChevronDownLarge'
};
/**
 * Default parameters for arrow component
 */
const TUI_ARROW_OPTIONS = tuiCreateToken(TUI_ARROW_DEFAULT_OPTIONS);
function tuiArrowOptionsProvider(options) {
  return tuiProvideOptions(TUI_ARROW_OPTIONS, options, TUI_ARROW_DEFAULT_OPTIONS);
}
class TuiArrowComponent {
  constructor(dropdown, textfieldSize, options) {
    this.dropdown = dropdown;
    this.textfieldSize = textfieldSize;
    this.options = options;
  }
  get rotated() {
    return this.dropdown.open;
  }
  get arrowIcon() {
    return tuiSizeBigger(this.textfieldSize.size) ? this.options.iconLarge : this.options.iconSmall;
  }
}
TuiArrowComponent.ɵfac = function TuiArrowComponent_Factory(t) {
  return new (t || TuiArrowComponent)(i0.ɵɵdirectiveInject(TuiHostedDropdownComponent), i0.ɵɵdirectiveInject(TUI_TEXTFIELD_SIZE), i0.ɵɵdirectiveInject(TUI_ARROW_OPTIONS));
};
TuiArrowComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiArrowComponent,
  selectors: [["tui-arrow"]],
  hostVars: 2,
  hostBindings: function TuiArrowComponent_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵclassProp("_rotated", ctx.rotated);
    }
  },
  decls: 3,
  vars: 4,
  consts: [[3, "src", 4, "polymorpheusOutlet"], [4, "ngIf"], [3, "src"]],
  template: function TuiArrowComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵtemplate(0, TuiArrowComponent_tui_svg_0_Template, 1, 1, "tui-svg", 0)(1, TuiArrowComponent_ng_container_1_Template, 1, 0, "ng-container", 1);
      i0.ɵɵpipe(2, "async");
    }
    if (rf & 2) {
      i0.ɵɵproperty("polymorpheusOutlet", ctx.arrowIcon);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(2, 2, ctx.dropdown.openChange));
    }
  },
  dependencies: [i1.TuiSvgComponent, i2.PolymorpheusOutletDirective, i3.NgIf, i3.AsyncPipe],
  styles: ["[_nghost-%COMP%]{transition-property:transform;transition-duration:var(--tui-duration, .3s);transition-timing-function:ease-in-out;display:block;cursor:pointer;pointer-events:none}._rotated[_nghost-%COMP%]{transform:rotate(180deg)}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiArrowComponent, [{
    type: Component,
    args: [{
      selector: 'tui-arrow',
      templateUrl: './arrow.template.html',
      styleUrls: ['./arrow.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], function () {
    return [{
      type: i1.TuiHostedDropdownComponent,
      decorators: [{
        type: Inject,
        args: [TuiHostedDropdownComponent]
      }]
    }, {
      type: i1.TuiTextfieldSizeDirective,
      decorators: [{
        type: Inject,
        args: [TUI_TEXTFIELD_SIZE]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_ARROW_OPTIONS]
      }]
    }];
  }, {
    rotated: [{
      type: HostBinding,
      args: ['class._rotated']
    }]
  });
})();
const TUI_ARROW = new PolymorpheusComponent(TuiArrowComponent);
class TuiArrowModule {}
TuiArrowModule.ɵfac = function TuiArrowModule_Factory(t) {
  return new (t || TuiArrowModule)();
};
TuiArrowModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiArrowModule
});
TuiArrowModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, TuiSvgModule, PolymorpheusModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiArrowModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiSvgModule, PolymorpheusModule],
      declarations: [TuiArrowComponent],
      exports: [TuiArrowComponent]
    }]
  }], null, null);
})();
const TUI_ARROW_DEFAULT_MODE = {
  interactive: TUI_ARROW,
  disabled: TUI_ARROW
};
/**
 * Type of icon in dropdowns for interactive or disable mode
 */
const TUI_ARROW_MODE = tuiCreateToken(TUI_ARROW_DEFAULT_MODE);
function tuiArrowModeProvider(options) {
  return tuiProvideOptions(TUI_ARROW_MODE, options, TUI_ARROW_DEFAULT_MODE);
}

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_ARROW, TUI_ARROW_DEFAULT_MODE, TUI_ARROW_DEFAULT_OPTIONS, TUI_ARROW_MODE, TUI_ARROW_OPTIONS, TuiArrowComponent, TuiArrowModule, tuiArrowModeProvider, tuiArrowOptionsProvider };
