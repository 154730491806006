import { TranslocoService } from '@ngneat/transloco';
import { OnInit, Pipe, PipeTransform } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getDocTypeById } from 'src/app/core/utils/doc-types.utils';
import { GetDocumentTypeDto } from 'src/app/modules/doc-types/models/doc-types.model';
import { DocumentTypesApiService } from 'src/app/modules/doc-types/services/document-types-api.service';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/core/store/state';
import { filter, withLatestFrom } from 'rxjs';
import { selectUser } from 'src/app/core/store/selectors';
import { isPresent } from 'src/app/core/utils/isPresent';

@UntilDestroy()
@Pipe({
  name: 'docTypes',
})
export class DocTypesPipe implements PipeTransform, OnInit {
  private docTypes!: GetDocumentTypeDto[];

  constructor(
    private documentTypesApiService: DocumentTypesApiService,
    public translocoService: TranslocoService,
    private store: Store<AppState>,
  ) {}

  getDocumentTypes$ = this.documentTypesApiService
    .getDocumentTypes$()
    .pipe(
      untilDestroyed(this),
      withLatestFrom(this.store.select(selectUser).pipe(filter(isPresent))),
    )
    .subscribe(([docTypes, user]) => {
      this.docTypes = docTypes;
    });

  ngOnInit(): void {}

  transform(date: number[]): string {
    const that = this;
    if (!date || !this.docTypes || date?.length === 0) {
      return that.translocoService.translate(`text.none`);
    }

    return getItemHtml(that, date, this.docTypes);
  }
}

function getItemHtml(
  that: DocTypesPipe,
  docTypeIds: number[],
  allDocTypes: GetDocumentTypeDto[],
): string {
  const arrNames = docTypeIds.map((id) => getDocTypeById(id, allDocTypes)?.name);
  // const translatedArr = arrIds.map((id) => that.translocoService.translate(`docType.${id}`));

  return `<span class="doc-types-in-column">${arrNames.join(', ')}</span>`;
}
