import { Injectable } from '@angular/core';
import { SearchCriterionsDataType } from '../services/client-side-search.service';
import { Criterion } from '../services/base-event-service';

@Injectable({
  providedIn: 'root',
})
export class DocTypesSearchCriterionsFactory {
  constructor() {}
  protected readonly entities = {
    [DOC_TYPES_SEARCH_CRITERION.NAME]: {
      id: DOC_TYPES_SEARCH_CRITERION.NAME,
      title: 'Doc type name',
      data: ['name'],
      type: SearchCriterionsDataType.String,
      controlName: 'docTypeName',
    },
  };

  getAllItemsAsArray() {
    return Object.values(this.entities) as Criterion[];
  }

  getById(id: number | DOC_TYPES_SEARCH_CRITERION) {
    const item = { ...this.entities[id as DOC_TYPES_SEARCH_CRITERION] };
    if (!item) {
      throw new Error(`Unknown id ${id} in ${this.constructor.name}`);
    }
    return item;
  }
}

export enum DOC_TYPES_SEARCH_CRITERION {
  NAME = 1,
}
