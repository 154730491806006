import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DOC_STATUS } from 'src/app/core/models/status.enum';

@Component({
  selector: 'app-status',
  templateUrl: './app-status.component.html',
  styleUrl: './app-status.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppStatusComponent {
  @Input() status: DOC_STATUS | number | undefined;

  get statusText(): number | undefined {
    return this.status;
  }

  get isGreen(): boolean | undefined {
    return this.status === DOC_STATUS.SIGNED;
  }

  get isBlue(): boolean | undefined {
    return this.status === DOC_STATUS.VALIDATED;
  }

  get isRed(): boolean | undefined {
    return this.status === DOC_STATUS.DROPPED;
  }

  get isYellow(): boolean | undefined {
    return this.status === DOC_STATUS.INPROGRESS;
  }
}
