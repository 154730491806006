<tui-primitive-textfield
  #input
  tuiTextfieldIconLeft="tuiIconSearchLarge"
  class="tui-space_all-2"
  [(value)]="searchValue"
  (keydown.arrowDown.prevent)="onArrowDown(list, $event)"
>
  {{placeholderKey | transloco}}
</tui-primitive-textfield>
<hr />
<tui-data-list
  #list
  [emptyContent]="'text.noResultsFound' | transloco"
  (keydown)="onKeyDown($event.key, input.nativeFocusableElement)"
>
  <tui-opt-group *ngIf="!searchValue">
    <button tuiOption class="tui-select-all" [value]="all">{{"labels.all" | transloco}}</button>
  </tui-opt-group>
  <ng-container>
    <tui-opt-group *tuiLet="autocompleteOptions | tuiFilter: filter : searchValue as filtered">
      <tui-opt-group *ngIf="filtered.length" tuiMultiSelectGroup>
        <button *ngFor="let item of filtered" tuiOption [value]="getValue(item)">
          {{ getLabel(item) }}
        </button>
      </tui-opt-group>
    </tui-opt-group>
  </ng-container>
</tui-data-list>
