import { Pipe, PipeTransform } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslocoService } from '@ngneat/transloco';
import { PositionFactory } from 'src/app/core/factories/position.factory';

@UntilDestroy()
@Pipe({
  name: 'myPosition',
})
export class MyPositionPipe implements PipeTransform {
  constructor(
    public translocoService: TranslocoService,
    public positionFactory: PositionFactory,
  ) {}

  transform(id: number): string {
    if (!id) {
      return '';
    }

    const myPosition = getMyPosition(this, id);
    return myPosition;
  }
}

function getMyPosition(that: MyPositionPipe, id: number): string {
  let res = '';
  // const translatedSavedUserPartyPosition = that.positionFactory.getById(id)?.name;
  const translated = that.translocoService.translate(`positions.${id}`);

  if (translated) {
    res = `<span class="position-in-column">${translated}</span>`;
  }

  return res;
}
