import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { DOC_STATUS } from '../models/status.enum';

@Injectable({
  providedIn: 'root',
})
export class DocumentStatusFactory {
  constructor(private translocoService: TranslocoService) {}
  protected readonly entities = {
    [DOC_STATUS.SIGNED]: {
      id: DOC_STATUS.SIGNED,
      name: this.translocoService.translate(`status.${DOC_STATUS.SIGNED}`),
      color: '#10ae0d',
      cssClass: '_green',
    },
    [DOC_STATUS.DROPPED]: {
      id: DOC_STATUS.DROPPED,
      name: this.translocoService.translate(`status.${DOC_STATUS.DROPPED}`),
      color: '#da365d',
      cssClass: '_red',
    },
    [DOC_STATUS.INPROGRESS]: {
      id: DOC_STATUS.INPROGRESS,
      name: this.translocoService.translate(`status.${DOC_STATUS.INPROGRESS}`),
      color: '#ffac0c',
      cssClass: '_yellow',
    },
    [DOC_STATUS.VALIDATED]: {
      id: DOC_STATUS.VALIDATED,
      name: this.translocoService.translate(`status.${DOC_STATUS.VALIDATED}`),
      color: '#0c7cff',
      cssClass: '_blue',
    },
  };

  getAllItemsAsArray() {
    return Object.values(this.entities);
  }

  getById(id: number | DOC_STATUS) {
    const item = { ...this.entities[id as DOC_STATUS] };
    if (!item) {
      throw new Error(`Unknown id ${id} in ${this.constructor.name}`);
    }
    return item;
  }
}
