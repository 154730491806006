import * as i0 from '@angular/core';
import { ElementRef, TemplateRef, Directive, Inject, Input, ViewChild, HostBinding } from '@angular/core';
import * as i1 from '@taiga-ui/cdk';
import { AbstractTuiControl, TuiIdService } from '@taiga-ui/cdk';
import { TUI_TEXTFIELD_HOST, TuiDataListDirective } from '@taiga-ui/core';
import { TUI_ITEMS_HANDLERS } from '@taiga-ui/kit/tokens';
class AbstractTuiNativeSelect {
  constructor(host, control, el, idService, itemsHandlers) {
    this.host = host;
    this.control = control;
    this.el = el;
    this.idService = idService;
    this.itemsHandlers = itemsHandlers;
    this.placeholder = '';
    this.disabledItemHandler = null;
    this.datalist = null;
  }
  get id() {
    return this.el.nativeElement.id || this.idService.generate();
  }
  get emptyOption() {
    return !!this.placeholder && !this.control.value;
  }
}
AbstractTuiNativeSelect.ɵfac = function AbstractTuiNativeSelect_Factory(t) {
  return new (t || AbstractTuiNativeSelect)(i0.ɵɵdirectiveInject(TUI_TEXTFIELD_HOST), i0.ɵɵdirectiveInject(AbstractTuiControl), i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(TuiIdService), i0.ɵɵdirectiveInject(TUI_ITEMS_HANDLERS));
};
AbstractTuiNativeSelect.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AbstractTuiNativeSelect,
  viewQuery: function AbstractTuiNativeSelect_Query(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵviewQuery(TuiDataListDirective, 7, TemplateRef);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.datalist = _t.first);
    }
  },
  hostVars: 1,
  hostBindings: function AbstractTuiNativeSelect_HostBindings(rf, ctx) {
    if (rf & 2) {
      i0.ɵɵhostProperty("id", ctx.id);
    }
  },
  inputs: {
    placeholder: "placeholder",
    disabledItemHandler: "disabledItemHandler"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractTuiNativeSelect, [{
    type: Directive
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_TEXTFIELD_HOST]
      }]
    }, {
      type: i1.AbstractTuiControl,
      decorators: [{
        type: Inject,
        args: [AbstractTuiControl]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i1.TuiIdService,
      decorators: [{
        type: Inject,
        args: [TuiIdService]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_ITEMS_HANDLERS]
      }]
    }];
  }, {
    placeholder: [{
      type: Input
    }],
    disabledItemHandler: [{
      type: Input
    }],
    datalist: [{
      type: ViewChild,
      args: [TuiDataListDirective, {
        read: TemplateRef,
        static: true
      }]
    }],
    id: [{
      type: HostBinding,
      args: ['id']
    }]
  });
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AbstractTuiNativeSelect };
