export const dataTableEvents = {
  common: {
    checkedId: 'checked-item-id',
    reviewLink: 'reviewLink',
    queryLink: 'queryLink',
    addClausesLink: 'addClausesLink',
    editProperties: 'editProperties',
    cancel: 'cancel-item',
    search: 'find-item',
    delete: 'delete-item',
    edit: 'edit-item',
    sendReport: 'send-report',
    editInDesktop: 'edit-in-desktop-item',
    apply: 'apply-item',
    case: 'case-item',
    details: 'details-item',
    download: 'download-item',
    calendar: 'calendar-item',
    previw: 'previw-item',
  },
};
