import { PartyDto } from 'src/app/modules/counterparties/services/parties-api.service';
import { CounterpartySearchModel } from 'src/app/modules/counterparties/models/counterparties.model';
import { SearchItemModel } from '../services/base-event-service';
import {
  COUNTERPARTY_SEARCH_CRITERION,
  CounterpartySearchCriterionsFactory,
} from '../factories/counterparty-search-criterions.factory';
import { FILTER_OPERATOR } from '../services/client-side-search.service';

export function getPartyById(id: number, parties: PartyDto[]): PartyDto | undefined {
  return parties.find((party) => party.id === id);
}

export function getCounterpartiesFilterConditionsFromModel(
  model: CounterpartySearchModel,
  counterpartySearchCriterionsFactory: CounterpartySearchCriterionsFactory,
): SearchItemModel[] {
  let conditions: SearchItemModel[] = [];

  if (!!model.counterpartyName) {
    const criterion = counterpartySearchCriterionsFactory.getById(
      COUNTERPARTY_SEARCH_CRITERION.NAME,
    );
    const item = {
      criterion: criterion.id,
      operator: FILTER_OPERATOR.Contains,
      type: criterion.type,
      searchValue: model.counterpartyName,
      controlName: criterion.controlName,
    } as SearchItemModel;

    conditions.push(item);
  }

  return conditions;
}
