<div class="app-input f-control" [ngClass]="{ 'with-label': !!label }">
  <mat-label *ngIf="label" class="label"
    >{{ label }}:
    <ng-container *ngIf="isRequired">*</ng-container>
  </mat-label>
  <mat-form-field
    [ngClass]="{ 'mat-form-field-invalid': highlightAsError, readonly: readonly }"
    appearance="outline"
  >
    <input
      [type]="type"
      matInput
      matNativeControl
      [formControl]="control"
      [maxLength]="maxLength"
      [readonly]="readonly"
      [mask]="mask"
      [prefix]="prefix"
      [placeholder]="placeholder"
      [name]="name"
      [id]="id"
      [autocomplete]="autocomplete"
    />
  </mat-form-field>
  <mat-error class="errors" *ngIf="showErrors && control?.invalid && control?.touched"
    >{{ getErrorMessage() }}
  </mat-error>
</div>
