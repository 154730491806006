import * as i0 from '@angular/core';
import { Directive, Optional, Inject, Self, Input, Output, NgModule } from '@angular/core';
import * as i1 from '@taiga-ui/cdk/directives/active-zone';
import { TuiActiveZoneDirective } from '@taiga-ui/cdk/directives/active-zone';
import { tuiIfMap } from '@taiga-ui/cdk/observables';
import * as i2 from '@taiga-ui/cdk/services';
import { TuiObscuredService, TuiParentsScrollService, TuiDestroyService } from '@taiga-ui/cdk/services';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Directive that monitors element visibility
 */
class TuiObscuredDirective {
  constructor(activeZone, obscured$) {
    this.enabled$ = new Subject();
    const mapped$ = obscured$.pipe(map(obscuredBy => !!obscuredBy && (!activeZone || !obscuredBy.length || obscuredBy.every(element => !activeZone.contains(element)))));
    this.tuiObscured = this.enabled$.pipe(tuiIfMap(() => mapped$));
  }
  set tuiObscuredEnabled(enabled) {
    this.enabled$.next(enabled);
  }
}
TuiObscuredDirective.ɵfac = function TuiObscuredDirective_Factory(t) {
  return new (t || TuiObscuredDirective)(i0.ɵɵdirectiveInject(TuiActiveZoneDirective, 8), i0.ɵɵdirectiveInject(TuiObscuredService, 2));
};
TuiObscuredDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiObscuredDirective,
  selectors: [["", "tuiObscured", ""]],
  inputs: {
    tuiObscuredEnabled: "tuiObscuredEnabled"
  },
  outputs: {
    tuiObscured: "tuiObscured"
  },
  features: [i0.ɵɵProvidersFeature([TuiObscuredService, TuiParentsScrollService, TuiDestroyService])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiObscuredDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiObscured]',
      providers: [TuiObscuredService, TuiParentsScrollService, TuiDestroyService]
    }]
  }], function () {
    return [{
      type: i1.TuiActiveZoneDirective,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TuiActiveZoneDirective]
      }]
    }, {
      type: i2.TuiObscuredService,
      decorators: [{
        type: Self
      }, {
        type: Inject,
        args: [TuiObscuredService]
      }]
    }];
  }, {
    tuiObscuredEnabled: [{
      type: Input
    }],
    tuiObscured: [{
      type: Output
    }]
  });
})();
class TuiObscuredModule {}
TuiObscuredModule.ɵfac = function TuiObscuredModule_Factory(t) {
  return new (t || TuiObscuredModule)();
};
TuiObscuredModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiObscuredModule
});
TuiObscuredModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiObscuredModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiObscuredDirective],
      exports: [TuiObscuredDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiObscuredDirective, TuiObscuredModule };
