import * as i0 from '@angular/core';
import { ElementRef, NgZone, Injectable, Inject, Directive, NgModule } from '@angular/core';
import { ALWAYS_TRUE_HANDLER, ALWAYS_FALSE_HANDLER } from '@taiga-ui/cdk/constants';
import { tuiTypedFromEvent, tuiZoneOptimized } from '@taiga-ui/cdk/observables';
import { tuiIsElement } from '@taiga-ui/cdk/utils';
import * as i1 from 'rxjs';
import { Observable, merge } from 'rxjs';
import { map, filter, distinctUntilChanged } from 'rxjs/operators';
function movedOut({
  currentTarget,
  relatedTarget
}) {
  return !tuiIsElement(relatedTarget) || !tuiIsElement(currentTarget) || !currentTarget.contains(relatedTarget);
}
class TuiHoveredService extends Observable {
  constructor(el, zone) {
    super(subscriber => this.stream$.subscribe(subscriber));
    this.el = el;
    this.zone = zone;
    this.stream$ = merge(tuiTypedFromEvent(this.el.nativeElement, 'mouseenter').pipe(map(ALWAYS_TRUE_HANDLER)), tuiTypedFromEvent(this.el.nativeElement, 'mouseleave').pipe(map(ALWAYS_FALSE_HANDLER)),
    // Hello, Safari
    tuiTypedFromEvent(this.el.nativeElement, 'mouseout').pipe(filter(movedOut), map(ALWAYS_FALSE_HANDLER))).pipe(distinctUntilChanged(), tuiZoneOptimized(this.zone));
  }
}
TuiHoveredService.ɵfac = function TuiHoveredService_Factory(t) {
  return new (t || TuiHoveredService)(i0.ɵɵinject(ElementRef), i0.ɵɵinject(NgZone));
};
TuiHoveredService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TuiHoveredService,
  factory: TuiHoveredService.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHoveredService, [{
    type: Injectable
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i0.NgZone,
      decorators: [{
        type: Inject,
        args: [NgZone]
      }]
    }];
  }, null);
})();
class TuiHoveredDirective {
  constructor(tuiHoveredChange) {
    this.tuiHoveredChange = tuiHoveredChange;
  }
}
TuiHoveredDirective.ɵfac = function TuiHoveredDirective_Factory(t) {
  return new (t || TuiHoveredDirective)(i0.ɵɵdirectiveInject(TuiHoveredService));
};
TuiHoveredDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiHoveredDirective,
  selectors: [["", "tuiHoveredChange", ""]],
  outputs: {
    tuiHoveredChange: "tuiHoveredChange"
  },
  features: [i0.ɵɵProvidersFeature([TuiHoveredService])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHoveredDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiHoveredChange]',
      outputs: ['tuiHoveredChange'],
      providers: [TuiHoveredService]
    }]
  }], function () {
    return [{
      type: i1.Observable,
      decorators: [{
        type: Inject,
        args: [TuiHoveredService]
      }]
    }];
  }, null);
})();
class TuiHoveredModule {}
TuiHoveredModule.ɵfac = function TuiHoveredModule_Factory(t) {
  return new (t || TuiHoveredModule)();
};
TuiHoveredModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiHoveredModule
});
TuiHoveredModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiHoveredModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiHoveredDirective],
      exports: [TuiHoveredDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiHoveredDirective, TuiHoveredModule, TuiHoveredService };
