import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AppButtonSize, AppButtonType } from '../../shared.model';

@Component({
  selector: 'app-button',
  templateUrl: './app-button.component.html',
  styleUrl: './app-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppButtonComponent {
  @Input() icon: string | undefined;
  @Input() iconColor: string | undefined;
  @Input() color: string | undefined;
  @Input() label: string | undefined;
  @Input() labelColor: string | undefined;
  @Input() type: AppButtonType = AppButtonType.Stroked;
  @Input() disabled: boolean = false;
  @Input() size: AppButtonSize = AppButtonSize.Basic;
  @Input() htmlType: string = 'button';
  @Output() onClick = new EventEmitter<void>();

  public click(): void {
    this.onClick.emit();
  }
}
