import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EMPTY_ARRAY } from '@taiga-ui/cdk';
import { TuiDataListComponent, tuiIsEditingKey } from '@taiga-ui/core';

@Component({
  selector: 'custom-list',
  templateUrl: './custom-list.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomListComponent<T> {
  @Input() autocompleteOptions: any[] = [];
  @Input() optionLabel: string = 'name';
  @Input() optionValue: string = 'id';
  @Input() placeholderKey: string = 'placeholders.search';

  public searchValue = '';
  readonly all = EMPTY_ARRAY;

  readonly filter = (item: T, search: string) => {
    const label = item[this.optionLabel as keyof typeof item] as string;
    const isOk = label.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    return isOk;
  };

  onArrowDown<T>(list: TuiDataListComponent<T>, event: Event): void {
    list.onFocus(event, true);
  }

  onKeyDown(key: string, element: HTMLElement | null): void {
    if (element && tuiIsEditingKey(key)) {
      element.focus({ preventScroll: true });
    }
  }

  getValue(item: any): number {
    return item[this.optionValue as keyof typeof item] as number;
  }

  getLabel(item: any): string {
    return item[this.optionLabel as keyof typeof item] as string;
  }
}
