import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { constants } from 'src/app/core/constants/constants';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class TooltipComponent implements OnInit {
  @Input() title: string | undefined;
  @Input() text: string = '';
  @Input() class: string = '';
  @Input() hideDelay: number = constants.tooltipHideDelay;
  @Input() tooltipPosition: string = 'right';
  @Input() escape: boolean = false; // html rendering

  constructor() {}

  ngOnInit(): void {}

  get htmlText(): string {
    if (this.title && this.text) {
      return `<p class="tooltip-header">${this.title}</b> <br/> <p> ${this.text} </p>`;
    } else if (this.text) {
      return `<br/> <p> ${this.text} </p>`;
    } else return '';
  }
}
