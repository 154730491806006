import * as i0 from '@angular/core';
import { ElementRef, Directive, Inject, NgModule } from '@angular/core';
class TuiElementDirective {
  constructor({
    nativeElement
  }) {
    /**
     * @note:
     * Typically, when your constructor is invoked with new,
     * an object is created, its constructor is assigned to
     * the invoked constructor and the object is then assigned
     * to this before executing any operations specified
     * in your constructor method.
     *
     * ERROR TypeError: Class constructor ElementRef cannot be invoked without 'new'
     * https://github.com/taiga-family/taiga-ui/issues/3072
     *
     * This way we can instantiate object creation
     * without additional prototype chain for possible fix bug.
     */
    return new ElementRef(nativeElement);
  }
}
TuiElementDirective.ɵfac = function TuiElementDirective_Factory(t) {
  return new (t || TuiElementDirective)(i0.ɵɵdirectiveInject(ElementRef));
};
TuiElementDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: TuiElementDirective,
  selectors: [["", "tuiElement", ""]],
  exportAs: ["elementRef"]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiElementDirective, [{
    type: Directive,
    args: [{
      selector: '[tuiElement]',
      exportAs: 'elementRef'
    }]
  }], function () {
    return [{
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }];
  }, null);
})();
class TuiElementModule {}
TuiElementModule.ɵfac = function TuiElementModule_Factory(t) {
  return new (t || TuiElementModule)();
};
TuiElementModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiElementModule
});
TuiElementModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiElementModule, [{
    type: NgModule,
    args: [{
      declarations: [TuiElementDirective],
      exports: [TuiElementDirective]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TuiElementDirective, TuiElementModule };
